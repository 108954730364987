/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Am } from './Am';

class Scale extends Am {
    constructor() {
        super();
        this.baseUrl += '/scale';
    }

    data({ scaleName, scaleSn, scaleType, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            scaleName,
            scaleSn,
            scaleType,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        scaleType,
        scaleSn,
        scaleName,
        scaleDesc,
        scaleKeywords,
        scaleBrief,
        testMinutes,
        remarks,
        orderNum,
        isAvailable,
        questionListJson,
    } = {}) {
        return super.post(
            '/save',
            {
                id,
                scaleType,
                scaleSn,
                scaleName,
                scaleDesc,
                scaleKeywords,
                scaleBrief,
                testMinutes,
                remarks,
                orderNum,
                isAvailable,
                questionListJson,
            },
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new Scale();
export { Scale };
