/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { HttpBase } from '@/api/base/HttpBase';

class Lg extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/lg';
    }
}

export default new Lg();
export { Lg };
