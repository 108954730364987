/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Scale from './Scale';
import ScaleParam from './ScaleParam';
import ScaleAnalysis from './ScaleAnalysis';
import ScaleFactor from './ScaleFactor';
import ScaleFactorComment from './ScaleFactorComment';
import AssessPlan from './AssessPlan';
import Assess from './Assess';

export default {
    Scale,
    ScaleParam,
    ScaleAnalysis,
    ScaleFactor,
    ScaleFactorComment,
    AssessPlan,
    Assess,
};
