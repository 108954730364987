<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基础信息</span>
                </div>
                <div class="item">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="userName"
                                label="账号："
                                label-width="9em"
                            >
                                {{detail.userName}}
                            </el-form-item>
                            <el-form-item
                                prop="fullName"
                                label="姓名："
                                label-width="9em"
                            >
                                {{detail.fullName}}
                            </el-form-item>
                            <el-form-item
                                prop="mobile"
                                label="手机号："
                                label-width="9em"
                            >
                                {{detail.mobile}}
                            </el-form-item>
                            <el-form-item
                                prop="businessLicenseUrl"
                                label="营业执照："
                                label-width="9em"
                            >
                                <imageList
                                    :data="detail.businessLicenseUrl"
                                    :item-width="100"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="attachmentList"
                                label="附件："
                                label-width="9em"
                            >
                                <imageList
                                    :data="detail.attachmentList"
                                    :item-width="100"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="applyTime"
                                label="申请时间："
                                label-width="9em"
                            >
                                {{detail.applyTime | moment('YYYY-MM-DD HH:mm:ss')}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="+detail.approvalStatus === 10">
                        <el-col :span="18">
                            <el-form-item
                                prop="approvalTime"
                                label="审核时间："
                                label-width="9em"
                            >
                                {{detail.approvalTime | moment('YYYY-MM-DD HH:mm:ss')}}
                            </el-form-item>
                            <el-form-item
                                prop="approvalStatusName"
                                label="审核状态："
                                label-width="9em"
                            >
                                {{detail.approvalStatusName}}
                            </el-form-item>
                            <el-form-item
                                prop="approvalRemark"
                                label="审核备注："
                                label-width="9em"
                            >
                                <div style="color: red">
                                    {{detail.approvalRemark}}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>审核信息</span>
                </div>
                <div
                    class="item"
                    v-if="+detail.approvalStatus === 10"
                >
                    <el-form
                        ref="addForm"
                        class="second-header npa-b"
                        size="small"
                        :model="formModel"
                        :rules="formRules"
                    >
                        <el-row>
                            <el-col :span="18">
                                <el-form-item
                                    prop="approvalStatus"
                                    label="审核是否通过"
                                    label-width="9em"
                                >
                                    <el-select
                                        v-model="formModel.approvalStatus"
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            label="审核通过"
                                            value="100"
                                        />
                                        <el-option
                                            label="审核不通过"
                                            value="99"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item
                                    prop="approvalRemark"
                                    label="审核备注"
                                    label-width="9em"
                                >
                                    <el-input
                                        type="textarea"
                                        v-model="formModel.approvalRemark"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item label-width="9em">
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="onAudit"
                                    >
                                        提交审核
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div
                    class="item"
                    v-else
                >
                    <el-row>
                        <el-col :span="18">
                            <el-form-item
                                prop="approvalTime"
                                label="审核时间："
                                label-width="9em"
                            >
                                {{detail.approvalTime | moment('YYYY-MM-DD HH:mm:ss')}}
                            </el-form-item>
                            <el-form-item
                                prop="approvalStatusName"
                                label="审核状态："
                                label-width="9em"
                            >
                                {{detail.approvalStatusName}}
                            </el-form-item>
                            <el-form-item
                                prop="approvalRemark"
                                label="审核备注："
                                label-width="9em"
                            >
                                {{detail.approvalRemark}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </el-form>

        <div
            class="ma-t"
            style="text-align: center"
        >
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'SchedulingPlanView',
    mixins: [pagesMixin],
    data() {
        return {
            detail: {},
            formModel: {
                id: '',
                approvalStatus: '',
                approvalRemark: '',
            },
            formRules: {
                approvalStatus: {
                    required: true,
                    message: '请选择',
                },
            },
        };
    },
    methods: {
        init() {
            this.$api.Mb.MemberSettlement.getDetail({
                id: this.formModel.id,
            }).then(json => {
                const res = json.data;
                this.detail = res.data;
            });
        },
        onAudit() {
            this.$refs.addForm.validate().then(() => {
                this.$api.Mb.MemberSettlement.audit({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
