<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基础信息</span>
                </div>
                <div class="item">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="deviceType"
                                label="设备类型"
                                label-width="9em"
                            >
                                <dictionaries-picker
                                    type="deviceType"
                                    v-model="formModel.deviceType"
                                    placeholder="请选择"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="itemName"
                                label="项目名称"
                                label-width="9em"
                            >
                                <el-input
                                    v-model="formModel.itemName"
                                    placeholder="请输入项目名称"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="itemSn"
                                label="项目编号"
                                label-width="9em"
                            >
                                <el-input
                                    v-model="formModel.itemSn"
                                    placeholder="请输入项目编号"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="isAvailable"
                                label="是否可用"
                                label-width="9em"
                            >
                                <el-switch
                                    v-model="formModel.isAvailable"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item
                                prop="itemDesc"
                                label="项目描述"
                                label-width="9em"
                            >
                                <Tinymce
                                    :height="300"
                                    image-action="/rs/attachment/uploadFileLibrary"
                                    v-model="formModel.itemDesc"
                                    image-upload-tips=""
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="18">
                            <el-form-item
                                prop="remarks"
                                label="备注"
                                label-width="9em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.remarks"
                                    :rows="6"
                                    placeholder="请输入备注"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>体检项目详情</span>
                    <el-button
                        class="ma-l"
                        type="success"
                        size="small"
                        @click="addDetail"
                    >
                        新增
                    </el-button>
                </div>
                <div class="item">
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="formModel.detailList"
                        style="width: 100%"
                        default-expand-all
                        @selection-change="changeDetailListSelected"
                    >
                        <el-table-column type="expand">
                            <template v-slot="scope">
                                <div
                                    class="ma-b"
                                    v-if="scope.row.rangeList && +scope.row.detailRangeType !== 3"
                                >
                                    <el-button
                                        size="small"
                                        type="success"
                                        @click="addRange(scope.row)"
                                    >
                                        新增
                                    </el-button>
                                </div>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    style="width: 95%"
                                    :data="scope.row.rangeList"
                                    v-if="scope.row.rangeList && +scope.row.detailRangeType === 2"
                                >
                                    <el-table-column
                                        prop="rangeBegin"
                                        label="开始值"
                                        width="155"
                                    >
                                        <template v-slot="range">
                                            <el-input-number
                                                size="small"
                                                controls-position="right"
                                                v-model="range.row.rangeBegin"
                                                :percision="4"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="rangeEnd"
                                        label="结束值"
                                        width="155"
                                    >
                                        <template v-slot="range">
                                            <el-input-number
                                                size="small"
                                                controls-position="right"
                                                v-model="range.row.rangeEnd"
                                                :percision="4"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultName"
                                        label="检测结果"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.resultName"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultInfo"
                                        label="医学描述"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.resultInfo"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="suggestion"
                                        label="指导建议"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.suggestion"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAbnormal"
                                        label="是否异常"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            <el-switch
                                                size="small"
                                                v-model="range.row.isAbnormal"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            <el-switch
                                                size="small"
                                                v-model="range.row.isAvailable"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        fixed="right"
                                        prop="operate"
                                        label="操作"
                                        width="60"
                                    >
                                        <template v-slot="range">
                                            <el-button
                                                size="small"
                                                type="text"
                                                @click="deleteRange(scope.row,range.row)"
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    style="width: 95%"
                                    :data="scope.row.rangeList"
                                    v-if="scope.row.rangeList && +scope.row.detailRangeType === 3"
                                >
                                    <el-table-column
                                        prop="rangeValue"
                                        label="评定值"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{ range.row.rangeValue }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultName"
                                        label="结果名称"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.resultName"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultInfo"
                                        label="结果描述"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.resultInfo"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="suggestion"
                                        label="建议"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.suggestion"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAbnormal"
                                        label="是否异常"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            <el-switch
                                                size="small"
                                                v-model="range.row.isAbnormal"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            <el-switch
                                                size="small"
                                                v-model="range.row.isAvailable"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    style="width: 95%"
                                    :data="scope.row.rangeList"
                                    v-if="scope.row.rangeList && (+scope.row.detailRangeType === 4 || +scope.row.detailRangeType === 5)"
                                >
                                    <el-table-column
                                        prop="rangeValue"
                                        label="评定值"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.rangeValue"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultName"
                                        label="结果名称"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.resultName"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultInfo"
                                        label="结果描述"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.resultInfo"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="suggestion"
                                        label="建议"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            <el-input
                                                size="small"
                                                type="textarea"
                                                :row="3"
                                                v-model="range.row.suggestion"
                                                placeholder="请输入"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAbnormal"
                                        label="是否异常"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            <el-switch
                                                size="small"
                                                v-model="range.row.isAbnormal"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            <el-switch
                                                size="small"
                                                v-model="range.row.isAvailable"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        fixed="right"
                                        prop="operate"
                                        label="操作"
                                        width="60"
                                    >
                                        <template v-slot="range">
                                            <el-button
                                                size="small"
                                                type="text"
                                                @click="deleteRange(scope.row,range.row)"
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailName"
                            label="名称"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                <el-input
                                    size="small"
                                    v-model="scope.row.detailName"
                                    placeholder="请输入名称"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailSn"
                            label="编号"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                <el-input
                                    size="small"
                                    v-model="scope.row.detailSn"
                                    placeholder="请输入编号"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailDesc"
                            label="描述"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                <el-input
                                    size="small"
                                    type="textarea"
                                    v-model="scope.row.detailDesc"
                                    :row="6"
                                    placeholder="请输入描述"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="unitName"
                            label="单位名称"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                <el-input
                                    size="small"
                                    v-model="scope.row.unitName"
                                    placeholder="请输入单位名称"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailRangeType"
                            label="评定范围类型"
                            width="155"
                        >
                            <template v-slot="scope">
                                <dictionaries-picker
                                    size="small"
                                    type="detailRangeType"
                                    v-model="scope.row.detailRangeType"
                                    placeholder="请选择"
                                    @change="changeRangeType(scope.row)"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailRangeDesc"
                            label="评定范围描述"
                            width="180"
                        >
                            <template v-slot="scope">
                                <el-input
                                    size="small"
                                    v-model="scope.row.detailRangeDesc"
                                    placeholder="请输入评定范围描述"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="isAvailable"
                            label="是否可用"
                            width="80"
                        >
                            <template v-slot="scope">
                                <el-switch
                                    size="small"
                                    v-model="scope.row.isAvailable"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailDesc"
                            label="描述"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                <el-input
                                    size="small"
                                    type="textarea"
                                    v-model="scope.row.detailDesc"
                                    :row="6"
                                    placeholder="请输入描述"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="60"
                        >
                            <template v-slot="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="deleteDetail(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </el-form>

        <div
            class="ma-t"
            style="margin-left: 10.5%"
        >
            <el-button
                type="primary"
                size="small"
                @click="onSave"
            >
                保 存
            </el-button>
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { Utils } from 'vmf';

export default {
    name: 'PhysicalExamItemEdit',
    mixins: [pagesMixin],
    data() {
        return {
            formModel: {
                deviceType: '',
                itemName: '',
                itemSn: '',
                isAvailable: true,
                itemDesc: '',
                remarks: '',
                detailList: [],
            },
            currentDetailTableSelect: [],
            formRules: {
                deviceType: {
                    required: true,
                    message: '请选择',
                },
                itemName: {
                    required: true,
                    message: '请输入',
                },
                isAvailable: {
                    required: true,
                    message: '请输入',
                },
            },
        };
    },
    methods: {
        onSave() {
            this.$refs.addForm.validate().then(() => {
                this.$api.Pe.PhysicalExamItem.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Pe.PhysicalExamItem.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
        changeDetailListSelected(val) {
            this.currentPlanDetailTableSelect = val;
        },
        addDetail() {
            if (!this.formModel.detailList) {
                this.formModel.detailList = [];
            }
            this.formModel.detailList.push({
                detailName: '',
                detailSn: '',
                detailDesc: '',
                unitName: '',
                detailRangeType: '1',
                detailRangeDesc: '',
                isAvailable: true,
                remarks: '',
                rangeList: null,
            });
        },
        deleteDetail(row) {
            this.formModel.detailList.splice(this.formModel.detailList.indexOf(row), 1);
        },
        changeRangeType(row) {
            const { detailRangeType } = row;
            if (!detailRangeType || +detailRangeType === 1) {
                row.rangeList = null;
            } else if (+detailRangeType === 3) {
                row.rangeList = [];
                row.rangeList.push(
                    {
                        rangeBegin: undefined,
                        rangeEnd: undefined,
                        rangeValue: '低标准',
                        resultName: '低标准',
                        resultInfo: '',
                        suggestion: '',
                        isAbnormal: true,
                        isAvailable: true,
                    },
                    {
                        rangeBegin: undefined,
                        rangeEnd: undefined,
                        rangeValue: '正常',
                        resultName: '正常',
                        resultInfo: '',
                        suggestion: '',
                        isAbnormal: false,
                        isAvailable: true,
                    },
                    {
                        rangeBegin: undefined,
                        rangeEnd: undefined,
                        rangeValue: '超标',
                        resultName: '超标',
                        resultInfo: '',
                        suggestion: '',
                        isAbnormal: true,
                        isAvailable: true,
                    },
                );
            } else {
                row.rangeList = [];
            }
        },
        addRange(row) {
            console.log(111, row.rangeList);
            if (!row.rangeList) {
                row.rangeList = [];
            }
            row.rangeList.push({
                rangeBegin: undefined,
                rangeEnd: undefined,
                rangeValue: undefined,
                resultName: '',
                resultInfo: '',
                suggestion: '',
                isAbnormal: false,
                isAvailable: true,
            });
            console.log(222, row.rangeList);
        },
        deleteRange(detail, range) {
            if (detail.rangeList && detail.rangeList.length) {
                detail.rangeList.splice(detail.rangeList.indexOf(range), 1);
            }
            console.log(333, detail.rangeList);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
