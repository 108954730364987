/**
 * Created by henian.xu on 2019/11/18.
 *
 */

import Vue from 'vue';
import Filter from '@vmf/filter';
import I18n from '@vmf/i18n';
import Power from '@vmf/power';
import { GlobalVar } from '@vmf/shared';
import { router } from '@vmf/router';
import { store } from '@vmf/vuex';
import Plugin from './plugin';
import Api from './api';
import UtilsInstall from './Utils';

import './store';

export { $$t, create$$t } from '@vmf/i18n';
export { addPower, hasPower } from '@vmf/power';
export * from '@vmf/request';
export * from '@vmf/storage';
export * from '@vmf/router';
export * from '@vmf/vuex';
export * from './Utils';

export { Vue, GlobalVar };

Vue.use(Filter);
Vue.use(Power);
Vue.use(Plugin);
Vue.use(Api);
Vue.use(UtilsInstall);

// TODO 处理使用 cdn 引用vue.min.js 时无效的问题
// Vue.config.productionTip = Utils.GlobalVar.productionTip;

const beforeCreateFns: any[] = [];
const requireProject = (require as any).context('@', true, /main\.js$/);
requireProject.keys().forEach((fileName: string) => {
    // 只会获取1级和2级目录下的main.js文件
    if (fileName.split('/').length > 3) return;
    const res = requireProject(fileName);
    if (res.beforeCreate) beforeCreateFns.push(res.beforeCreate());
});

Vue.use(I18n);

Promise.all(beforeCreateFns).then(res => {
    // vue-meta 处理标题
    const keyName =
        (GlobalVar.appConfig && GlobalVar.appConfig.VUE_META && GlobalVar.appConfig.VUE_META.keyName) || 'metaInfo';

    new Vue({
        router,
        store,
        [keyName]() {
            const {
                meta: { title = '', subTitle = '', i18nKey = '' },
            } = this.$route;
            return {
                title: (this as any).$$t(
                    i18nKey || '',
                    `${title}${subTitle ? `-${subTitle}` : subTitle}` || GlobalVar.DocTitle,
                ),
            };
        },
        render: h =>
            h(
                'div',
                {
                    attrs: {
                        id: 'app',
                    },
                },
                [h('router-view')],
            ),
    }).$mount('#app');
});
