<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <template v-if="!formModel.id || +type === 1">
                        <!--                        <el-form-item
                            prop="scaleType"
                            label="量表类型"
                            label-width="10em">
                            <dictionaries-picker
                                type="scaleType"
                                v-model="formModel.scaleType"
                            />
                        </el-form-item>-->
                        <el-form-item
                            prop="scaleSn"
                            label="量表编码"
                            label-width="10em">
                            <el-input
                                v-model="formModel.scaleSn"
                                auto-complete="off" />
                        </el-form-item>
                        <el-form-item
                            prop="scaleName"
                            label="量表名称"
                            label-width="10em">
                            <el-input
                                v-model="formModel.scaleName"
                                auto-complete="off" />
                        </el-form-item>
                        <el-form-item
                            prop="scaleDesc"
                            label="量表介绍"
                            label-width="10em">
                            <el-input
                                type="textarea"
                                v-model="formModel.scaleDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="scaleKeywords"
                            label="关键词"
                            label-width="10em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.scaleKeywords"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="scaleBrief"
                            label="简介"
                            label-width="10em">
                            <el-input
                                type="textarea"
                                v-model="formModel.scaleBrief"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="testMinutes"
                            label="测试时长(分钟)"
                            label-width="10em"
                        >
                            <el-input-number
                                controls-position="right"
                                v-model="formModel.testMinutes"
                                :precision="0"
                                :min="1"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="remarks"
                            label="备注"
                            label-width="10em">
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="orderNum"
                            label="排序号"
                            label-width="10em">
                            <el-input-number
                                controls-position="right"
                                :min="1"
                                v-model.number="formModel.orderNum"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="isAvailable"
                            label="是否可用"
                            label-width="10em"
                        >
                            <el-switch
                                v-model="formModel.isAvailable"
                                :active-value="true"
                                :inactive-value="false"
                            />
                        </el-form-item>
                    </template>
                    <el-form-item
                        label="题目列表"
                        label-width="10em"
                        v-if="!formModel.id || +type === 2"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddQuestion"
                        >
                            新增题目
                        </el-button>

                        <el-table
                            stripe
                            border
                            size="small"
                            :data="questionList"
                            style="width: 100%"
                            class="ma-t"
                            default-expand-all
                        >
                            <el-table-column type="expand">
                                <template slot-scope="question">
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddAnswer(question.row)"
                                    >
                                        新增选项
                                    </el-button>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="question.row.answerList"
                                        style="width: 100%"
                                        class="ma-t"
                                    >
                                        <el-table-column
                                            prop="orderNum"
                                            label="排序号"
                                            width="160">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="1"
                                                    v-model.number="scope.row.orderNum"
                                                    size="mini"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="answerTitle"
                                            label="答案信息"
                                            min-width="100"
                                        >
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.answerTitle"
                                                    auto-complete="off"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="answerScore"
                                            label="分值"
                                            min-width="100"
                                        >
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="scope.row.answerScore"
                                                    size="mini"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="comment"
                                            label="评语"
                                            min-width="100"
                                        >
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.comment"
                                                    auto-complete="off"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            fixed="right"
                                            label="操作"
                                            width="60">
                                            <template slot-scope="scope">
                                                <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="
                                                        onDelAnswer(question.row.answerList, scope.row)
                                                    "
                                                >
                                                    删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderNum"
                                label="排序号"
                                width="160">
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="1"
                                        v-model.number="scope.row.orderNum"
                                        size="mini"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="questionTitle"
                                label="题目信息"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.questionTitle"
                                        auto-complete="off"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="60">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelQuestion(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item label-width="10em">
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm">
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"> 返 回 </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ScaleEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                scaleType: '',
                scaleSn: '',
                scaleName: '',
                scaleDesc: '',
                scaleKeywords: '',
                scaleBrief: '',
                testMinutes: '',
                remarks: '',
                orderNum: '',
                isAvailable: true,
            },
            // 表单校验规则
            formRules: {
                scaleType: {
                    required: true,
                    message: '请选择量表类型',
                },
                scaleSn: {
                    required: true,
                    message: '请输入量表编码',
                    trigger: 'blur',
                },
                scaleName: {
                    required: true,
                    message: '请输入量表名称',
                    trigger: 'blur',
                },
                testMinutes: {
                    required: true,
                    message: '请输入测试时间',
                    trigger: 'blur',
                },
            },
            questionList: [],
            type: '',
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            this.$utils.validateForm([addForm], true).then(() => {
                this.$api.Am.Scale.save({
                    ...this.formModel,
                    questionListJson: !this.formModel.id || +this.type === 2 ? JSON.stringify(this.questionList) : '',
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Am.Scale.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res.scale);
                this.questionList = res.questionList;
            });
        },
        onAddQuestion() {
            this.questionList.push({
                orderNum: this.questionList.length + 1,
                answerList: [],
            });
        },
        onDelQuestion(row) {
            this.questionList.splice(this.questionList.indexOf(row), 1);
        },
        onAddAnswer(row) {
            row.answerList.push({
                orderNum: row.answerList.length + 1,
            });
        },
        onDelAnswer(list, row) {
            list.splice(list.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        this.formModel.scaleType = this.$route.query.scaleType;
        this.type = this.$route.query.type;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
