import { Pe } from './Pe';

class Doctor extends Pe {
    constructor() {
        super();
        this.baseUrl += '/doctor';
    }

    data({ fullName, labelName, idCardNo, mobile, sex, doctorStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            fullName,
            labelName,
            idCardNo,
            mobile,
            sex,
            doctorStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const res = json.data.data;
                const { fileBasePath } = res;
                const { avatar } = res.doctor;
                if (fileBasePath) {
                    if (avatar) {
                        res.doctor.avatar = [
                            {
                                name: avatar,
                                address: fileBasePath + avatar,
                            },
                        ];
                    } else {
                        res.doctor.avatar = [];
                    }
                }
                return json;
            });
    }

    save({
        id,
        avatar,
        attachment,
        fullName,
        labelName,
        idCardNo,
        mobile,
        sex,
        birthday,
        graduatedSchool,
        jobTitle,
        personalIntroduction,
        workExperience,
        educationalExperience,
        relevantQualifications,
        reservationPrice,
        doctorStatus,
        remarks,
        doctorDepartmentId,
        orgName,
        isRecommend,
        materialsJson,
    } = {}) {
        let avatarUrl;
        if (avatar && avatar.length) {
            avatarUrl = avatar
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let attachmentUrl;
        if (attachment && attachment.length) {
            attachmentUrl = attachment
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/save', {
            id,
            avatar: avatarUrl,
            attachment: attachmentUrl,
            fullName,
            labelName,
            idCardNo,
            mobile,
            sex,
            birthday,
            graduatedSchool,
            jobTitle,
            personalIntroduction,
            workExperience,
            educationalExperience,
            relevantQualifications,
            reservationPrice,
            doctorStatus,
            remarks,
            doctorDepartmentId,
            orgName,
            isRecommend,
            materialsJson,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}
export default new Doctor();
export { Doctor };
