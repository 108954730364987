/**
 * Created by henian.xu on 2019/3/19.
 * 设备检查
 */

import Vue from 'vue';
import { Utils } from 'vmf';
// import { debounce } from './Common';

const { debounce } = Utils;

const { platform } = window.navigator;
const ua = window.navigator.userAgent;
const docEl = document.documentElement;

export const Device = Vue.observable({
    ua,
    ios: false,
    android: false,
    androidChrome: false,
    desktop: false,
    windowsPhone: false,
    iphone: false,
    iphoneX: false,
    ipod: false,
    ipad: false,
    edge: false,
    ie: false,
    firefox: false,
    macos: false,
    windows: false,
    cordova: !!(window.cordova || window.phonegap),
    phonegap: !!(window.cordova || window.phonegap),
    electron: false,
    webview: false,
    standalone: false,
    statusbar: false,
    weixin: false,
    weixinMiniProgram: false,
    tablet: false,
    pixelRatio: 1,
    os: '',
    osVersion: null,
    needsStatusbarOverlay: () => false,
    width: docEl.clientWidth,
    height: docEl.clientHeight,
    isScreenPortrait: true,
    documentElement: docEl,
    docHidden: false,
});

document.onvisibilitychange = function handler() {
    // document.title = document.hidden ? 'hidden' : 'visible';
    // console.log('Visibility of page has changed!', document.hidden, document.visibilityState);
    Device.docHidden = document.hidden;
};

/*
window.addEventListener('visibilitychange', () => {
    // 如果页面处于被认为是对用户隐藏状态时返回true，否则返回false。
    // document.hidden;
    console.log('hidden', document.hidden);
    // 通过这个方法来获取当前标签页在浏览器中的激活状态
    switch (document.visibilityState) {
        case 'prerender': // 网页预渲染，但内容不可见的(被document.hidden当做隐藏). 文档可能初始状态为prerender，但绝不会从其它值转为该值。
            document.title = 'prerender';
            console.log('prerender');
            break;
        case 'hidden': // 内容不可见牌后台状态，最小化或者锁屏状态
            document.title = 'hidden';
            console.log('hidden');
            break;
        case 'visible': // 内容可见
            document.title = 'visible';
            console.log('visible');
            break;
        case 'unloaded': // 文档被卸载
            document.title = 'unloaded';
            console.log('unloaded');
            break;
        default:
            break;
    }
});
*/

window.addEventListener(
    'resize',
    debounce(() => {
        // console.log('window resize');
        Device.width = docEl.clientWidth;
        Device.height = docEl.clientHeight;
    }),
);

const screenOrientationMql = window.matchMedia('(orientation: portrait)');
function handleOrientationChange(mql) {
    // console.log('screenOrientationMql', mql.matches);
    Device.isScreenPortrait = mql.matches;
}
handleOrientationChange(screenOrientationMql);
screenOrientationMql.addListener(handleOrientationChange);

const screenWidth = window.screen.width;
const screenHeight = window.screen.height;

const windowsPhone = ua.match(/(Windows Phone);?[\s\/]+([\d.]+)?/); // eslint-disable-line
const android = ua.match(/(Android);?[\s\/]+([\d.]+)?/); // eslint-disable-line
const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
const iphone = !ipad && ua.match(/(iPhone\sOS|iOS)\s([\d_]+)/);
const iphoneX =
    iphone &&
    ((screenWidth === 375 && screenHeight === 812) || // X/XS
        (screenWidth === 414 && screenHeight === 896)); // XR / XS Max
const ie = ua.indexOf('MSIE ') >= 0 || ua.indexOf('Trident/') >= 0;
const edge = ua.indexOf('Edge/') >= 0;
const firefox = ua.indexOf('Gecko/') >= 0 && ua.indexOf('Firefox/') >= 0;
const macos = platform === 'MacIntel';
const windows = platform === 'Win32';
const electron = ua.toLowerCase().indexOf('electron') >= 0;
const weixin = !!ua.match(/MicroMessenger/);
const weixinMiniProgram = !!ua.match(/miniProgram/);

const tablet = !!ipad || (android && !/(?:Mobile)/.test(ua)) || (firefox && /(?:Tablet)/.test(ua));

Device.ie = ie;
Device.edge = edge;
Device.firefox = firefox;
Device.weixin = weixin;
Device.tablet = tablet;

// weixinMiniProgram
if (!weixin && !weixinMiniProgram) {
    const { wx } = window;
    if (window.__wxjs_environment === 'miniprogram') {
        Device.weixinMiniProgram = true;
    } else if (wx && wx.miniProgram) {
        // 这是个异步的过程，原理是 WeixinJSBridgeReady 事件中判断 window.__wxjs_environment === "miniprogram"
        wx.miniProgram.getEnv(res => {
            Device.weixinMiniProgram = res.miniprogram;
        });
    } else {
        Device.weixinMiniProgram = false;
    }
} else {
    Device.weixinMiniProgram = weixinMiniProgram;
}
// console.log('first weixinMiniProgram: ', Device.weixinMiniProgram);

// Windows
if (windowsPhone) {
    Device.os = 'windowsPhone';
    // eslint-disable-next-line prefer-destructuring
    Device.osVersion = windowsPhone[2];
    Device.windowsPhone = true;
}
// Android
if (android && !windows) {
    Device.os = 'android';
    // eslint-disable-next-line prefer-destructuring
    Device.osVersion = android[2];
    Device.android = true;
    Device.androidChrome = ua.toLowerCase().indexOf('chrome') >= 0;
}
if (ipad || iphone || ipod) {
    Device.os = 'ios';
    Device.ios = true;
}
// iOS
if (iphone && !ipod) {
    Device.osVersion = iphone[2].replace(/_/g, '.');
    Device.iphone = true;
    Device.iphoneX = !!iphoneX;
}
if (ipad) {
    Device.osVersion = ipad[2].replace(/_/g, '.');
    Device.ipad = true;
}
if (ipod) {
    Device.osVersion = ipod[3] ? ipod[3].replace(/_/g, '.') : null;
    Device.iphone = true;
}
// iOS 8+ changed UA
if (Device.ios && Device.osVersion && ua.indexOf('Version/') >= 0) {
    if (Device.osVersion.split('.')[0] === '10') {
        // eslint-disable-next-line prefer-destructuring
        Device.osVersion = ua
            .toLowerCase()
            .split('version/')[1]
            .split(' ')[0];
    }
}

// Webview
const webView =
    !!((iphone || ipad || ipod) && (ua.match(/.*AppleWebKit(?!.*Safari)/i) || window.navigator.standalone)) ||
    (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches);
Device.webview = webView;
Device.standalone = webView;

// Desktop
Device.desktop = !(Device.ios || Device.android || Device.windowsPhone) || electron;
if (Device.desktop) {
    Device.electron = electron;
    Device.macos = macos;
    Device.windows = windows;
}

// Meta statusbar
const metaStatusbar = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');

// Check for status bar and fullscreen app mode
Device.needsStatusbarOverlay = function needsStatusbarOverlay() {
    if (Device.desktop) return false;
    if (Device.standalone && Device.ios && metaStatusbar && metaStatusbar.content === 'black-translucent') {
        return true;
    }
    if (
        (webView || (Device.android && Device.cordova)) &&
        window.innerWidth * window.innerHeight === window.screen.width * window.screen.height
    ) {
        return !(Device.iphoneX && (window.orientation === 90 || window.orientation === -90));
    }
    return false;
};
Device.statusbar = Device.needsStatusbarOverlay();

// Pixel Ratio
Device.pixelRatio = window.devicePixelRatio || 1;
