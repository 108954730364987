<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template slot="body">
            <el-row>
                <el-col :span="24">
                    <el-form
                        ref="addForm"
                        size="small"
                        :model="formModel"
                        :rules="formRules"
                        :disabled="!!formModel.planStatus && formModel.planStatus !== 10"
                    >
                        <el-card class="box-card ma-t">
                            <div
                                slot="header"
                                class="clearfix">
                                <span>计划信息</span>
                            </div>
                            <el-form-item
                                label="计划名称"
                                prop="planName"
                                label-width="10em">
                                <el-input
                                    v-model="formModel.planName"
                                    auto-complete="off" />
                            </el-form-item>
                            <el-form-item
                                prop="planImage"
                                label="计划图片"
                                label-width="10em"                            >
                                <upload
                                    validate-md5
                                    action="/rs/attachment/uploadFileLibrary"
                                    :limit="1"
                                    v-model="formModel.planImage"
                                    :oss-action="$globalVar.appConfig.OSS_ACTION"
                                />
                            </el-form-item>
                            <el-form-item
                                label="计划时间"
                                prop="planTime"
                                label-width="10em">
                                <el-date-picker
                                    v-model="formModel.planTime"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始时间"
                                    end-placeholder="截止时间"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="planDesc"
                                label="计划描述"
                                label-width="10em"
                            >
                                <Tinymce
                                    :height="300"
                                    image-action="/rs/attachment/uploadFileLibrary"
                                    v-model="formModel.planDesc"
                                    image-upload-tips="图片大小不能超过5M，格式支持jpg、png、bmp"
                                />
                            </el-form-item>
                            <el-form-item
                                label="备注"
                                prop="remarks"
                                label-width="10em">
                                <el-input
                                    type="textarea"
                                    v-model="formModel.remarks"
                                    auto-complete="off"
                                />
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card ma-t">
                            <div
                                slot="header"
                                class="clearfix">
                                <span>管理量表</span>
                            </div>
                            <el-form-item
                                label="量表列表"
                                label-width="10em"
                                prop="scaleId">
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onAddSelectScale"
                                >
                                    选择量表
                                </el-button>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="selectScaleList"
                                    style="width: 100%"
                                    class="ma-t"
                                >
                                    <el-table-column
                                        prop="scaleName"
                                        label="量表名称"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="scaleSn"
                                        label="量表编码"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="scaleTypeName"
                                        label="量表类型"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="testMinutes"
                                        label="测试时长（分钟）"
                                        width="120"
                                    />
                                    <el-table-column
                                        prop="orderNum"
                                        label="排序号"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        min-width="100"
                                    >
                                        <template slot-scope="scope">
                                            {{ scope.row.isAvailable ? '是' : '否' }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="创建时间"
                                        width="135">
                                        <template slot-scope="scope">
                                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="修改时间"
                                        width="135">
                                        <template slot-scope="scope">
                                            {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                        </template>
                                    </el-table-column>
                                    <!--                  <el-table-column fixed="right" label="操作" width="45">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="onDeleteSelectScale(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>-->
                                </el-table>
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card ma-t">
                            <el-form-item label-width="10em">
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirm(10)"
                                    v-if="!formModel.planStatus || formModel.planStatus === 10"
                                >
                                    保存草稿
                                </el-button>
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirm(100)"
                                    v-if="!formModel.planStatus || formModel.planStatus === 10"
                                >
                                    保存并发布
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="$router.back()">
                                    返 回
                                </el-button>
                            </el-form-item>
                        </el-card>
                    </el-form>
                </el-col>
            </el-row>
        </template>

        <el-dialog
            title="选择量表"
            center
            width="1200px"
            :visible.sync="selectScaleDialog.isVisible"
            @closed="onSelectScaleCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectScaleDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectScaleDialog.queryFormModel"
            >
                <el-form-item
                    prop="scaleName"
                    label="量表名称">
                    <el-input
                        v-model="selectScaleDialog.queryFormModel.scaleName"
                        placeholder="请输入量表名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="scaleSn"
                    label="量表编码">
                    <el-input
                        v-model="selectScaleDialog.queryFormModel.scaleSn"
                        placeholder="请输入量表编码"
                    />
                </el-form-item>
                <!--                <el-form-item
                    prop="scaleType"
                    label="量表类型">
                    <dictionaries-picker
                        type="scaleType"
                        v-model="selectScaleDialog.queryFormModel.scaleType"
                    />
                </el-form-item>-->
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectScaleDialogQuery()">
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectScaleDialog">
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectScaleDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectScaleDialogTable"
                    @selection-change="onSelectScaleDialogSelectionChange"
                >
                    <!--          <el-table-column
            type="selection"
            :reserve-selection="true"
            width="36"
          />-->
                    <el-table-column
                        prop="scaleName"
                        label="量表名称"
                        min-width="100" />
                    <el-table-column
                        prop="scaleSn"
                        label="量表编码"
                        min-width="100" />
                    <el-table-column
                        prop="scaleTypeName"
                        label="量表类型"
                        min-width="100"
                    />
                    <el-table-column
                        prop="testMinutes"
                        label="测试时长（分钟）"
                        width="120"
                    />
                    <el-table-column
                        prop="orderNum"
                        label="排序号"
                        min-width="100" />
                    <el-table-column
                        prop="isAvailable"
                        label="是否可用"
                        min-width="100">
                        <template slot-scope="scope">
                            {{ scope.row.isAvailable ? '是' : '否' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="创建时间"
                        width="135">
                        <template slot-scope="scope">
                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="修改时间"
                        width="135">
                        <template slot-scope="scope">
                            {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="90">
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectScaleConfirm(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectScaleDialog.pagination"
                        @input="onSelectScaleDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer">
                <!--        <el-button type="primary" size="small" @click="onSelectScaleConfirm">
          确 定
        </el-button>-->
                <el-button
                    size="small"
                    @click="onSelectScaleCancel"> 取 消 </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ScaleEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                planName: '',
                planTime: '',
                beginTime: '',
                endTime: '',
                remarks: '',
                planType: '2',
                planStatus: '',
                planImage: [],
                planDesc: '',
            },
            // 表单校验规则
            formRules: {
                planName: {
                    required: true,
                    message: '请输入计划名称',
                },
                planTime: {
                    type: 'array',
                    required: true,
                    message: '请选择计划时间',
                },
                planImage: {
                    type: 'array',
                    required: true,
                    message: '请上传',
                },
            },
            selectScaleList: [],
            selectScaleDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    scaleName: '',
                    scaleSn: '',
                    scaleType: '2',
                    isAvailable: '1',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
        };
    },
    methods: {
        onConfirm(planStatus) {
            const { addForm } = this.$refs;
            this.$utils.validateForm([addForm], true).then(() => {
                if (this.selectScaleList.length === 0) {
                    this.$message({
                        message: '请选择量表',
                        type: 'error',
                    });
                    return;
                }
                [this.formModel.beginTime, this.formModel.endTime] = this.formModel.planTime;
                this.$api.Am.AssessPlan.save({
                    ...this.formModel,
                    scaleId: this.selectScaleList[0].id,
                    planStatus,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Am.AssessPlan.getDetail({
                id: this.formModel.id,
            }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res.assessPlan);
                this.formModel.planTime = [this.formModel.beginTime, this.formModel.endTime];
                if (res.selectScaleList) {
                    this.selectScaleList = res.selectScaleList;
                }
            });
        },
        onSelectScaleDialogSelectionChange(val) {
            this.selectScaleDialog.currentTableSelect = val;
        },
        onAddSelectScale() {
            this.selectScaleDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectScaleDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectScaleDialogTable) {
                    this.$refs.selectScaleDialogTable.clearSelection();
                }
                this.selectScaleList.forEach(item => {
                    this.$refs.selectScaleDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectScaleDialogQuery(pagination) {
            return this.$api.Am.Scale.data({
                ...this.selectScaleDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectScaleDialog.tableData = res.data;
                this.selectScaleDialog.pagination = res.pagination;
            });
        },
        onResetSelectScaleDialog() {
            this.$refs.selectScaleDialogQueryForm.resetFields();
        },
        onSelectScaleConfirm(row) {
            // 插入新数据
            if (row) {
                this.selectScaleList = [{ ...row }];
            } else {
                const list = [];
                this.selectScaleDialog.currentTableSelect.forEach(item => {
                    let isHave = false;
                    this.selectScaleList.forEach(oldItem => {
                        if (item.id === oldItem.id) {
                            list.push({ ...oldItem });
                            isHave = true;
                        }
                    });
                    if (!isHave) {
                        // 添加新数据
                        list.push({ ...item });
                    }
                });
                this.selectScaleList = [...list];
            }
            this.selectScaleDialog.isVisible = false;
        },
        onSelectScaleCancel() {
            this.selectScaleDialog.isVisible = false;
        },
        onDeleteSelectScale(row) {
            this.selectScaleList.splice(this.selectScaleList.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
