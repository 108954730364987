import { HttpBase } from '@/api/base/HttpBase';

class Ce extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/ce';
    }
}
export default new Ce();
export { Ce };
