import Station from './Station';
import Device from './Device';
import Doctor from './Doctor';
import SchedulingPlan from './SchedulingPlan';
import Schedule from './Schedule';
import DoctorReservation from './DoctorReservation';
import PhysicalExamItem from './PhysicalExamItem';
import MedicalReport from './MedicalReport';
import PhysicalExamReservation from './PhysicalExamReservation';
import DoctorDepartment from './DoctorDepartment';
import CaseCat from './CaseCat';
import CaseItem from './CaseItem';

export default {
    Station,
    Device,
    Doctor,
    SchedulingPlan,
    Schedule,
    PhysicalExamItem,
    DoctorReservation,
    PhysicalExamReservation,
    MedicalReport,
    DoctorDepartment,
    CaseCat,
    CaseItem,
};
