<!-- Created by henian.xu on 2019/10/14. -->

<template>
    <div
        class="login-page"
        :style="selfStyles"
    >
        <!-- -&#45;&#45;{{ backUrl }}-&#45;&#45;-->
        <div class="inner">
            <div class="header">
                {{ siteDetail.name }}
            </div>
            <div class="body">
                <div class="form-input">
                    <div class="label">
                        {{ $$t('vmf_account', '账号') }}:
                    </div>
                    <div class="input">
                        <input
                            type="text"
                            v-model="formModel.account"
                            @keydown.enter="()=>{onSubmit()}"
                        >
                        <transition name="form-input-show">
                            <div
                                v-show="!formModel.account"
                                class="error"
                            >
                                {{ $$t('vmf_please_enter_account', '请输入账号') }}
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="form-input">
                    <div class="label">
                        {{ $$t('vmf_password', '密码') }}:
                    </div>
                    <div class="input">
                        <input
                            type="password"
                            v-model="formModel.password"
                            @keydown.enter="()=>{onSubmit()}"
                        >
                        <transition name="form-input-show">
                            <div
                                v-show="!formModel.password"
                                class="error"
                            >
                                {{ $$t('vmf_please_enter_password', '请输入密码') }}
                            </div>
                        </transition>
                    </div>
                </div>
                <div
                    v-if='showVerificationCode'
                    class="form-input">
                    <div class="label">
                        {{ $$t('vmf_verificationCode', '验证码') }}:
                    </div>
                    <div class="input">
                        <div class='group'>
                            <input
                                v-model="formModel.verificationCode"
                                @keydown.enter="()=>{onSubmit()}"
                            >
                            <img
                                @click="onAuthCodeChange"
                                class="captcha-img"
                                :src="captchaSrc">
                        </div>

                        <transition name="form-input-show">
                            <div
                                v-show="!formModel.verificationCode"
                                class="error"
                            >
                                {{ $$t('vmf_please_enter_verificationCode', '请输入验证码') }}
                            </div>
                        </transition>
                    </div>
                </div>
                <div
                    class="submit"
                    @click="()=>{onSubmit()}"
                >
                    {{ $$t('vmf_immediately_log_in', '立即登录') }}
                </div>
                <!--<el-form
                    ref="form"
                    label-width="60px"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        label="账号:"
                        prop="account"
                    >
                        <el-input
                            v-model="formModel.account"
                            placeholder="请输入"
                        />
                    </el-form-item>
                    <el-form-item
                        label="密码:"
                        prop="password"
                    >
                        <el-input
                            type="password"
                            placeholder="请输入"
                            v-model="formModel.password"
                        />
                    </el-form-item>
                    &lt;!&ndash;<el-form-item label="验证码:">
                        <div class="captcha">
                            <el-input v-model="formModel.type"/>
                            <img
                                class="captcha-img"
                                src="@/assets/images/captcha.png">
                        </div>
                    </el-form-item>&ndash;&gt;
                    &lt;!&ndash;<el-form-item class="nma-a">&ndash;&gt;
                    <el-button
                        class="w-100per"
                        type="primary"
                        @click="onSubmit"
                    >
                        立即登录
                    </el-button>
                    &lt;!&ndash;</el-form-item>&ndash;&gt;
                </el-form>-->
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Utils } from 'vmf';
// import { GlobalVar } from '@vmf/shared';

const { mapActions } = createNamespacedHelpers('User');

export default {
    name: 'LoginPage',
    data() {
        return {
            siteDetail: this.$utils.GlobalVar.site || { name: this.$$t('vmf_management_system', '管理系统') },
            formModel: {
                account: process.env.VUE_APP_LOGIN_ACCOUNT || '',
                password: process.env.VUE_APP_LOGIN_PASSWORD || '',
                verificationCode: '',
            },
            captchaSrc: '',
        };
    },
    beforeRouteEnter(to, from, next) {
        const { backUrl } = to.query;
        if (backUrl) {
            next();
            return;
        }
        next({
            ...to,
            replace: true,
            query: {
                ...to.query,
                backUrl: from.fullPath,
            },
        });
    },
    computed: {
        backUrl() {
            let backUrl = decodeURIComponent(this.$route.query.backUrl || '/');
            if (/^\/login/.test(backUrl)) {
                backUrl = '/';
            }
            return backUrl;
        },
        selfStyles() {
            const { GlobalVar } = this.$utils;
            const bgUrl =
                (GlobalVar.site && GlobalVar.site.loginImageUrl) ||
                (GlobalVar.appConfig && GlobalVar.appConfig.LOGIN_IMAGE_URL);
            if (!bgUrl) return {};
            return {
                backgroundImage: `url(${bgUrl})`,
            };
        },
        showVerificationCode() {
            const { GlobalVar } = this.$utils;
            return GlobalVar.showVerificationCode;
        },
    },
    methods: {
        ...mapActions(['login']),

        onAuthCodeChange() {
            const { GlobalVar } = this.$utils;
            const baseURL = ((GlobalVar.appConfig && GlobalVar.appConfig.AXIOS_DEFAULT_CONFIG) || {}).baseURL || '';
            const id = this.$utils.getUniqueId('id-');
            this.captchaSrc = `${baseURL}/rs/captcha/get-captcha?v=${id}`;
        },
        async onSubmit() {
            const { formModel, backUrl, showVerificationCode } = this;
            if (!formModel.account || !formModel.password) return;
            if (showVerificationCode && !formModel.verificationCode) return;
            try {
                Utils.$bus.$emit('before-login');
                await this.login(formModel);
                Utils.$bus.$emit('login');
            } catch (e) {
                this.onAuthCodeChange();
                return;
            }
            this.onAuthCodeChange();

            this.$router.replace(backUrl);
        },
    },
    created() {
        this.onAuthCodeChange();
        this.$nextTick(() => {
            document.documentElement.classList.add('html-full');
        });
    },
    destroyed() {
        document.documentElement.classList.remove('html-full');
    },
};
</script>

<style lang="scss">
.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #04255a center no-repeat;
    background-size: cover;
    min-height: 100%;
    min-width: 1200px;

    > .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > .header {
            letter-spacing: 5px;
            //font-size: 50px;
            //font-weight: bold;
            margin-bottom: 20px;
            font-size: 33px;
            color: #baf3f2;
            font-weight: 600;
        }
        > .body {
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.3);
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
            padding: 32px;
            border-radius: 10px;
            width: 400px;
            color: #fff;

            > .form-input {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
                font-size: 15px;
                > .label {
                    flex: 0 0 auto;
                    text-align: right;
                    margin-right: 10px;
                }
                > .input {
                    position: relative;
                    flex: 1 1 1%;
                    > input,
                    > .group > input {
                        position: relative;
                        z-index: 100;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                        background-color: rgba(0, 0, 0, 0.4);
                        border: 1px solid rgba(0, 0, 0, 0.6);
                        color: #efef;
                        border-radius: 5px;
                        outline: none;
                        padding: 0 10px;
                        box-sizing: border-box;
                    }

                    > .group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        > input {
                            flex: 1 1 1%;
                            width: 1%;
                        }

                        > .captcha-img {
                            flex: 0 0 auto;
                            display: block;
                            width: 100px;
                            height: 40px;
                            margin-left: 10px;
                        }
                    }
                    > .error {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 100%;
                        color: #f00;
                    }
                }
            }
            > .submit {
                line-height: 40px;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                user-select: none;

                padding: 0 15px;
                font-size: 16px;
                height: 40px;
                width: 100%;
                border: 0;
                background: #3fbbcc;
            }
        }
    }
}

@keyframes form-input-in {
    0% {
        opacity: 0;
        transform: translate(0, -100%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes form-input-out {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, -100%);
    }
}

.form-input-show-enter-active {
    animation: form-input-in 0.5s;
}

.form-input-show-leave-active {
    animation: form-input-out 0.5s;
}
</style>
