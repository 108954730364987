import { Mb } from './Mb';

class ActivityMember extends Mb {
    constructor() {
        super();
        this.baseUrl += '/activityMember';
    }

    data({ orgName, activityId, fullName, mobile, applyTime, signInTime, signInStatus, currentPage, pageSize } = {}) {
        let applyTimeBegin, applyTimeEnd;
        if (applyTime && applyTime.length === 2) {
            [applyTimeBegin, applyTimeEnd] = applyTime;
        }

        let signInTimeBegin, signInTimeEnd;
        if (signInTime && signInTime.length === 2) {
            [signInTimeBegin, signInTimeEnd] = signInTime;
        }
        return super.get('/data', {
            orgName,
            activityId,
            fullName,
            mobile,
            applyTimeBegin,
            applyTimeEnd,
            signInTimeBegin,
            signInTimeEnd,
            signInStatus,
            currentPage,
            pageSize,
        });
    }
}
export default new ActivityMember();
export { ActivityMember };
