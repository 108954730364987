<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="schedulingRuleType"
                    label="规则类型"
                >
                    <dictionaries-picker
                        type="schedulingRuleType"
                        v-model="queryFormModel.schedulingRuleType"
                        placeholder="请选择"
                    />
                </el-form-item>
                <el-form-item
                    prop="schedulingPlanName"
                    label="计划名称"
                >
                    <el-input
                        v-model="queryFormModel.schedulingPlanName"
                        placeholder="请输入计划名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="beginDate"
                    label="开始日期"
                >
                    <el-date-picker
                        v-model="queryFormModel.beginDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择开始日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="endDate"
                    label="结束日期"
                >
                    <el-date-picker
                        v-model="queryFormModel.endDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/pe/schedule/station/schedulingPlan/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
                <el-button-group>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="50"
                />
                <el-table-column
                    prop="schedulingRuleTypeName"
                    label="规则类型"
                    min-width="100"
                />
                <el-table-column
                    prop="schedulingPlanName"
                    label="计划名称"
                    min-width="100"
                />
                <el-table-column
                    prop="beginDate"
                    label="开始日期"
                    min-width="100"
                />
                <el-table-column
                    prop="endDate"
                    label="结束日期"
                    min-width="100"
                />
                <el-table-column
                    prop="updateTime"
                    label="更新时间"
                    min-width="120"
                >
                    <template #default="scope">
                        {{scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="insertTime"
                    label="创建时间"
                    min-width="120"
                >
                    <template #default="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="operatorName"
                    label="创建人"
                    min-width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="140"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template #default="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pe/schedule/station/schedulingPlan/view/' + scope.row.id)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'SchedulingPlan',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                schedulingRuleType: '',
                schedulingObjectType: 1,
                schedulingPlanName: '',
                beginDate: '',
                endDate: '',
            },
            currentTableSelect: [],
            tableData: [],
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Pe.SchedulingPlan.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pe.SchedulingPlan.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style scoped lang="scss">
</style>
