import { Rs } from './Rs';

class LanguageModule extends Rs {
    constructor() {
        super();
        this.baseUrl += '/languageModule';
    }

    data({ moduleCode, moduleName, availableLanguage, currentPage, pageSize } = {}) {
        return super.get('/data', {
            moduleCode,
            moduleName,
            availableLanguage,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, moduleName, moduleCode, moduleDesc, availableLanguage, defaultLanguage } = {}) {
        return super.post('/save', {
            id,
            moduleName,
            moduleCode,
            moduleDesc,
            availableLanguage,
            defaultLanguage,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    getAvailableLanguage({ id } = {}) {
        return super.get('/getAvailableLanguage', {
            id,
        });
    }
}
export default new LanguageModule();
export { LanguageModule };
