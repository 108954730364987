/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class Console extends Rs {
    constructor() {
        super();
        this.baseUrl += '/console';
    }

    index(amountDate) {
        let amountDateBegin = null;
        let amountDateEnd = null;
        if (amountDate != null && amountDate.length === 2) {
            [amountDateBegin, amountDateEnd] = amountDate;
        }
        return super.get('/index', { amountDate, amountDateBegin, amountDateEnd });
    }

    expireDate(expireDate) {
        return super.get('/index', {
            expireDate,
        });
    }
}

export default new Console();
export { Console };
