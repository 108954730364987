import Course from '../ce/Course';
import CourseDetail from '../ce/CourseDetail';
import CourseCat from '../ce/CourseCat';
import CourseComment from '../ce/CourseComment';
import CourseMember from '../ce/CourseMember';

export default {
    Course,
    CourseDetail,
    CourseCat,
    CourseComment,
    CourseMember,
};
