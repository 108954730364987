<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='integral-ranking'>
        <div
            v-for='(item,index) in listData'
            :key='item.id'
            class='item'>
            <div class='bg'></div>
            <div class='inner'>
                <div class='serial'>
                    <div
                        class='bg'
                        :style='index=== 0 ? `background-color: #e4a60b;`:""'></div>
                    <div class='inner'>NO.{{index + 1}}</div>
                </div>
                <div class='label'>{{ item.fullName }}</div>
                <div class='value'>{{item.totalPoint}}</div>
                <div class='branch'>{{ item.orgName }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { rsLargeScreenMixin } from 'pages/largeScreen/store';

export default {
    name: 'IntegralRanking',
    mixins: [rsLargeScreenMixin],
    data() {
        return {};
    },
    computed: {
        listData() {
            const list = [...this.ranking];
            return list.splice(0, 10);
        },
    },
};
</script>

<style lang='scss'>
.integral-ranking {
    height: 100%;
    padding: $padding-small 0;

    > .item {
        position: relative;

        > .bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to right, #3e1406, transparent);
            background-color: transparent;
            transform: skewX(-20deg);
        }
        > .inner {
            position: relative;
            z-index: 10;
            padding: 0 $padding;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;

            > .serial {
                position: relative;
                flex: 0 0 60px;
                padding: $padding-small;
                text-align: center;

                > .bg {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: #cc6014;
                    //transform: skew(-20deg);
                    clip-path: polygon(0 0, 100% 0, 100% 0, 90% 100%, 10% 100%);
                }
                > .inner {
                    position: relative;
                    z-index: 10;
                }
            }
            > .label {
                flex: 0 0 150px;
                padding: $padding-small;
            }
            > .value {
                flex: 0 0 50px;
                padding: $padding-small;
                color: #cc6014;
            }
            > .branch {
                flex: 1 1 1%;
                padding: $padding-small;
                text-align: right;
            }
        }

        + .item {
            margin-top: $margin;
        }
    }
}
</style>
