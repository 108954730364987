/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ce } from './Ce';

class Course extends Ce {
    constructor() {
        super();
        this.baseUrl += '/course';
    }

    data({ courseCatId, courseName, courseStatus, courseLearnType, currentPage, pageSize } = {}) {
        return super.get('/data', {
            courseCatId,
            courseName,
            courseStatus,
            courseLearnType,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { fileBasePath } = json.data.data;
            const { courseImage, addressLatitude, addressLongitude } = json.data.data.course;
            if (!courseImage) {
                json.data.data.course.courseImage = [];
            } else {
                json.data.data.course.courseImage = courseImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (addressLatitude && addressLongitude) {
                json.data.data.course.latLng = `${addressLongitude},${addressLatitude}`;
            }
            return json;
        });
    }

    save1({
        id,
        courseCatId,
        courseName,
        courseImage,
        courseHour,
        courseDesc,
        courseLearnType,
        courseMemberNum,
        courseAmount,
        courseTime,
        courseRegionId,
        courseDetailAddress,
        latLng,
    } = {}) {
        const courseImageStr = courseImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        let addressLongitude = null;
        let addressLatitude = null;
        if (latLng) {
            [addressLongitude, addressLatitude] = latLng.split(',');
        }
        return super.post('/save1', {
            id,
            courseCatId,
            courseName,
            courseImage: courseImageStr,
            courseHour,
            courseDesc,
            courseLearnType,
            courseMemberNum,
            courseAmount,
            courseTime,
            courseRegionId,
            courseDetailAddress,
            addressLongitude,
            addressLatitude,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    select() {
        return super.get('/select', {});
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    release({ ids } = {}) {
        return super.post('/release', {
            ids,
        });
    }

    cancelRelease({ ids } = {}) {
        return super.post('/cancelRelease', {
            ids,
        });
    }

    audit({ id, auditStatus, auditRemarks } = {}) {
        return super.post('/audit', {
            id,
            auditStatus,
            auditRemarks,
        });
    }
}

export default new Course();
export { Course };
