/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { HttpBase } from '@/api/base/HttpBase';

class Sv extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/sv';
    }
}

export default new Sv();
export { Sv };
