<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="departmentId"
                    label="组织机构"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.departmentId"
                        :query-params="{isFilterAuth:1}"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="comments"
                    label="评论内容"
                >
                    <el-input
                        v-model="queryFormModel.comments"
                        placeholder="请输入评论内容"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @expand-change="findCommentList"
            >
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            stripe
                            border
                            size="small"
                            :ref="'table' + scope.row.id"
                            :data="scope.row.commentList"
                        >
                            <el-table-column
                                prop="orgNamtPath"
                                label="评论用户"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <div>{{scope.row.orgNamtPath}}</div>
                                    <div>{{scope.row.fullName}}</div>
                                    <div>{{scope.row.mobile}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orgNamtPath"
                                label="评论对象"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <div>{{scope.row.replyOrgNamtPath}}</div>
                                    <div>{{scope.row.replyFullName}}</div>
                                    <div>{{scope.row.replyMobile}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="comments"
                                label="评论内容"
                                min-width="200"
                            />
                            <el-table-column
                                label="评论时间"
                                width="135"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.releaseTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column
                                label="评论数"
                                width="80"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.commentCount }}
                                </template>
                            </el-table-column>-->
                            <el-table-column
                                label="是否隐藏"
                                width="80">
                                <template slot-scope="statusScope">
                                    <el-switch
                                        v-model="statusScope.row.isDelete"
                                        @change="(isSelect) => changeStatus(statusScope.row, isSelect)"
                                        :active-value="true"
                                        :inactive-value="false"
                                    />
                                </template>
                            </el-table-column>
                        </el-table>

                        <div class="ma-t ta-r">
                            <!-- 分页 -->
                            <pagination
                                v-model="scope.row.pagination"
                                @input="findCommentList(scope.row)"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orgNamtPath"
                    label="评论用户"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <div>{{scope.row.orgNamtPath}}</div>
                        <div>{{scope.row.fullName}}</div>
                        <div>{{scope.row.mobile}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="comments"
                    label="评论内容"
                    min-width="200"
                />
                <el-table-column
                    label="评论时间"
                    width="135"
                >
                    <template #default="scope">
                        {{ scope.row.releaseTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="评论数"
                    width="80"
                >
                    <template slot-scope="scope">
                        {{ scope.row.commentCount }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否隐藏"
                    width="80">
                    <template slot-scope="statusScope">
                        <el-switch
                            v-model="statusScope.row.isDelete"
                            @change="(isSelect) => changeStatus(statusScope.row, isSelect)"
                            :active-value="true"
                            :inactive-value="false"
                        />
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CmsArticle',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                activityId: '',
                activityCommentId: '',
                departmentId: '',
                fullName: '',
                mobile: '',
                comments: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Rs.Org,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.ActivityComment.data(queryData).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    this.$set(item, 'pagination', {});
                    this.$set(item, 'commentList', []);
                });
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        changeStatus(row, isSelect) {
            this.$api.Mb.ActivityComment.status({ id: row.id, isDelete: isSelect }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        findCommentList(row, type) {
            this.$api.Mb.ActivityComment.data({
                activityId: this.queryFormModel.activityId,
                activityCommentId: row.id,
                ...row.pagination,
            }).then(json => {
                const res = json.data;
                row.pagination = res.pagination;
                row.commentList = res.data;
            });
        },
    },
    created() {
        this.queryFormModel.activityId = this.$route.params.id;
    },
};
</script>

<style lang="scss">
</style>
