import { Pe } from './Pe';

class CaseItem extends Pe {
    constructor() {
        super();
        this.baseUrl += '/caseItem';
    }

    data({ caseCatId, caseCatName, name, nameEn, nameOther, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            caseCatId,
            caseCatName,
            name,
            nameEn,
            nameOther,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const res = json.data.data;
                const { fileBasePath, icon } = res;
                if (fileBasePath) {
                    if (icon) {
                        res.icon = icon.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    } else {
                        res.icon = [];
                    }
                }
                return json;
            });
    }
    save({
        id,
        caseCatIdList,
        icon,
        name,
        nameEn,
        nameOther,
        overview,
        symptoms,
        causes,
        treatment,
        daily,
        seekingMedicalAdvice,
        remarks,
        isRecommend,
        isAvailable,
    } = {}) {
        const iconStr = icon
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        let caseCatIds = '';
        if (caseCatIdList && caseCatIdList.length) {
            caseCatIds = caseCatIdList.join(',');
        }
        return super.post('/save', {
            id,
            caseCatIds,
            icon: iconStr,
            name,
            nameEn,
            nameOther,
            overview,
            symptoms,
            causes,
            treatment,
            daily,
            seekingMedicalAdvice,
            remarks,
            isRecommend,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new CaseItem();
export { CaseItem };
