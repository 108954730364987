/**
 * Created by henian.xu on 2020/2/14.
 *
 */

import { get, post, postJson, href, createCancelToken } from '@vmf/request';
import { VueConstructor } from 'vue';

const Http = Object.freeze({
    get,
    post,
    postJson,
    href,
    createCancelToken,
});

export default (vue: VueConstructor) => {
    Object.defineProperty(vue, '$http', {
        get(): typeof Http {
            return Http;
        },
    });
    Object.defineProperty(vue.prototype, '$http', {
        get(): typeof Http {
            return Http;
        },
    });
};

declare module 'vue/types/vue' {
    export interface Vue {
        readonly $http?: typeof Http;
        // readonly $api?: ApiWrap;
    }
}
