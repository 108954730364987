<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template slot="body">
            <el-row>
                <el-col :span="24">
                    <el-form
                        ref="addForm"
                        size="small">
                        <el-card class="box-card ma-t">
                            <div
                                slot="header"
                                class="clearfix">
                                <span>量表信息</span>
                            </div>
                            <el-col :span="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="量表名称:"
                                        label-width="8em">
                                        {{ scale.scaleName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="量表编码:"
                                        label-width="8em">
                                        {{ scale.scaleSn }}
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :span="24">
                                <!--                                <el-col :span="12">
                                    <el-form-item
                                        label="量表用途:"
                                        label-width="8em">
                                        {{ scale.scalePurposeName }}
                                    </el-form-item>
                                </el-col>-->
                                <el-col :span="12">
                                    <el-form-item
                                        label="量表类型:"
                                        label-width="8em">
                                        {{ scale.scaleTypeName }}
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :span="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="量表介绍:"
                                        label-width="8em">
                                        {{ scale.scaleDesc }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="关键词:"
                                        label-width="8em">
                                        {{ scale.scaleKeywords }}
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :span="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="简介:"
                                        label-width="8em">
                                        {{ scale.scaleBrief }}
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :span="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="测试时长(分钟):"
                                        label-width="8em">
                                        {{ scale.testMinutes }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="是否可用:"
                                        label-width="8em">
                                        {{ scale.isAvailable ? '是' : '否' }}
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-card>
                        <el-card class="box-card ma-t">
                            <div
                                slot="header"
                                class="clearfix">
                                <span>题目信息</span>
                            </div>
                            <div class="question-wrap">
                                <div class="header">
                                    <!--                  <div class="label">{{ scale.scaleName }}</div>-->
                                    <!--                <div class="time">{{ scale.testMinutes }}分钟</div>-->
                                </div>
                                <div
                                    class="body"
                                    v-for="question in questionList"
                                    :key="question.id"
                                >
                                    <div class="label">
                                        {{ question.orderNum }}、{{ question.questionTitle }}
                                    </div>
                                    <div class="inner">
                                        <div class="item">
                                            <el-radio-group v-model="question.selectAnswerId">
                                                <el-radio
                                                    v-for="answer in question.answerList"
                                                    :key="answer.id"
                                                    :label="answer.id"
                                                >
                                                    {{ indexToString(answer.orderNum - 1) }}:{{
                                                        answer.answerTitle
                                                    }}
                                                </el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>

                        <el-card class="box-card ma-t">
                            <el-form-item label-width="10em">
                                <el-button
                                    size="small"
                                    @click="$router.back()">
                                    返 回
                                </el-button>
                            </el-form-item>
                        </el-card>
                    </el-form>
                </el-col>
            </el-row>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ScaleEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            id: '',
            scale: {},
            questionList: [],
        };
    },
    methods: {
        init() {
            this.$api.Am.Scale.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.scale = res.scaleDetail;
                this.questionList = res.questionList;
            });
        },
        indexToString(index) {
            let charcode;
            return index
                .toString(26)
                .split('')
                .map(function(item, i) {
                    charcode = item.charCodeAt(0);
                    charcode += charcode >= 97 ? 10 : 49;
                    return String.fromCharCode(charcode);
                })
                .join('')
                .toUpperCase();
        },
        test() {},
    },
    created() {
        this.id = this.$route.params.id;
        this.init();
    },
};
</script>

<style lang="scss">
.question-wrap {
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1 1 1%;
            font-size: $more * 100%;
            font-weight: bold;
            text-align: center;
        }
        > .time {
            flex: 0 0 auto;
        }
    }

    > .body {
        margin-top: $margin;
        > .label {
            font-weight: bold;
        }
        > .inner {
            > .item {
                > .el-radio-group {
                    display: block;
                    > .el-radio {
                        display: block;
                        padding: $padding 0;
                        margin-right: 0;
                    }
                }
                + .item {
                    margin-top: $margin;
                }
            }
        }
    }
}
</style>
