/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Servant from './Servant';
import ServantExpress from './ServantExpress';
import ExpressBillRange from './ExpressBillRange';
import Shop from './Shop';
import ServantDecoration from './ServantDecoration';
import ServantPaymentConfig from './ServantPaymentConfig';

export default {
    Servant,
    ServantExpress,
    ExpressBillRange,
    Shop,
    ServantDecoration,
    ServantPaymentConfig,
};
