<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        prop="stationId"
                        label="站点"
                        label-width="12em"
                    >
                        <el-button
                            size="small"
                            type="success"
                            @click="openStationDialog"
                        >
                            选择
                        </el-button>

                        <el-table
                            class="ma-t"
                            stripe
                            border
                            size="small"
                            ref="stationDialogTable"
                            :data="stationDialog.stationList"
                            row-key="id"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="stationName"
                                label="站点名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="stationSn"
                                label="站点编号"
                                min-width="100"
                            />
                            <el-table-column
                                prop="stationLogo"
                                label="站点LOGO"
                                width="90"
                            >
                                <template v-slot="scope">
                                    <imageList
                                        :data="scope.row.stationLogoUrl"
                                        :item-width="50"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="stationOpenTime"
                                label="站点营业时间"
                                min-width="100"
                            />
                            <el-table-column
                                prop="stationRegionName"
                                label="站点地区"
                                min-width="100"
                            />
                            <el-table-column
                                prop="stationDetailAddress"
                                label="站点详细地址"
                                min-width="100"
                            />
                            <el-table-column
                                prop="contactFullname"
                                label="联系人姓名"
                                width="100"
                            />
                            <el-table-column
                                prop="contactTel"
                                label="联系人电话"
                                width="100"
                            />
                            <el-table-column
                                prop="remarks"
                                label="备注"
                                min-width="100"
                            />
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="60"
                            >
                                <template #default="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="deleteStationDialog(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="deviceType"
                        label="设备类型"
                        label-width="12em"
                    >
                        <dictionaries-picker
                            type="deviceType"
                            v-model="formModel.deviceType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="deviceSn"
                        label="设备号"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.deviceSn"
                            placeholder="请输入设备号"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="deviceName"
                        label="设备名称"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.deviceName"
                            placeholder="请输入设备名称"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="deviceStatus"
                        label="设备状态"
                        label-width="12em"
                    >
                        <dictionaries-picker
                            type="deviceStatus"
                            v-model="formModel.deviceStatus"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="12em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            placeholder="请输入备注"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-dialog
            title="选择站点"
            center
            width="1200px"
            :visible.sync="stationDialog.isVisible"
            @closed="cancelStationDialog"
        >
            <el-form
                ref="queryStationForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="stationDialog.queryFormModel"
            >
                <el-form-item
                    prop="stationName"
                    label="站点名称"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.stationName"
                        placeholder="请输入站点名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="stationSn"
                    label="站点编号"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.stationSn"
                        placeholder="请输入站点编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="stationRegionId"
                    label="站点地区"
                >
                    <CascaderPicker
                        clearable
                        filterable
                        check-strictly
                        :api-class="regionApi"
                        v-model="stationDialog.queryFormModel.stationRegionId"
                    />
                </el-form-item>
                <el-form-item
                    prop="contactFullname"
                    label="联系人姓名"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.contactFullname"
                        placeholder="请输入联系人姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="contactTel"
                    label="联系人电话"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.contactTel"
                        placeholder="请输入联系人电话"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="queryStationDialog()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="resetStationDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-table
                stripe
                border
                size="small"
                ref="stationDialogTable"
                :data="stationDialog.tableData"
                row-key="id"
                style="width: 100%"
                @selection-change="changeSelectedStationDialog"
            >
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="50"
                />
                <el-table-column
                    prop="stationName"
                    label="站点名称"
                    min-width="100"
                />
                <el-table-column
                    prop="stationSn"
                    label="站点编号"
                    min-width="100"
                />
                <el-table-column
                    prop="stationLogo"
                    label="站点LOGO"
                    width="90"
                >
                    <template v-slot="scope">
                        <imageList
                            :data="scope.row.stationLogoUrl"
                            :item-width="50"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="stationOpenTime"
                    label="站点营业时间"
                    min-width="100"
                />
                <el-table-column
                    prop="stationRegionName"
                    label="站点地区"
                    min-width="100"
                />
                <el-table-column
                    prop="stationDetailAddress"
                    label="站点详细地址"
                    min-width="100"
                />
                <el-table-column
                    prop="contactFullname"
                    label="联系人姓名"
                    width="100"
                />
                <el-table-column
                    prop="contactTel"
                    label="联系人电话"
                    width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template #default="scope">
                        <el-button
                            type="success"
                            size="small"
                            @click="confirmStationDialog(scope.row)"
                        >
                            选 择
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="stationDialog.pagination"
                    @input="queryStationDialog"
                />
            </div>

            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="cancelStationDialog"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { Utils } from 'vmf';
export default {
    name: 'StationEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                stationId: '',
                deviceType: '',
                deviceSn: '',
                deviceName: '',
                deviceStatus: '',
                remarks: '',
            },
            // 表单校验规则
            formRules: {
                stationId: {
                    required: true,
                    message: '请选择',
                },
                deviceType: {
                    required: true,
                    message: '请选择',
                },
                deviceSn: [
                    {
                        required: true,
                        message: '请输入',
                    },
                    {
                        validator: this.checkDeviceSn,
                        message: '设备号不能重复',
                    },
                ],
                deviceName: {
                    required: true,
                    message: '请输入',
                },
                deviceStatus: {
                    required: true,
                    message: '请选择',
                },
            },
            stationDialog: {
                isVisible: false,
                queryFormModel: {
                    stationName: '',
                    stationSn: '',
                    stationRegionId: '',
                    contactFullname: '',
                    contactTel: '',
                    isAvailable: 1,
                },
                tableData: [],
                currentTableSelect: [],
                pagination: {},
                stationList: [],
            },
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pe.Device.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pe.Device.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.stationDialog.stationList = res.stationList || [];
            });
        },
        checkDeviceSn: Utils.debounce(function func(rule, value, callback) {
            this.$api.Pe.Device.checkDeviceSn({
                id: this.formModel.id,
                deviceSn: this.formModel.deviceSn,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        }, 500),
        // region stationDialog
        openStationDialog() {
            this.stationDialog.isVisible = true;
            this.$nextTick(() => {
                const { queryStationForm } = this.$refs;
                if (queryStationForm) {
                    queryStationForm.resetFields();
                }
                this.queryStationDialog();
                const { stationDialogTable } = this.$refs;
                // 初始化已选择数据
                if (stationDialogTable) {
                    stationDialogTable.clearSelection();
                }
                this.stationDialog.stationList.forEach(item => {
                    stationDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        cancelStationDialog() {
            this.stationDialog.isVisible = false;
        },
        changeSelectedStationDialog(val) {
            this.stationDialog.currentTableSelect = val;
        },
        queryStationDialog() {
            this.$api.Pe.Station.data({
                ...this.stationDialog.queryFormModel,
                ...this.stationDialog.pagination,
            }).then(json => {
                const res = json.data;
                this.stationDialog.tableData = res.data;
                this.stationDialog.pagination = res.pagination;
            });
        },
        resetStationDialog() {
            this.$refs.queryStationForm.resetFields();
        },
        confirmStationDialog(row) {
            this.stationDialog.stationList = [{ ...row }];
            this.formModel.stationId = row.id;
            this.stationDialog.isVisible = false;
        },
        deleteStationDialog(row) {
            this.stationDialog.stationList.splice(this.stationDialog.stationList.indexOf(row), 1);
            this.formModel.stationId = '';
        },
        // endregion
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
