/**
 * Created by henian.xu on 2018/7/18.
 *
 */
import { Utils } from 'vmf';
import { Am } from './Am';

const { GlobalVar } = Utils;

class AssessPlan extends Am {
    constructor() {
        super();
        this.baseUrl += '/assessPlan';
    }

    data({ planName, planStatus, planType, currentPage, pageSize } = {}) {
        return super.get('/data', {
            planName,
            planStatus,
            planType,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const planImage = json.data.data.assessPlan.planImage;
            if (!planImage) {
                json.data.data.assessPlan.planImage = [];
            } else {
                json.data.data.assessPlan.planImage = planImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, planName, beginTime, endTime, remarks, planType, planStatus, scaleId, planImage, planDesc } = {}) {
        const planImageStr = planImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            planName,
            beginTime,
            endTime,
            remarks,
            planType,
            planStatus,
            scaleId,
            planImage: planImageStr,
            planDesc,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    cancel({ ids } = {}) {
        return super.post('/cancel', {
            ids,
        });
    }
}

export default new AssessPlan();
export { AssessPlan };
