<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <canvas
        class='circle-progress'
        ref='canvas'
        :style='styles'></canvas>
</template>

<script>
export default {
    name: 'CircleProgress',
    data() {
        return {
            isMounted: false,
        };
    },
    props: {
        value: {
            type: Number,
            default: 50,
        },
        maxValue: {
            type: Number,
            default: 100,
        },
        size: {
            type: Number,
            default: 70,
        },
        backgroundColor: {
            type: String,
            default: 'rgba(228,66,63,0.2)',
            // default: '#e4423f4d',
        },
        foregroundColor: {
            type: String,
            default: '#e4423f',
        },
    },
    computed: {
        styles() {
            const { size } = this;
            return {
                width: `${size}px`,
                height: `${size}px`,
            };
        },
        progress() {
            const { value, maxValue } = this;
            return (value / maxValue) * 100;
        },
        progressChange() {
            const { progress, isMounted } = this;
            if (!isMounted) return -1;
            return isMounted && progress;
        },
    },
    watch: {
        progressChange: {
            handler(val) {
                if (val === -1) return;
                this.draw(this.progressToAngle(this.progress));
            },
            immediate: true,
        },
    },
    methods: {
        progressToAngle(progress) {
            return (progress / 100) * 360;
        },
        // 弧度=角度×(π÷180 )
        angleToRadian(angle) {
            return angle * (Math.PI / 180);
        },
        // 角度=弧度×(180÷π)
        radianToAngle(radian) {
            return radian * (180 / Math.PI);
        },
        initCtx() {
            const { canvas } = this.$refs;
            canvas.width = this.size;
            canvas.height = this.size;
            this.canvas = canvas;
            this.context = canvas.getContext('2d');
        },
        draw(angle) {
            const { canvas, context, size, backgroundColor, foregroundColor } = this;

            const startAngle = 270;
            const innerLineW = 4;
            const radius = size / 2 - innerLineW / 2;
            context.clearRect(0, 0, canvas.width, canvas.height);

            // 背景圆
            context.beginPath();
            context.strokeStyle = backgroundColor;
            context.lineWidth = innerLineW;
            context.arc(size / 2, size / 2, radius, this.angleToRadian(0), this.angleToRadian(360), false);
            context.stroke();

            // 背景圆
            context.beginPath();
            context.strokeStyle = foregroundColor;
            context.lineWidth = 1;
            context.arc(size / 2, size / 2, radius - innerLineW, this.angleToRadian(0), this.angleToRadian(360), false);
            context.stroke();

            // 前景圆
            context.beginPath();
            // context.lineCap = 'round';
            /*const gradient = context.createLinearGradient(-20, -20, canvas.width, canvas.height);
            gradient.addColorStop(0, '#24fc8e');
            gradient.addColorStop(0.8, '#059eee');*/
            const gradient = foregroundColor;
            if (this.progress) {
                context.strokeStyle = gradient;
                context.lineWidth = innerLineW;
                context.arc(
                    size / 2,
                    size / 2,
                    radius,
                    this.angleToRadian(startAngle),
                    this.angleToRadian(startAngle + angle),
                    false,
                );
            }
            context.stroke();
            let fontSize = size / 3;
            context.font = `bold ${fontSize}px sans-serif`;
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillStyle = '#ffffff';
            const offsetWidth = context.measureText(`${this.progress.toFixed(0)}`).width;
            context.fillText(`${this.progress.toFixed(0)}`, size / 2 - size / 5 / 2, size / 2 + fontSize * 0.1);
            fontSize = size / 5;
            context.font = `bold ${fontSize}px sans-serif`;
            context.textAlign = 'left';
            context.fillText(`%`, size / 2 + offsetWidth / 2 - size / 5 / 2, size / 2 + fontSize * 0.3);
            context.stroke();
        },
    },
    mounted() {
        this.initCtx();
        this.isMounted = true;
    },
};
</script>

<style lang='scss'>
</style>
