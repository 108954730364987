import { Pe } from './Pe';

class Schedule extends Pe {
    constructor() {
        super();
        this.baseUrl += '/schedule';
    }

    getSchedule({ selectDate, schedulingObjectType, businessTableName, objectId } = {}) {
        return super.get('/getSchedule', {
            selectDate,
            schedulingObjectType,
            businessTableName,
            objectId,
        });
    }
}
export default new Schedule();
export { Schedule };
