<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div :class="classes">
        <div
            class='inner'
            :style="styles">
            <div class='header'>
                <img src='./images/header-bg.png'>
            </div>
            <div class='body'>
                <div class='block left'>
                    <LargeScreenBox label='党员学习'>
                        <PartyMemberStudy></PartyMemberStudy>
                    </LargeScreenBox>
                    <LargeScreenBox
                        label='党员男女占比统计'
                        :flex-grow-shrink='1.2'>
                        <MaleToFemaleRatio></MaleToFemaleRatio>
                    </LargeScreenBox>
                    <LargeScreenBox
                        label='党员年龄层分析'
                        :flex-grow-shrink='1.4'>
                        <AgeAnalysis></AgeAnalysis>
                    </LargeScreenBox>
                </div>
                <div class='block center'>
                    <LargeScreenBox :flex-grow-shrink='1'>
                        <LargeScreenMap></LargeScreenMap>
                    </LargeScreenBox>
                    <LargeScreenBox :flex-grow-shrink='0'>
                        <ModuleStatistics></ModuleStatistics>
                    </LargeScreenBox>
                </div>
                <div class='block right'>
                    <LargeScreenBox
                        label='各支部党员'
                        :flex-grow-shrink='1'>
                        <PartyMemberBranchInfo></PartyMemberBranchInfo>
                    </LargeScreenBox>
                    <LargeScreenBox
                        label='党员积分榜'
                        :flex-grow-shrink='1.5'>
                        <IntegralRanking></IntegralRanking>
                    </LargeScreenBox>
                    <LargeScreenBox
                        label='赏费统计'
                        :flex-grow-shrink='1'>
                        <PartyRewardStatistics></PartyRewardStatistics>
                    </LargeScreenBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LargeScreenBox from 'pages/largeScreen/_largeScreenBox.vue';
import PartyMemberStudy from 'pages/largeScreen/_partyMemberStudy.vue';
import MaleToFemaleRatio from 'pages/largeScreen/_maleToFemaleRatio.vue';
import AgeAnalysis from 'pages/largeScreen/_ageAnalysis.vue';
import ModuleStatistics from 'pages/largeScreen/_moduleStatistics.vue';
import PartyMemberBranchInfo from 'pages/largeScreen/_partyMemberBranchInfo.vue';
import IntegralRanking from 'pages/largeScreen/_integralRanking.vue';
import PartyRewardStatistics from 'pages/largeScreen/_partyRewardStatistics.vue';
import LargeScreenMap from 'pages/largeScreen/_largeScreenMap.vue';
import { Device } from '@/utils/Device';
import { Utils } from 'vmf';

const { transformCssValue } = Utils;

export default {
    name: 'LargeScreenPage',
    components: {
        LargeScreenMap,
        PartyRewardStatistics,
        IntegralRanking,
        PartyMemberBranchInfo,
        ModuleStatistics,
        AgeAnalysis,
        MaleToFemaleRatio,
        PartyMemberStudy,
        LargeScreenBox,
    },
    data() {
        return {};
    },
    computed: {
        isScreenPortrait() {
            const { width, height } = Device;
            if (!width || !height) return false;
            return width < height;
        },
        classes() {
            const { isScreenPortrait } = this;
            return ['large-screen-page', { column: isScreenPortrait }];
        },
        styles() {
            const res = {};
            const {
                // isScreenPortrait,
                $route: { meta = {}, query },
            } = this;
            const screenSize = {
                width: 1920,
                height: 1080,
                ...(meta.screenSize || {}),
            };
            /* if (isScreenPortrait) {
                [screenSize.height, screenSize.width] = [screenSize.width, screenSize.height];
            } */
            if (+query.w) screenSize.width = +query.w;
            if (+query.h) screenSize.height = +query.h;
            const scale = Math.min(Device.width / screenSize.width, Device.height / screenSize.height);
            res.width = transformCssValue(screenSize.width);
            res.height = transformCssValue(screenSize.height);
            // if (GlobalVar.isDev && Device.desktop) return res;
            res.transform = `scale(${scale})`;
            // console.log(scale, Device.width, Device.height, Device.desktop);
            return res;
        },
    },
    methods: {},
};
</script>

<style lang='scss'>
.large-screen-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #1f150f;
    color: #fff;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    > .inner {
        flex: 0 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 1920px;
        height: 1080px;
        background-image: linear-gradient(to bottom, #180303 30%, #633b2d 70%, #633b2d 80%, #592a24 105%);
        color: #fff;
        font-size: 16px;

        &:after {
            position: absolute;
            right: 0;
            top: 50px;
            content: '';
            background: transparent url('./images/bg.png') center no-repeat;
            width: 521px;
            height: 412px;
        }

        > .header {
        }

        > .body {
            flex: 1 1 1%;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;

            > .block {
                flex: 1 1 1%;
                padding: $padding;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                //background-color: rgba(#f00, 0.2);

                &.left {
                }
                &.center {
                    flex: 2;
                }
                &.right {
                }
            }
        }
    }
}
</style>
