/**
 * Created by henian.xu on 2018/7/24.
 * 常用校验类
 */

import { isArray, isObject, isBoolean } from './Common';

const emailReg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
// const mobileReg = /(^((166|198|199)|(15[^4])|(14[5-9])|17[0-3]|(17[5-8])|((13|18)[0-9]))\d{8}$)|(^(1749)\d{7}$)/;
const mobileReg = /^1\d{10}$/;

/**
 * 确认密码验证
 * @param rule
 * @param value
 * @param callback
 */
export function againPassword(this: any, rule: any, value: any, callback: any /* , source, options */) {
    if (value !== this.formModel.password) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 邮箱验证
 * @param rule
 * @param value
 * @param callback
 * @param source
 * @param options
 */
export function email(rule: any, value: any, callback: any /* , source, options */) {
    if (!emailReg.test(value)) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 手机验证
 * @param rule
 * @param value
 * @param callback
 * @param source
 * @param options
 */
export function mobile(rule: any, value: any, callback: any /* , source, options */) {
    if (!mobileReg.test(value)) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 账号验证(手机号与邮箱)
 * @param rule
 * @param value
 * @param callback
 */
export function account(rule: any, value: any, callback: any) {
    if (!emailReg.test(value) && !mobileReg.test(value)) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

export function strictRequired(rule: any, value: any, callback: any) {
    const { key = 'id', message } = rule;
    let val = value;
    if (isArray(value)) {
        val = value.length;
    } else if (isObject(value)) {
        val = value[key];
    } else if (isBoolean(value)) {
        val = true;
    }
    if (val) {
        callback();
    } else {
        callback(new Error(message));
    }
}

export default {
    againPassword,
    email,
    mobile,
    account,
    strictRequired,
};
