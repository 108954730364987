import { registerModule } from 'vmf';

registerModule('a', {
    state: {
        b: 1,
    },
    getters: {},
    mutations: {},
    actions: {},
});
