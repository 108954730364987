<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='age-analysis'>
        <v-chart
            v-if='isMounted'
            class="echarts"
            :option="echartsOptions"
            :theme='echartsTheme'
            autoresize
        />
        <!--        <ECharts
            class="echarts"
            ref='echarts'
            :theme='echartsTheme'
            auto-resize
            :options="echartsOptions"></ECharts>-->
    </div>
</template>

<script>
import echartsTheme from './echartsTheme/customize';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart } from 'echarts/charts';
import { LegendComponent, TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import { rsLargeScreenMixin } from 'pages/largeScreen/store';
use([CanvasRenderer, PieChart, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);

export default {
    name: 'AgeAnalysis',
    mixins: [rsLargeScreenMixin],
    components: { VChart },
    data() {
        return {
            echartsTheme,
            isMounted: false,
        };
    },
    computed: {
        echartsOptions() {
            const { ageAnalysis } = this;
            return {
                title: {
                    text: '单位: %',
                },
                /*tooltip: {
                    // trigger: 'axis',
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                    },
                },*/
                legend: {
                    left: 'right',
                    data: ['男性党员', '女性党员'],
                },
                grid: {
                    top: '10%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value',
                },
                yAxis: {
                    type: 'category',
                    // data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    data: ageAnalysis.map(item => item.name),
                },
                series: [
                    {
                        name: '男性党员',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'insideRight',
                            formatter(params) {
                                return params.data || '';
                            },
                        },
                        // data: [320, 302, 301, 334, 390, 330, 320],
                        data: ageAnalysis.map(item => item.malePercent),
                    },
                    {
                        name: '女性党员',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            formatter: function(val) {
                                return val.value || '';
                            },
                            position: 'insideRight',
                        },
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        data: ageAnalysis.map(item => item.femalePercent),
                    },
                ],
            };
        },
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, 200);
    },
};
</script>

<style lang='scss'>
.age-analysis {
    height: 100%;
    padding: $padding;
    > .echarts {
        width: 100%;
        height: 100%;
    }
}
</style>
