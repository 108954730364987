<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="scaleName"
                    label="量表名称">
                    <el-input
                        v-model="queryFormModel.scaleName"
                        placeholder="请输入量表名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="scaleSn"
                    label="量表编码">
                    <el-input
                        v-model="queryFormModel.scaleSn"
                        placeholder="请输入量表编码"
                    />
                </el-form-item>
                <!--                <el-form-item
                    prop="scaleType"
                    label="量表类型">
                    <dictionaries-picker
                        type="scaleType"
                        v-model="queryFormModel.scaleType"
                    />
                </el-form-item>-->
                <el-form-item
                    prop="isAvailable"
                    label="是否可用">
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择">
                        <el-option
                            label="全部"
                            value="" />
                        <el-option
                            label="是"
                            value="1" />
                        <el-option
                            label="否"
                            value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"> 查询 </el-button>
                    <el-button
                        type="success"
                        @click="onReset"> 重置 </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying">
            <!-- 操作 -->
            <div class="ma-b">
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="
                            $router.push(
                                '/am/scale/healthyScale/edit?scaleType=' +
                                    queryFormModel.scaleType,
                            )
                        "
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="scaleName"
                    label="量表名称"
                    min-width="100" />
                <el-table-column
                    prop="scaleSn"
                    label="量表编码"
                    min-width="100" />
                <!--                <el-table-column
                    prop="scaleTypeName"
                    label="量表类型"
                    min-width="100"
                />-->
                <el-table-column
                    prop="testMinutes"
                    label="测试时长（分钟）"
                    width="120"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序号"
                    min-width="100" />
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100">
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="180">
                    <template slot-scope="scope">
                        <template>
                            <el-button
                                type="text"
                                size="small"
                                @click="
                                    $router.push(
                                        '/am/scale/healthyScale/edit/' +
                                            scope.row.id +
                                            '?scaleType=' +
                                            queryFormModel.scaleType +
                                            '&type=1',
                                    )
                                "
                            >
                                编辑量表
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="
                                    $router.push(
                                        '/am/scale/healthyScale/edit/' +
                                            scope.row.id +
                                            '?scaleType=' +
                                            queryFormModel.scaleType +
                                            '&type=2',
                                    )
                                "
                            >
                                编辑题目
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="
                                    $router.push(
                                        '/am/scale/healthyScale/factor/' + scope.row.id,
                                    )
                                "
                            >
                                编辑因子
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="onTableDelete(scope.row.id)"
                            >
                                删除
                            </el-button>
                        </template>
                        <el-button
                            type="text"
                            size="small"
                            @click="
                                $router.push('/am/scale/healthyScale/view/' + scope.row.id)
                            "
                        >
                            查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery" />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Scale',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                scaleName: '', // 量表名称
                scaleSn: '', // 量表编码
                scaleType: '1',
                isAvailable: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Am.Scale.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.Scale.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
