import { Pe } from './Pe';
class Station extends Pe {
    constructor() {
        super();
        this.baseUrl += '/station';
    }

    data({
        stationName,
        stationSn,
        stationRegionId,
        contactFullname,
        contactTel,
        isAvailable,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            stationName,
            stationSn,
            stationRegionId,
            contactFullname,
            contactTel,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const res = json.data.data;
                const { fileBasePath, stationLogo, addressLongitude, addressLatitude } = res;
                if (fileBasePath) {
                    if (stationLogo) {
                        res.stationLogo = stationLogo.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    } else {
                        res.stationLogo = [];
                    }
                }

                if (addressLongitude && addressLatitude) {
                    res.latLng = addressLongitude + ',' + addressLatitude;
                }

                return json;
            });
    }

    save({
        id,
        stationName,
        stationSn,
        stationLogo,
        stationOpenTime,
        stationDesc,
        stationRegionId,
        stationDetailAddress,
        latLng,
        contactFullname,
        contactTel,
        isAvailable,
        remarks,
    } = {}) {
        let stationLogoUrl;
        if (stationLogo && stationLogo.length) {
            stationLogoUrl = stationLogo
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let addressLongitude, addressLatitude;
        if (latLng) {
            const latLngs = latLng.split(',').reduce((prev, curr) => {
                prev.push(curr);
                return prev;
            }, []);
            if (latLngs && latLngs.length === 2) {
                [addressLongitude, addressLatitude] = latLngs;
            }
        }
        return super.post('/save', {
            id,
            stationName,
            stationSn,
            stationLogo: stationLogoUrl,
            stationOpenTime,
            stationDesc,
            stationRegionId,
            stationDetailAddress,
            addressLongitude,
            addressLatitude,
            contactFullname,
            contactTel,
            isAvailable,
            remarks,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    checkStationSn({ id, stationSn } = {}) {
        return super.get('/checkStationSn', {
            id,
            stationSn,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new Station();
export { Station };
