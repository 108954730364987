/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';

class ExpressBillRange extends Sv {
    constructor() {
        super();
        this.baseUrl += '/expressBillRange';
    }

    data({ expressId }) {
        return super.get('/data', { expressId });
    }

    save({ expressId, beginChar, beginSn, endSn } = {}) {
        return super.post('/save', { expressId, beginChar, beginSn, endSn }, { timeout: 0 });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', { id, isAvailable });
    }
}

export default new ExpressBillRange();
export { ExpressBillRange };
