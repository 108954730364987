/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ce } from './Ce';

class CourseMember extends Ce {
    constructor() {
        super();
        this.baseUrl += '/courseMember';
    }

    data({ courseCatId, courseName, fullName, mobile, currentPage, pageSize } = {}) {
        return super.get('/data', {
            courseCatId,
            courseName,
            fullName,
            mobile,
            currentPage,
            pageSize,
        });
    }
}

export default new CourseMember();
export { CourseMember };
