/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ce } from './Ce';

class CourseCat extends Ce {
    constructor() {
        super();
        this.baseUrl += '/courseCat';
    }

    data({ pId, name, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get(
            '/get',
            { id },
            {
                isRepeat: true,
            },
        );
    }

    save({ id, parentId, name, orderNum, isAvailable } = {}) {
        return super.post('/save', {
            id,
            parentId,
            name,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    selectAll() {
        return super.get(
            '/selectAll',
            {},
            {
                isRepeat: true,
            },
        );
    }

    /**
     * select
     * @param pId
     * @param name
     * @param _cancelSource 用于取消请求
     * @returns {AxiosPromise<any>}
     */
    select({ pId, name } = {}, config = {}) {
        return super.get(
            '/select',
            {
                pId,
                name,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id } = {}, config = {}) {
        return super.get(
            '/loadSelectNode',
            {
                id,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}

export default new CourseCat();
export { CourseCat };
