/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Am } from './Am';

class ScaleAnalysis extends Am {
    constructor() {
        super();
        this.baseUrl += '/scaleAnalysis';
    }

    list({ scaleId } = {}) {
        return super.get('/list', {
            scaleId,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, scaleId, conditionExpression, analysisComment, isPrewarning, remarks, orderNum, questionIds } = {}) {
        return super.post('/save', {
            id,
            scaleId,
            conditionExpression,
            analysisComment,
            isPrewarning,
            remarks,
            orderNum,
            questionIds,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ScaleAnalysis();
export { ScaleAnalysis };
