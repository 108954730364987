import { Pe } from './Pe';

class CaseCat extends Pe {
    constructor() {
        super();
        this.baseUrl += '/caseCat';
    }

    data({ pId, name, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, parentId, name, isAvailable, orderNum } = {}) {
        return super.post('/save', {
            id,
            parentId,
            name,
            isAvailable,
            orderNum,
        });
    }

    delete({ id } = {}) {
        return super.post('/delete', {
            id,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    selectAll() {
        return super.get(
            '/selectAll',
            {},
            {
                isRepeat: true,
            },
        );
    }

    select({ pId, name } = {}, config) {
        return super.get(
            '/select',
            {
                pId,
                name,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id, keywords } = {}, config) {
        return super.get(
            '/loadSelectNode',
            {
                id,
                keywords,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}
export default new CaseCat();
export { CaseCat };
