<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="objectId"
                    label="站点"
                >
                    <RemoteSelect
                        ref="selectObjectRef"
                        filterable
                        remote="/pe/station/data"
                        placeholder="请输入"
                        :props="{id:'id',name:'selectName'}"
                        :remote-params="{isAvailable: 1}"
                        v-model="queryFormModel.objectId"
                        @change="changeObject($event)"
                    />
                </el-form-item>
                <el-form-item>
                    <!--                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>-->
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <div
                class="ma-l"
                v-if="station.stationSn"
                style="font-size: large;font-weight: bold;text-align: right"
            >
                <span>{{station.stationName}}({{station.stationSn}}) </span>
                <span
                    class="ma-l"
                    v-if="schedulingPlan.schedulingPlanName"
                >
                    当前排班计划：{{schedulingPlan.schedulingPlanName}}
                </span>
            </div>
            <el-calendar
                class="schedule-calendar"
                v-model="queryFormModel.selectDate"
            >
                <template v-slot:dateCell="{date,data}">
                    <div
                        class="calendar-item"
                    >
                        <div class="label">{{date|moment('DD')}}</div>
                        <div
                            v-if="schedule[data.day]"
                            class="content">
                            <template v-for="item in schedule[data.day]">
                                <div
                                    :key="item.id"
                                >
                                    {{item.scheduleTypeName}}：{{item.beginTime | moment('HH:mm:ss') }}-{{item.endTime | moment('HH:mm:ss') }}
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </el-calendar>
        </div>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import Dayjs from 'dayjs';

export default {
    name: 'Schedule',
    mixins: [pagesMixin],
    data() {
        return {
            queryFormModel: {
                selectDate: new Date(),
                schedulingObjectType: 1,
                businessTableName: 't_pe_station',
                objectId: '',
            },
            schedule: {},
            station: {
                stationName: '',
                stationSn: '',
            },
            schedulingPlan: {},
        };
    },
    computed: {
        getSelectDate() {
            return this.queryFormModel.selectDate;
        },
    },
    watch: {
        getSelectDate: {
            handler(newVal, oldVal) {
                if (oldVal && Dayjs(newVal).format('YYYY-MM-DD') !== Dayjs(oldVal).format('YYYY-MM-DD')) {
                    this.onQuery();
                    this.getSchedulingPlan();
                }
            },
        },
    },
    methods: {
        onQuery() {
            this.$api.Pe.Schedule.getSchedule({
                ...this.queryFormModel,
            }).then(json => {
                const res = json.data;
                this.schedule = res.data || {};
            });
        },
        onReset() {
            this.$refs.queryForm.resetFields();
            this.station.stationName = '';
            this.station.stationSn = '';
            this.schedulingPlan = {};
            this.onQuery();
        },
        changeObject(val) {
            this.station.stationName = '';
            this.station.stationSn = '';
            const { selectObjectRef } = this.$refs;
            selectObjectRef.getItem(val).then(data => {
                this.station.stationName = data[0].stationName;
                this.station.stationSn = data[0].stationSn;
            });
            this.onQuery();
            this.getSchedulingPlan();
        },
        getSchedulingPlan() {
            this.schedulingPlan = {};
            const { objectId, selectDate } = this.queryFormModel;
            if (objectId && selectDate) {
                this.$api.Pe.SchedulingPlan.getSchedulingPlan({
                    objectId,
                    businessTableName: 't_pe_station',
                    selectDate: Dayjs(selectDate).format('YYYY-MM-DD'),
                }).then(json => {
                    const res = json.data;
                    this.schedulingPlan = res.data || {};
                });
            }
        },
    },
    created() {
        this.onQuery();
        this.getSchedulingPlan();
    },
};
</script>

<style lang="scss">
.schedule-calendar {
    .el-calendar-day {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;
        height: initial;
        max-height: 160px;
        min-height: 85px;
        .calendar-item {
            flex: 1 1 1%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            overflow: hidden;
            > .label {
                flex: 0 0 auto;
            }
            > .content {
                flex: 1 1 1%;
                font-size: smaller;
                overflow-y: auto;
                @include beautify-scrollbar();
            }
        }
    }
}
</style>
