<!-- Created by henian.xu on 2019/9/10. -->

<template>
    <div class="nav-menu">
        <el-menu
            :default-active="headerIndex"
            mode="horizontal"
        >
            <!--router-->
            <el-menu-item
                v-for="item in listMenu"
                :key="item.id"
                :index="`${item.id}`"
            >
                <!--:route="{path:item.url}"-->
                <div
                    v-if='!item.url'
                    class='link'
                    @click.stop.prevent='onItem(item)'>
                    <XIcon
                        v-if="item.icon"
                        :content="item.icon"
                    />{{ item.name }}
                </div>
                <a
                    v-else-if="$utils.isUrl(item.url)"
                    class="link"
                    :href="item.url+item.parameter"
                    :target='item.target||""'
                >
                    <XIcon
                        v-if="item.icon"
                        :content="item.icon"
                    />{{ item.name }}
                </a>
                <router-link
                    v-else
                    class="link"
                    :to="item.url+item.parameter"
                    :target='item.target||""'
                >
                    <XIcon
                        v-if="item.icon"
                        :content="item.icon"
                    />{{ item.name }}
                </router-link>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
import { menuState } from './MenuState';

export default {
    name: 'NavMenu',
    inject: {
        Menu: { default: {} },
    },
    computed: {
        headerIndex: {
            get() {
                return menuState.headerIndex;
            },
            set(val) {
                menuState.headerIndex = val;
            },
        },
        listMenu() {
            const { rawData: { listMenu = [] } = { listMenu: [] } } = menuState;
            return listMenu.filter(item => !!item);
        },
    },
    methods: {
        onItem(item) {
            const res = { ...item };
            if (this.Menu && this.Menu.clickMenu) this.Menu.clickMenu(res);
            this.$root.$emit('menu-click', res);
        },
    },
};
</script>

<style lang="scss">
.nav-menu {
    flex: 1 1 1%;
    overflow-x: auto;
    overflow-y: hidden;
    @include beautify-scrollbar;

    > .el-menu {
        flex: 1 1 1%;
        border-bottom: none;
        background-color: transparent;

        &.el-menu--horizontal {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            > .el-menu-item {
                float: none;
                height: auto;
                line-height: 0;
                padding: 0;
                border-bottom: 0;

                > .link {
                    display: inline-block;
                    height: 60px;
                    line-height: 60px;
                    padding: 0 20px;
                }
            }
        }

        > .el-submenu,
        > .el-menu-item {
            background-color: transparent;

            .x-icon {
                margin-right: $margin-small;
            }

            &,
            > .el-submenu__title {
                &,
                i {
                    color: #fff;
                }
            }

            &:not(.is-disabled) {
                &:focus {
                    &,
                    > .el-submenu__title {
                        background-color: transparent;
                        color: #fff;
                    }
                }
                &:hover {
                    &,
                    > .el-submenu__title {
                        background-color: mix(#000, #125454, 5%);
                        color: #fff;
                    }
                }
            }

            &.is-active {
                &,
                > .el-submenu__title {
                    background-color: mix(#000, #125454, 10%);
                    color: #fff;
                    border-bottom: 0;

                    i {
                        //color: #125454;
                    }
                }
            }
        }
    }
}
</style>
