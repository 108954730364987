<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.cmsStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"/>
                <el-tab-pane
                    label="待审核"
                    name="2"/>
                <el-tab-pane
                    label="审核通过"
                    name="4"/>
                <el-tab-pane
                    label="审核不通过"
                    name="5"/>
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="title"
                    label="标题"
                >
                    <el-input
                        v-model="queryFormModel.title"
                        placeholder="请输入标题"
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsCatalogId"
                    label="分类"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.cmsCatalogId"
                        :min-lv="1"
                        :p-id="0"
                        filterable
                        change-on-select
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsArticleType"
                    label="文章类型"
                    label-width="7em"
                >
                    <dictionaries-picker
                        type="cmsArticleType"
                        v-model="queryFormModel.cmsArticleType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-dropdown
                        @command="handleCommand"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                        >
                            新增
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1">
                                新增文章
                            </el-dropdown-item>
                            <el-dropdown-item command="2">
                                新增图片文章
                            </el-dropdown-item>
                            <el-dropdown-item command="3">
                                新增视频文章
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="id"
                    label="文章ID"
                    width="80"
                />
                <el-table-column
                    prop="title"
                    label="标题"
                    min-width="100"
                />
                <el-table-column
                    prop="cmsCatalogName"
                    label="分类"
                    min-width="100"
                />
                <el-table-column
                    prop="cmsArticleTypeName"
                    label="文章类型"
                    min-width="100"
                />
                <el-table-column
                    label="发布时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="isTop"
                    label="是否置顶"
                    width="80"
                >
                    <template #default="scope">
                        <el-switch
                            v-model="scope.row.isTop"
                            @input="onIsTopInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cmsStatusName"
                    label="文章状态"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pt/cms/cmsArticle/edit/' + scope.row.cmsArticleType + '/' + scope.row.id)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="copyToClipboard(scope.row.id)">
                            复制地址
                        </el-button>
                        <!--                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pt/cms/cmsArticle/comment/' + scope.row.id)"
                        >
                            查看评论
                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CmsArticle',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                title: '',
                cmsCatalogId: '',
                cmsArticleType: '',
                cmsStatus: '0',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            selectApi: this.$api.Pt.CmsCatalog,
            cascaderPicker: '',
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                queryFormModel: {
                    cmsArticleId: '',
                    fullName: '',
                    mobile: '',
                    comments: '',
                },
                pagination: [],
                tableData: [],
            },
        };
    },
    methods: {
        copyToClipboard(id) {
            const url = `pages/cms/detail?cmsId=${id}`;
            const input = document.createElement('input');
            input.value = url;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);

            this.$message.success('地址已复制到剪贴板');
        },
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pt.CmsArticle.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Pt.CmsArticle.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pt.CmsArticle.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pt.CmsArticle.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        handleCommand(command) {
            this.$router.push('/pt/cms/cmsArticle/edit/' + command);
        },
        onIsTopInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Pt.CmsArticle.changeIsTop({
                id: row.id,
                isTop: row.isTop,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isTop = !row.isTop;
                });
        },
    },
    mounted() {
        setTimeout(() => {
            //TODO 级联选择器 异步数据初始化问题
            this.cascaderPicker = '';
        }, 10);
    },
};
</script>

<style lang="scss">
</style>
