/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class Feedback extends Mb {
    constructor() {
        super();
        this.baseUrl += '/feedback';
    }

    data({
        departmentId,
        fullName,
        mobile,
        feedbackType,
        feedbackStatus,
        feedbackStatusNE,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            departmentId,
            fullName,
            mobile,
            feedbackType,
            feedbackStatus,
            feedbackStatusNE,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/getDetail', { id }).then(json => {
            const { fileBasePath } = json.data.data;
            const { feedbackImage } = json.data.data;
            const { handleAttachment } = json.data.data;
            if (!feedbackImage) {
                json.data.data.feedbackImage = [];
            } else {
                json.data.data.feedbackImage = feedbackImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (!handleAttachment) {
                json.data.data.handleAttachment = [];
            } else {
                json.data.data.handleAttachment = handleAttachment.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    dealFeedback({ id, handleRemarks, handleAttachment } = {}) {
        const handleAttachmentStr = handleAttachment
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/dealFeedback', {
            id,
            handleRemarks,
            handleAttachment: handleAttachmentStr,
        });
    }

    submitMessage({ id, content, feedbackImage } = {}) {
        const feedbackImageStr = feedbackImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/submitMessage', {
            id,
            content,
            feedbackImage: feedbackImageStr,
        });
    }
}

export default new Feedback();
export { Feedback };
