/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pt } from './Pt';
import { GlobalVar } from '@vmf/shared';

class CmsArticle extends Pt {
    constructor() {
        super();
        this.baseUrl += '/cmsArticle';
    }

    data({ title, cmsCatalogId, cmsArticleType, cmsStatus, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            title,
            cmsCatalogId,
            cmsArticleType,
            isAvailable,
            cmsStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const titleImage = json.data.data.result.titleImage;
            if (!titleImage) {
                json.data.data.result.titleImage = [];
            } else {
                json.data.data.result.titleImage = titleImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: json.data.data.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const contentImage = json.data.data.result.contentImage;
            if (!contentImage) {
                json.data.data.result.contentImage = [];
            } else {
                json.data.data.result.contentImage = contentImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: json.data.data.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const voiceFile = json.data.data.result.voiceFile;
            if (!voiceFile) {
                json.data.data.result.voiceFile = [];
            } else {
                json.data.data.result.voiceFile = voiceFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: json.data.data.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        cmsArticleType,
        showType,
        id,
        title,
        subtitle,
        cmsCatalogId,
        titleImageType,
        titleImage,
        contentImageType,
        contentImage,
        voiceFile,
        digest,
        author,
        source,
        isShowReleaseTime,
        releaseTime,
        content,
        imageList,
        isTop,
    } = {}) {
        const titleImageStr = titleImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const contentImageStr = contentImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const voiceFileStr = voiceFile
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            cmsArticleType,
            showType,
            id,
            title,
            subtitle,
            cmsCatalogId,
            titleImageType,
            titleImage: titleImageStr,
            contentImageType,
            contentImage: contentImageStr,
            voiceFile: voiceFileStr,
            digest,
            author,
            source,
            isShowReleaseTime,
            releaseTime,
            content,
            imageList,
            isTop,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    audit({ id, auditStatus, auditRemarks } = {}) {
        return super.post('/audit', {
            id,
            auditStatus,
            auditRemarks,
        });
    }
    changeIsTop({ id, isTop } = {}) {
        return super.post('/changeIsTop', {
            id,
            isTop,
        });
    }
}

export default new CmsArticle();
export { CmsArticle };
