/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { HttpBase } from '@/api/base/HttpBase';

class Am extends HttpBase {
  constructor() {
    super();
    this.baseUrl += '/am';
  }
}

export default new Am();
export { Am };
