<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="servantName"
                        label="服务商名称"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantSn"
                        label="服务商编码"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantSn"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantWebsite"
                        label="服务商网址"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantWebsite"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantLogo"
                        label="服务商LOGO"
                        label-width="10em"
                    >
                        <upload
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.servantLogo"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantRegionId"
                        label="服务商地址"
                        label-width="10em"
                    >
                        <CascaderPicker
                            :api-class="servantSelectApi"
                            v-model="formModel.servantRegionId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantAddress"
                        label="服务商详细地址"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantAddress"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantPostcode"
                        label="邮政编码"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantPostcode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantEmail"
                        label="电子邮箱"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantEmail"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalName"
                        label="联系人姓名"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.principalName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalTel"
                        label="联系人电话"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.principalTel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="openTime"
                        label="开通时间"
                        label-width="10em"
                    >
                        <el-date-picker
                            v-model="formModel.openTime"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择开通时间"
                            :disabled="+formModel.orgId !== 1"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="expireTime"
                        label="到期时间"
                        label-width="10em"
                    >
                        <el-date-picker
                            v-model="formModel.expireTime"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择到期时间"
                            :disabled="+formModel.orgId !== 1"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isOrderAutoAudit"
                        label="订单自动审核"
                        label-width="10em"
                    >
                        <el-switch
                            v-model="formModel.isOrderAutoAudit"
                            :active-value="true"
                            :inactive-value="false"
                            :disabled="true"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isOrderAutoStock"
                        label="订单自动出入库"
                        label-width="10em"
                    >
                        <el-switch
                            v-model="formModel.isOrderAutoStock"
                            :active-value="true"
                            :inactive-value="false"
                            :disabled="true"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isMergeStock"
                        label="是否合并库存"
                        label-width="10em"
                    >
                        <el-switch
                            v-model="formModel.isMergeStock"
                            :active-value="true"
                            :inactive-value="false"
                            :disabled="true"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stockCheckoutMode"
                        label="出库方式"
                        label-width="10em"
                    >
                        <dictionaries-picker
                            type="stockCheckoutMode"
                            v-model="formModel.stockCheckoutMode"
                            :disabled="true"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isFeedetailAutoAudit"
                        label="费用自动审核"
                        label-width="10em"
                    >
                        <el-switch
                            v-model="formModel.isFeedetailAutoAudit"
                            :active-value="true"
                            :inactive-value="false"
                            :disabled="true"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ServantInfo',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                servantName: '', // 服务商名称
                servantSn: '', // 服务商编码
                servantWebsite: '', // 服务商网址
                servantLogo: [], // 服务商LOGO
                servantRegionId: '', // 服务商地址
                servantAddress: '', // 服务商详细地址
                servantPostcode: '', // 邮政编码
                servantEmail: '', // 电子邮箱
                principalName: '', // 联系人姓名
                principalTel: '', // 联系人电话
                openTime: '', // 开通时间
                expireTime: '', //到期时间
                remarks: '', // 备注
                orgId: '', //组织机构
                isOrderAutoAudit: '', //订单自动审核
                isOrderAutoStock: '', //订单自动出入库
                stockCheckoutMode: '', // 出库方式
                isFeedetailAutoAudit: '', //费用自动审核
                isMergeStock: '', // 是否合并库存
            },
            // 表单校验规则
            formRules: {
                servantName: {
                    required: true,
                    message: '请输入服务商名称',
                    trigger: 'blur',
                },
                servantSn: {
                    required: true,
                    message: '请输入服务商编码',
                    trigger: 'blur',
                },
                servantLogo: {
                    type: 'array',
                    required: true,
                    message: '请上传服务商LOGO',
                },
                servantRegionId: {
                    required: true,
                    message: '请选择服务商地址',
                    trigger: 'blur',
                },
                servantAddress: {
                    required: true,
                    message: '请输入服务商详细地址',
                    trigger: 'blur',
                },
                principalName: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                principalTel: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                openTime: {
                    required: true,
                    message: '请选择开通时间',
                    trigger: 'blur',
                },
                expireTime: {
                    required: true,
                    message: '请选择到期时间',
                    trigger: 'blur',
                },
                stockCheckoutMode: {
                    required: true,
                    message: '请选择出库方式',
                },
            },
            servantSelectApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sv.Servant.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.$router.back();
                });
            });
        },
        initServant() {
            this.$api.Sv.Servant.getServantInfo().then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.initServant();
    },
};
</script>

<style lang="scss">
</style>
