/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pt } from './Pt';

class PortalInfoConfig extends Pt {
    constructor() {
        super();
        this.baseUrl += '/portalInfoConfig';
    }

    data({
             type,
             portalInfoName,
             portalInfoType,
             portalInfoKey,
             isAvailable,
             currentPage,
             pageSize,
         } = {}) {
        return super.get('/data', {
            type,
            portalInfoName,
            portalInfoType,
            portalInfoKey,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, portalInfoType, portalInfoName, imageSizeRequire, portalInfoKey, portalInfoValue, isAvailable } = {}) {
        return super.post('/save', {
            id,
            portalInfoType,
            portalInfoName,
            imageSizeRequire,
            portalInfoKey,
            portalInfoValue,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    changeValue({ id, portalInfoValue }) {
        return super.post('/changeValue', {
            id,
            portalInfoValue,
        });
    }
}

export default new PortalInfoConfig();
export { PortalInfoConfig };
