const baseFontSize = 15;
export default {
    color: [
        '#b5330c',
        '#e29735',
        '#5b8ff9',
        '#5ad8a6',
        '#f6bd16',
        '#6f5ef9',
        '#cbc680',
        '#6c8ab2',
        '#749f83',
        '#ca8622',
        '#bda29a',
        '#6e7074',
        '#546570',
        '#c4ccd3',
    ],
    /* color: [
        '#c23531',
        '#2f4554',
        '#61a0a8',
        '#d48265',
        '#91c7ae',
        '#749f83',
        '#ca8622',
        '#bda29a',
        '#6e7074',
        '#546570',
        '#c4ccd3',
    ], */
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
        textStyle: {
            color: '#ffffff',
            fontSize: 14,
        },
        subtextStyle: {
            color: '#ffffff',
        },
    },
    line: {
        itemStyle: {
            normal: {
                borderWidth: 1,
            },
        },
        lineStyle: {
            normal: {
                width: 2,
            },
        },
        symbolSize: 4,
        symbol: 'emptyCircle',
        smooth: false,
    },
    radar: {
        itemStyle: {
            normal: {
                borderWidth: 1,
            },
        },
        lineStyle: {
            normal: {
                width: 2,
            },
        },
        symbolSize: 4,
        symbol: 'emptyCircle',
        smooth: false,
    },
    bar: {
        itemStyle: {
            normal: {
                barBorderWidth: 0,
                barBorderColor: '#ccc',
            },
            emphasis: {
                barBorderWidth: 0,
                barBorderColor: '#ccc',
            },
        },
    },
    pie: {
        label: {
            fontSize: baseFontSize,
            // color: '#fff',
            color: 'inherit',
            // shadowColor: '#f00',
        },
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    scatter: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    boxplot: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    parallel: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    sankey: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    funnel: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    gauge: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            emphasis: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
    },
    candlestick: {
        itemStyle: {
            normal: {
                color: '#c23531',
                color0: '#314656',
                borderColor: '#c23531',
                borderColor0: '#314656',
                borderWidth: 1,
            },
        },
    },
    graph: {
        itemStyle: {
            normal: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
        },
        lineStyle: {
            normal: {
                width: 1,
                color: '#aaa',
            },
        },
        symbolSize: 4,
        symbol: 'emptyCircle',
        smooth: false,
        color: [
            '#c23531',
            '#2f4554',
            '#61a0a8',
            '#d48265',
            '#91c7ae',
            '#749f83',
            '#ca8622',
            '#bda29a',
            '#6e7074',
            '#546570',
            '#c4ccd3',
        ],
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                },
            },
        },
    },
    map: {
        itemStyle: {
            normal: {
                areaColor: '#eeeeee',
                borderColor: '#444444',
                borderWidth: 0.5,
            },
            emphasis: {
                areaColor: 'rgba(255,215,0,0.8)',
                borderColor: '#444444',
                borderWidth: 1,
            },
        },
        label: {
            normal: {
                textStyle: {
                    color: '#000000',
                },
            },
            emphasis: {
                textStyle: {
                    color: 'rgb(100,0,0)',
                },
            },
        },
    },
    geo: {
        itemStyle: {
            normal: {
                areaColor: '#eeeeee',
                borderColor: '#444444',
                borderWidth: 0.5,
            },
            emphasis: {
                areaColor: 'rgba(255,215,0,0.8)',
                borderColor: '#444444',
                borderWidth: 1,
            },
        },
        label: {
            normal: {
                textStyle: {
                    color: '#000000',
                },
            },
            emphasis: {
                textStyle: {
                    color: 'rgb(100,0,0)',
                },
            },
        },
    },
    effectScatter: {
        label: {
            color: '#fff',
        },
    },
    categoryAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#ffffff',
            },
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: '#ffffff',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                fontSize: baseFontSize,
                color: '#ffffff',
            },
        },
        splitLine: {
            show: false,
            lineStyle: {
                color: ['#ccc'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
            },
        },
    },
    valueAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#ffffff',
            },
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: '#ffffff',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                fontSize: baseFontSize,
                color: '#ffffff',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#cccccc'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
            },
        },
    },
    logAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#333',
            },
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: '#333',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                fontSize: baseFontSize,
                color: '#333',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#ccc'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
            },
        },
    },
    timeAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#333',
            },
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: '#333',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#333',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#ccc'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
            },
        },
    },
    toolbox: {
        iconStyle: {
            normal: {
                borderColor: '#999999',
            },
            emphasis: {
                borderColor: '#666666',
            },
        },
    },
    legend: {
        textStyle: {
            fontSize: baseFontSize,
            color: '#ffffff',
        },
    },
    tooltip: {
        axisPointer: {
            lineStyle: {
                color: '#cccccc',
                width: 1,
            },
            crossStyle: {
                color: '#cccccc',
                width: 1,
            },
        },
    },
    timeline: {
        lineStyle: {
            color: '#293c55',
            width: 1,
        },
        itemStyle: {
            normal: {
                color: '#293c55',
                borderWidth: 1,
            },
            emphasis: {
                color: '#a9334c',
            },
        },
        controlStyle: {
            normal: {
                color: '#293c55',
                borderColor: '#293c55',
                borderWidth: 0.5,
            },
            emphasis: {
                color: '#293c55',
                borderColor: '#293c55',
                borderWidth: 0.5,
            },
        },
        checkpointStyle: {
            color: '#e43c59',
            borderColor: 'rgba(194,53,49,0.5)',
        },
        label: {
            normal: {
                textStyle: {
                    color: '#293c55',
                },
            },
            emphasis: {
                textStyle: {
                    color: '#293c55',
                },
            },
        },
    },
    visualMap: {
        color: ['#bf444c', '#d88273', '#f6efa6'],
    },
    dataZoom: {
        backgroundColor: 'rgba(47,69,84,0)',
        dataBackgroundColor: 'rgba(47,69,84,0.3)',
        fillerColor: 'rgba(167,183,204,0.4)',
        handleColor: '#a7b7cc',
        handleSize: '100%',
        textStyle: {
            color: '#333333',
        },
    },
    markPoint: {
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                },
            },
            emphasis: {
                textStyle: {
                    color: '#ffffff',
                },
            },
        },
    },
};
