import { Mb } from './Mb';

class ActivityCat extends Mb {
    constructor() {
        super();
        this.baseUrl += '/activityCat';
    }

    data({ pId, name, activityType, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            activityType,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, parentId, name, activityType, isAvailable, orderNum } = {}) {
        return super.post('/save', {
            id,
            parentId,
            name,
            activityType,
            isAvailable,
            orderNum,
        });
    }

    delete({ id } = {}) {
        return super.post('/delete', {
            id,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    selectAll({ activityType } = {}) {
        return super.get(
            '/selectAll',
            {
                activityType,
            },
            {
                isRepeat: true,
            },
        );
    }

    select({ pId, name, activityType } = {}, config) {
        return super.get(
            '/select',
            {
                pId,
                name,
                activityType,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id, activityType, keywords } = {}, config) {
        return super.get(
            '/loadSelectNode',
            {
                id,
                activityType,
                keywords,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}
export default new ActivityCat();
export { ActivityCat };
