/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import MenuVisitLog from './MenuVisitLog';

export default {
    MenuVisitLog,
};
