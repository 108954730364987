import { Mb } from './Mb';
class PartyNotice extends Mb {
    constructor() {
        super();
        this.baseUrl += '/partyNotice';
    }

    data({ orgId, title, releaseTime, isAvailable, currentPage, pageSize } = {}) {
        let releaseTimeBegin, releaseTimeEnd;
        if (releaseTime && releaseTime.length === 2) {
            [releaseTimeBegin, releaseTimeEnd] = releaseTime;
        }
        return super.get('/data', {
            orgId,
            title,
            releaseTimeBegin,
            releaseTimeEnd,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    save({ id, title, content, releaseTime, orgIdList, orderNum, isAvailable } = {}) {
        let orgIds = '';
        if (orgIdList && orgIdList.length) {
            orgIds = orgIdList.join(',');
        }
        return super.post('/save', {
            id,
            title,
            content,
            releaseTime,
            orgIds,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new PartyNotice();
export { PartyNotice };
