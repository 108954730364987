/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import Rs from './rs';
import Pt from './pt';
import Ms from './ms';
import Sv from './sv';
import Mb from './mb';
import Ce from './ce';
import Am from './am';
import Pe from './pe';
import Lg from './lg';

export const Api = {
    Rs,
    Pt,
    Ms,
    Sv,
    Mb,
    Ce,
    Pe,
    Am,
    Lg,
};
export default Api;
