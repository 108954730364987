<!-- Created by henian.xu on 2019/8/14. -->

<template>
    <el-container
        class="layout-menu"
        direction="vertical"
    >
        <Nav />
        <el-container>
            <Aside />
            <el-container>
                <router-view
                    v-show="pageShow"
                    :key="routerViewKey"
                />
            </el-container>
        </el-container>
    </el-container>
    <!--<el-container class="layout-menu">
        <el-header>Header</el-header>
        <el-container>
            <el-aside width="200px">
                Aside
            </el-aside>
            <el-container>
                <el-main><router-view /></el-main>
                <el-footer>Footer</el-footer>
            </el-container>
        </el-container>
    </el-container>-->
</template>

<script>
import Aside from './_aside.vue';
import Nav from './_nav.vue';
import { menuState, setRawData, recursiveMenu, setRemainderMenu, getMenuById } from './MenuState';

export default {
    name: 'LayoutMenu',
    components: { Aside, Nav },
    provide() {
        return {
            Menu: this,
        };
    },
    data() {
        return { pageShow: false };
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
        verifyMenu: {
            type: Boolean,
            default: false,
        },
        remainderMenu: {
            type: Object,
            default: null,
        },
        clickMenu: {
            type: Function,
            default: null,
        },
        menuChange: {
            type: Function,
            default: null,
        },
        asideMenuChange: {
            type: Function,
            default: null,
        },
        navBrandStyle: {
            type: Object,
            default: null,
        },
    },
    beforeRouteEnter(to, form, next) {
        const {
            path,
            meta: { parentUrl },
        } = to;
        next(vm => {
            if (vm.verifyMenu) {
                if (path === '/') {
                    vm.pageShow = true;
                    return;
                }
                const menu = vm.menuUrlMap[path] || vm.menuUrlMap[parentUrl];
                vm.pageShow = !!menu;
                if (!menu && path !== '/') {
                    vm.noAuth();
                }
            } else {
                vm.pageShow = true;
            }
        });
    },
    beforeRouteUpdate(to, form, next) {
        const {
            path,
            meta: { parentUrl },
        } = to;
        if (this.verifyMenu) {
            if (path === '/') {
                this.pageShow = true;
                return;
            }
            const menu = this.menuUrlMap[path] || this.menuUrlMap[parentUrl];
            this.pageShow = !!menu;
            if (!menu && path !== '/') {
                setTimeout(() => {
                    this.noAuth();
                }, 200);
            }
        } else {
            this.pageShow = true;
        }
        next();
    },
    computed: {
        defaultUrl() {
            const { rawData: { defaultUrl } = { defaultUrl: '' } } = menuState;
            return defaultUrl;
        },
        listMenu() {
            const { rawData: { listMenu } = { listMenu: [] } } = menuState;
            return listMenu;
        },
        menuUrlMap() {
            return recursiveMenu(this.listMenu, 'url');
        },
        headerIndex: {
            get() {
                return menuState.headerIndex;
            },
            set(val) {
                menuState.headerIndex = val;
            },
        },
        asideIndex: {
            get() {
                return menuState.asideIndex;
            },
            set(val) {
                menuState.asideIndex = val;
            },
        },
        routerViewKey() {
            const { $route } = this;
            const {
                fullPath,
                name,
                meta: { keepOnly },
            } = $route;
            if (!keepOnly) return undefined;
            let keyValue = name || fullPath;
            if (typeof keepOnly === 'string') {
                keyValue = $route[keepOnly];
                if (typeof keyValue !== 'string' || !keyValue) {
                    keyValue = name || fullPath;
                }
            }
            return keyValue;
        },
    },
    watch: {
        data: {
            handler(val) {
                if (!val) return;
                setRawData(val);
            },
            immediate: true,
        },
        remainderMenu: {
            handler(val) {
                setRemainderMenu(val);
            },
            immediate: true,
        },
        defaultUrl: {
            handler(val) {
                const { path } = this.$route;
                if (val === path || path !== '/') return;
                this.$router.replace(val);
            },
            immediate: true,
        },
        '$route.path': {
            handler(val) {
                if (val === '/') return;
                this.updateRoute(val);
            },
            immediate: true,
        },
        headerIndex: {
            handler(val) {
                if (!val) return;
                const menu = getMenuById(val);
                const res = { ...menu };
                if (this.menuChange) this.menuChange(res);
                this.$root.$emit('menu-change', res);
            },
            immediate: true,
        },
        asideIndex: {
            handler(val) {
                if (!val) return;
                const menu = getMenuById(val);
                const res = { ...menu };
                if (this.asideMenuChange) this.asideMenuChange(res);
                this.$root.$emit('aside-menu-change', res);
            },
            immediate: true,
        },
    },
    methods: {
        // 根据 url 回选菜单
        updateRoute() {
            const { path, meta } = this.$route;
            /* if (this.stateData == null) {
                // 等待菜单数据初始化完毕
                const timerId = setTimeout(() => {
                    this.updateRoute();
                    clearTimeout(timerId);
                }, 100);
                return;
            } */
            const { parentUrl } = meta;
            const menu = this.menuUrlMap[path] || this.menuUrlMap[parentUrl];
            if (!menu) {
                console.error(`页面不存在, url: ${path}`);
                return;
            }
            this.headerIndex = `${menu.rootId}`;
            this.asideIndex = `${menu.id}`;
        },
        // 无权限访问页面
        async noAuth() {
            await this.$alert(
                this.$$t('vmf_no_permission_page', '您无权限访问此页面'),
                this.$$t('vmf_friendly_reminder', '温馨提示'),
                {
                    type: 'error',
                    showClose: false,
                    // callback(action) {},
                },
            );
            this.$router.replace(this.defaultUrl);
        },
    },
    created() {
        menuState.isCollapse = !this.$utils.Device.desktop;
    },
};
</script>

<style lang="scss" scoped>
.el-container {
    height: 100%;
    min-height: 0;
}
.el-main {
    @include beautify-scrollbar;
}
</style>
<style lang="scss">
html.pc {
    .layout-menu {
        min-width: 1280px;
    }
}
.layout-menu {
    height: 100%;
}
</style>
