<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.activityStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待发布"
                    name="10"
                />
                <el-tab-pane
                    label="待审核"
                    name="30"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="40"
                />
                <el-tab-pane
                    label="已发布"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="activityCatId"
                    label="活动分类"
                    :key="queryFormModel.activityType"
                >
                    <CascaderPicker
                        clearable
                        filterable
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.activityCatId"
                        :query-params="{activityType: queryFormModel.activityType}"
                    />
                </el-form-item>
                <el-form-item
                    prop="orgName"
                    label="组织机构"
                >
                    <el-input
                        v-model="queryFormModel.orgName"
                        placeholder="请输入组织机构"
                    />
                </el-form-item>
                <el-form-item
                    prop="activityName"
                    label="活动名称"
                >
                    <el-input
                        v-model="queryFormModel.activityName"
                        placeholder="请输入活动名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="releaseTime"
                    label="发布时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.releaseTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item
                    prop="applyEndTime"
                    label="报名截止时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.applyEndTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionId"
                    label="活动地区"
                >
                    <CascaderPicker
                        clearable
                        filterable
                        check-strictly
                        :api-class="regionApi"
                        v-model="queryFormModel.regionId"
                    />
                </el-form-item>
                <el-form-item
                    prop="contactFullName"
                    label="联系人姓名"
                >
                    <el-input
                        v-model="queryFormModel.contactFullName"
                        placeholder="请输入联系人姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="contactTel"
                    label="联系人电话"
                >
                    <el-input
                        v-model="queryFormModel.contactTel"
                        placeholder="请输入联系人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="isNeedSignIn"
                    label="是否需要签到"
                >
                    <el-select
                        v-model="queryFormModel.isNeedSignIn"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/mb/activity/offline/activity/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
                <el-button-group class="ma-l">
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onCancel"
                        :disabled="!currentTableSelect.length"
                        v-if="+queryFormModel.activityStatus === 100"
                    >
                        取消
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="50"
                    v-if="+queryFormModel.activityStatus === 100"
                />
                <el-table-column
                    prop="id"
                    label="活动ID"
                    width="80"
                />
                <el-table-column
                    prop="activityCatName"
                    label="活动分类"
                    min-width="100"
                />
                <el-table-column
                    prop="orgName"
                    label="组织机构"
                    min-width="100"
                />
                <el-table-column
                    prop="activityTypeName"
                    label="活动类型"
                    min-width="100"
                />
                <el-table-column
                    prop="activityName"
                    label="活动名称"
                    min-width="100"
                />
                <el-table-column
                    prop="releaseTime"
                    label="发布时间"
                    width="140"
                >
                    <template #default="scope">
                        {{scope.row.releaseTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="applyEndTime"
                    label="报名截止时间"
                    width="140"
                >
                    <template #default="scope">
                        {{scope.row.applyEndTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="activityBegin"
                    label="活动时间"
                    width="200"
                >
                    <template #default="scope">
                        <div>
                            开始时间：{{scope.row.activityTimeBegin | moment('YYYY-MM-DD HH:mm:ss')}}
                        </div>
                        <div>
                            结束时间：{{scope.row.activityTimeEnd | moment('YYYY-MM-DD HH:mm:ss')}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="limitPeopleNum"
                    label="限制人数"
                    min-width="100"
                />
                <el-table-column
                    prop="limitPointNum"
                    label="限制积分"
                    min-width="100"
                />
                <el-table-column
                    prop="activityAmount"
                    label="活动金额"
                    min-width="100"
                />
                <el-table-column
                    prop="regionName"
                    label="活动地区"
                    min-width="100"
                />
                <el-table-column
                    prop="address"
                    label="活动详细地址"
                    min-width="100"
                />
                <el-table-column
                    prop="contactFullName"
                    label="联系人姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="contactTel"
                    label="联系人电话"
                    min-width="100"
                />
                <el-table-column
                    prop="isNeedSignIn"
                    label="是否需要签到"
                    min-width="100"
                >
                    <template #default="scope">
                        {{scope.row.isNeedSignIn ? '是':'否'}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="clickCount"
                    label="点击次数"
                    width="80"
                />
                <el-table-column
                    prop="agreeCount"
                    label="点赞次数"
                    width="80"
                />
                <el-table-column
                    prop="commentCount"
                    label="评论次数"
                    width="80"
                />
                <el-table-column
                    prop="applyNum"
                    label="报名人数"
                    width="80"
                />
                <el-table-column
                    prop="activityStatusName"
                    label="活动状态"
                    width="80"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template v-slot="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/activity/offline/activity/edit/' + scope.row.id)"
                            v-if="+scope.row.activityStatus !== 99 && +scope.row.activityStatus !== 30"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/activity/offline/activity/view/' + scope.row.id)"
                            v-if="+scope.row.activityStatus !== 10 || +scope.row.activityStatus === 30"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onShowShareInfo(scope.row)"
                            v-if="scope.row.isNeedSignIn"
                        >
                            二维码
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/activity/offline/activity/comment/' + scope.row.id)"
                            v-if="+scope.row.activityStatus === 100"
                        >
                            查看评论
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/activity/offline/activity/member/' + scope.row.id)"
                            v-if="+scope.row.activityStatus === 100"
                        >
                            报名列表
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableCancel(scope.row.id)"
                            v-if="+scope.row.activityStatus === 100"
                        >
                            取消
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="二维码"
            center
            width="600px"
            :visible.sync="shareInfoDialog.isVisible"
            @closed="onShareInfoDialogCancel"
        >
            <el-form
                ref="shareInfoForm"
                size="small"
                :model="shareInfoDialog.formModel"
                :rules="shareInfoDialog.formRules"
            >
                <el-form-item
                    label="小程序码"
                    label-width="9em"
                    v-if="shareInfoDialog.formModel.minAppCodeImage"
                >
                    <img
                        width="200"
                        :src="shareInfoDialog.formModel.minAppCodeImage"
                        alt=""
                    >
                    <div class="label">线下活动小程序码（右键另存为保存图片）</div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onShareInfoDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';
export default {
    name: 'Activity',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                activityCatId: '',
                selectOrgId: '',
                activityType: 2,
                activityName: '',
                releaseTime: '',
                applyEndTime: '',
                regionId: '',
                contactFullName: '',
                contactTel: '',
                isNeedSignIn: '',
                activityStatus: '0',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Mb.ActivityCat,
            regionApi: this.$api.Rs.Region,
            loading: '',
            shareInfoDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    minAppCodeImage: '',
                },
                // 表单校验规则
                formRules: {},
            },
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Mb.Activity.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onCancel() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableCancel(ids.join(','));
        },
        onTableCancel(ids) {
            this.$confirm('是否确定要取消！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.Activity.cancelActivity({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onShowShareInfo(row) {
            const { formModel } = this.shareInfoDialog;
            this.$api.Mb.Activity.getMinAppCodeImage({ id: row.id }).then(json => {
                const res = json.data;
                formModel.minAppCodeImage = res.data;
                this.shareInfoDialog.isVisible = true;
            });
        },
        onShareInfoDialogCancel() {
            const { shareInfoForm } = this.$refs;
            shareInfoForm.resetFields();
            this.shareInfoDialog.isVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
</style>
