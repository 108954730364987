<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.courseStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="未发布"
                    name="1"
                />
                <el-tab-pane
                    label="已发布"
                    name="2"
                />
                <el-tab-pane
                    label="待审核"
                    name="30"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="40"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="courseCatId"
                    label="课程分类"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.courseCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseName"
                    label="课程名称"
                >
                    <el-input
                        v-model="queryFormModel.courseName"
                        placeholder="请输入课程名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseLearnType"
                    label="课程学习方式"
                >
                    <dictionaries-picker
                        type="courseLearnType"
                        v-model="queryFormModel.courseLearnType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"

                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/ce/course/course/edit')"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                    <el-button
                        type="primary"
                        icon="el-icon-check"
                        size="small"
                        @click="onRelease"
                        :disabled="!currentTableSelect.length"
                    >
                        发布
                    </el-button>
                    <el-button
                        type="warning"
                        icon="el-icon-close"
                        size="small"
                        @click="onCancelRelease"
                        :disabled="!currentTableSelect.length"
                    >
                        取消发布
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="39"
                />
                <el-table-column
                    prop="id"
                    label="课程ID"
                    width="80"
                />
                <el-table-column
                    prop="courseCatNamePath"
                    label="课程分类"
                    min-width="100"
                />
                <el-table-column
                    prop="courseLearnTypeName"
                    label="课程学习方式"
                    min-width="100"
                />
                <el-table-column
                    prop="courseName"
                    label="课程名称"
                    min-width="100"
                />
                <el-table-column
                    label="课程图片"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.courseImageUrl"
                        />
                    </template>
                </el-table-column>
                <!--                <el-table-column
                    prop="courseHour"
                    label="等效学时"
                    min-width="100"
                />-->
                <el-table-column
                    prop="courseStatusName"
                    label="课程状态"
                    min-width="100"
                />
                <el-table-column
                    prop="operatorName"
                    label="创建人"
                    min-width="100"
                />
                <el-table-column
                    label="创建时间"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableRelease(scope.row.id)"
                            v-if="+scope.row.courseStatus === 1 || +scope.row.courseStatus === 40"
                        >
                            发布
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableCancelRelease(scope.row.id)"
                            v-if="+scope.row.courseStatus === 2"
                        >
                            取消发布
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/ce/course/course/edit/${scope.row.id}`)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="copyToClipboard(scope.row.id)">
                            复制地址
                        </el-button>
                        <!--                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/ce/course/course/comment/${scope.row.id}`)"
                        >
                            课程评论
                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Course',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                courseCatId: '',
                courseName: '',
                courseStatus: '',
                courseLearnType: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Ce.CourseCat,
        };
    },
    methods: {
        copyToClipboard(id) {
            const url = `pages/course/info?courseId=${id}`;
            const input = document.createElement('input');
            input.value = url;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);

            this.$message.success('地址已复制到剪贴板');
        },
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ce.Course.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Ce.Course.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ce.Course.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onTableRelease(ids) {
            this.$confirm('是否确定要发布！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ce.Course.release({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onRelease() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableRelease(ids.join(','));
        },
        onTableCancelRelease(ids) {
            this.$confirm('是否确定要取消发布！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ce.Course.cancelRelease({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onCancelRelease() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableCancelRelease(ids.join(','));
        },
    },
};
</script>

<style lang="scss">
</style>
