/**
 * Created by henian.xu on 2019/12/6.
 *
 */
import { Utils, Vue } from 'vmf';
const {
    /*GlobalVar: {
        appConfig: { WEBSOCKET_CONFIG: webSocketConfig },
    },*/
    isObject,
} = Utils;

const state = Vue.observable({
    msgList: [],
    unprocessedMsg: [],
    readyState: 3,
});
//创建WebSocket实例，可以使用ws和wss。第二个参数可以选填自定义协议，如果多协议，可以以数组方式
// export const socket = new WebSocket(webSocketConfig.location);
let socket = null;
let openEvent = null;
export function getSocket() {
    return socket;
}

function connect(url, protocols) {
    if (!url) return;
    openEvent = null;
    socket = new WebSocket(url, protocols);
    socket.onopen = event => {
        openEvent = event;
        const { target } = event;
        state.readyState = target.readyState;
        Object.values(vmMap).forEach(vm => {
            const { websocketOpen } = vm.$options;
            if (!websocketOpen) return;
            websocketOpen.forEach(fn => fn.apply(vm, [event]));
        });
    };
    socket.onerror = event => {
        Object.values(vmMap).forEach(vm => {
            const { websocketError } = vm.$options;
            if (!websocketError) return;
            websocketError.forEach(fn => fn.apply(vm, [event]));
        });
    };
    /*
    1. socket.readyState 0:正在链接中; 1:已经链接并且可以通讯; 2:正在关闭; 3:已经关闭;
    2. event.wasClean [Boolean] true  客户端或者服务器端调用close主动关闭 false 反之
    3. event.code [Number] 关闭连接的状态码。socket.close(code, reason)
    4. event.reason [String]
    关闭连接的原因。socket.close(code, reason)
    */
    socket.onclose = event => {
        console.log('onclose');
        const { target } = event;
        state.readyState = target.readyState;
        Object.values(vmMap).forEach(vm => {
            const { websocketClose } = vm.$options;
            if (!websocketClose) return;
            websocketClose.forEach(fn => fn.apply(vm, [event]));
        });
        // debugger;
    };
    socket.onmessage = event => {
        event.id = Utils.getUniqueId('SOCKET_MSG_');
        state.msgList.push(event);
        handleMessage();
    };
}

let processing = false;
async function handleMessage(vm, msgQueue = state.msgList, msgLen = -1) {
    if (processing || !msgQueue.length) return;
    const vmList = vm ? [vm] : Object.values(vmMap);
    const len = vmList.length;
    if (!len) return;
    processing = true;
    const event = msgQueue.shift();
    let haveRead = false;
    for (let i = 0; i < len; i++) {
        const vm = vmList[i];
        const { websocketMessage } = vm.$options;
        if (!websocketMessage) continue;
        haveRead = await websocketMessage.reduce(async (previousPromise, nextPromise) => {
            const pre = await previousPromise;
            const res = await nextPromise.apply(vm, [event]);
            return pre || !!res;
        }, Promise.resolve(false));
        // 如果已读则不再往下传播
        if (haveRead) break;
    }
    // 未标为已读的消息放入 unprocessedMsg 中等待新的页面处理方法创建时再处理
    if (!haveRead) {
        state.unprocessedMsg.push(event);
    }
    processing = false;
    if (!vm) {
        // 查看有没有新加入的组件
        await handleRegisterQueue();
    } else {
        // msgLen 只是为了解决 unprocessedMsg 循环调用 handleMessage 的问题
        msgLen -= 1;
    }
    if (msgQueue.length && msgLen > 0) return handleMessage(vm, msgQueue, msgLen);
}
// 消息标志为已读
function markAsRead(msg) {
    let id = msg;
    if (isObject(msg)) id = msg.id;
    if (!id) return;
    let index = -1;
    state.unprocessedMsg.some((msg, i) => {
        const b = msg.id === id;
        if (b) index = i;
        return b;
    });
    if (index > -1) state.unprocessedMsg.shift(index, 1);
}

const vmMap = {};
const registerQueue = [];
function registerSocketInstance(vm) {
    const {
        websocketOpen,
        websocketMessage,
        websocketClose,
        websocketError,
        // add
    } = vm.$options;
    if (!websocketOpen && !websocketClose && !websocketMessage && !websocketError) return;
    registerQueue.push(vm);
    handleRegisterQueue();
}
async function handleRegisterQueue() {
    if (processing || !registerQueue.length) return;
    const vm = registerQueue.shift();
    vmMap[vm._uid] = vm;
    const {
        websocketOpen,
        websocketMessage,
        // websocketClose,
        // websocketError,
        // add
    } = vm.$options;
    // socket.readyState 0:正在链接中; 2:正在关闭; 3:已经关闭;
    if (socket && socket.readyState === socket.OPEN) {
        if (websocketOpen && websocketOpen.length) websocketOpen.forEach(fn => fn.apply(vm, [openEvent]));

        // 如果有未读消息则用当前新加入的页面尝试处理
        const msgLen = state.unprocessedMsg.length;
        if (msgLen && websocketOpen && websocketMessage.length) {
            await handleMessage(vm, state.unprocessedMsg, msgLen);
        }
    }
    if (registerQueue.length) return handleRegisterQueue();
}

export default vue => {
    vue.mixin({
        created() {
            registerSocketInstance(this);
        },
        destroyed() {
            delete vmMap[this._uid];
        },
    });

    Object.defineProperty(vue.prototype, '$socket', {
        get() {
            return socket;
        },
    });
    Object.defineProperty(vue.prototype, '$sockets', {
        get() {
            return {
                connect,
                markAsRead,
                msgList: state.unprocessedMsg,
                readyState: state.readyState,
            };
        },
    });

    const strats = vue.config.optionMergeStrategies;
    strats.websocketOpen = strats.websocketClose = strats.websocketMessage = strats.websocketError = strats.created;
};

/*async function runPromiseByQueue(myPromises) {
    const r = myPromises.reduce(async (previousPromise, nextPromise) => {
        const pre = await previousPromise;
        const id = await nextPromise.apply();
        console.log(id);
        return pre + id;
    }, Promise.resolve(0));
    r.then(res => {
        console.log(1111, res);
    });
    /!*myPromises.reduce((previousPromise, nextPromise) => {
        return previousPromise.then(() => {
            return nextPromise();
        });
    }, Promise.resolve());*!/
    /!*for (let i = 0; i < myPromises.length; i++) {
        const res = await myPromises[i]();
        console.log(res);
        if (res === 2) break;
    }*!/
}
const createPromise = (time, id) => () =>
    new Promise(resolve =>
        setTimeout(() => {
            console.log('promise', id);
            resolve(id);
        }, time),
    );

runPromiseByQueue([
    createPromise(3000, 1),
    createPromise(2000, 2),
    createPromise(1000, 3),
    //add
]);*/
