/**
 * Created by henian.xu on 2019/11/1.
 * 一些组件的通用配置项
 */
import Vue from 'vue';

export const pickerOptions = {
    shortcuts: [
        {
            text: '今天',
            onClick(picker: Vue) {
                const now = new Date();
                picker.$emit('pick', [now, now]);
            },
        },
        {
            text: '昨天',
            onClick(picker: Vue) {
                const now = new Date();
                now.setTime(now.getTime() - 3600 * 1000 * 24);
                picker.$emit('pick', [now, now]);
            },
        },
        {
            text: '近一周',
            onClick(picker: Vue) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
            },
        },
        {
            text: '近一个月',
            onClick(picker: Vue) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
            },
        },
        {
            text: '近三个月',
            onClick(picker: Vue) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
            },
        },
    ],
};

export default {
    pickerOptions,
};
