/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';

class ServantExpress extends Sv {
    constructor() {
        super();
        this.baseUrl += '/servantExpress';
    }

    data() {
        return super.get('/data', {});
    }

    getByExpressId({ expressId }) {
        return super.get('/getByExpressId', { expressId });
    }

    saveServantExpress({ expressId, waybillTemplate }) {
        return super.post('/saveServantExpress', { expressId, waybillTemplate });
    }

    save({ jsonStr } = {}) {
        return super.post('/save', {
            jsonStr,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ServantExpress();
export { ServantExpress };
