/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ce } from './Ce';

class CourseComment extends Ce {
    constructor() {
        super();
        this.baseUrl += '/courseComment';
    }

    data({ courseId, courseCommentId, departmentId, fullName, mobile, comments, currentPage, pageSize } = {}) {
        return super.get('/data', {
            courseId,
            courseCommentId,
            departmentId,
            fullName,
            mobile,
            comments,
            currentPage,
            pageSize,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isDelete } = {}) {
        return super.post('/status', {
            id,
            isDelete,
        });
    }
}

export default new CourseComment();
export { CourseComment };
