<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基础信息</span>
                </div>
                <div class="item">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="schedulingRuleType"
                                label="规则类型："
                                label-width="9em"
                            >
                                {{detail.schedulingRuleTypeName}}
                            </el-form-item>
                            <el-form-item
                                prop="schedulingPlanName"
                                label="计划名称："
                                label-width="9em"
                            >
                                {{detail.schedulingPlanName}}
                            </el-form-item>
                            <el-form-item
                                prop="planDate"
                                label="计划日期："
                                label-width="9em"
                            >
                                <div>
                                    开始日期：{{detail.beginDate}}
                                </div>
                                <div>
                                    结束日期：{{detail.endDate}}
                                </div>

                            </el-form-item>
                            <el-form-item
                                prop="remarks"
                                label="备注："
                                label-width="9em"
                            >
                                {{detail.remarks}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>排班信息</span>
                </div>
                <div class="item">
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="detail.planDetailList"
                        style="width: 100%"
                        v-if="detail.schedulingRuleType"
                    >
                        <el-table-column
                            prop="week"
                            label="星期"
                            min-width="100"
                            v-if="+detail.schedulingRuleType === 1"
                        >
                            <template #default="scope">
                                <span
                                    v-for="item in scope.row.weekNameList"
                                    :key="item"
                                >
                                    {{item}}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailDate"
                            label="计划日期"
                            min-width="100"
                            v-if="+detail.schedulingRuleType === 2"
                        >
                            <template #default="scope">
                                <div>
                                    开始日期：{{scope.row.beginDate}}
                                </div>
                                <div>
                                    结束日期：{{scope.row.endDate}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailTime"
                            label="计划时间"
                            min-width="100"
                        >
                            <template #default="scope">
                                <div>
                                    开始时间：{{scope.row.beginTime }}
                                </div>
                                <div>
                                    结束时间：{{scope.row.endTime }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>站点信息</span>
                    <el-button
                        class="ma-l"
                        size="small"
                        type="success"
                        @click="openStationDialog"
                    >
                        选择
                    </el-button>
                </div>
                <div class="item">
                    <el-table
                        class="ma-t"
                        stripe
                        border
                        size="small"
                        ref="stationDialogTable"
                        :data="formModel.objectList"
                        row-key="id"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="stationName"
                            label="站点名称"
                            min-width="100"
                        />
                        <el-table-column
                            prop="stationSn"
                            label="站点编号"
                            min-width="100"
                        />
                        <el-table-column
                            prop="stationLogo"
                            label="站点LOGO"
                            width="90"
                        >
                            <template v-slot="scope">
                                <imageList
                                    :data="scope.row.stationLogoUrl"
                                    :item-width="50"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="stationOpenTime"
                            label="站点营业时间"
                            min-width="100"
                        />
                        <el-table-column
                            prop="stationRegionName"
                            label="站点地区"
                            min-width="100"
                        />
                        <el-table-column
                            prop="stationDetailAddress"
                            label="站点详细地址"
                            min-width="100"
                        />
                        <el-table-column
                            prop="contactFullname"
                            label="联系人姓名"
                            width="100"
                        />
                        <el-table-column
                            prop="contactTel"
                            label="联系人电话"
                            width="100"
                        />
                        <el-table-column
                            prop="remarks"
                            label="备注"
                            min-width="100"
                        />
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="60"
                        >
                            <template #default="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="deleteStationDialog(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </el-form>

        <div
            class="ma-t"
            style="text-align: center"
        >
            <el-button
                type="primary"
                size="small"
                @click="onSave"
            >
                保 存
            </el-button>
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>

        <el-dialog
            title="选择站点"
            center
            width="1200px"
            :visible.sync="stationDialog.isVisible"
            @closed="cancelStationDialog"
        >
            <el-form
                ref="queryStationForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="stationDialog.queryFormModel"
            >
                <el-form-item
                    prop="stationName"
                    label="站点名称"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.stationName"
                        placeholder="请输入站点名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="stationSn"
                    label="站点编号"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.stationSn"
                        placeholder="请输入站点编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="stationRegionId"
                    label="站点地区"
                >
                    <CascaderPicker
                        clearable
                        filterable
                        check-strictly
                        :api-class="regionApi"
                        v-model="stationDialog.queryFormModel.stationRegionId"
                    />
                </el-form-item>
                <el-form-item
                    prop="contactFullname"
                    label="联系人姓名"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.contactFullname"
                        placeholder="请输入联系人姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="contactTel"
                    label="联系人电话"
                >
                    <el-input
                        v-model="stationDialog.queryFormModel.contactTel"
                        placeholder="请输入联系人电话"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="queryStationDialog()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="resetStationDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-table
                stripe
                border
                size="small"
                ref="stationDialogTable"
                :data="stationDialog.tableData"
                row-key="id"
                style="width: 100%"
                @selection-change="changeSelectedStationDialog"
            >
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="50"
                />
                <el-table-column
                    prop="stationName"
                    label="站点名称"
                    min-width="100"
                />
                <el-table-column
                    prop="stationSn"
                    label="站点编号"
                    min-width="100"
                />
                <el-table-column
                    prop="stationLogo"
                    label="站点LOGO"
                    width="90"
                >
                    <template v-slot="scope">
                        <imageList
                            :data="scope.row.stationLogoUrl"
                            :item-width="50"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="stationOpenTime"
                    label="站点营业时间"
                    min-width="100"
                />
                <el-table-column
                    prop="stationRegionName"
                    label="站点地区"
                    min-width="100"
                />
                <el-table-column
                    prop="stationDetailAddress"
                    label="站点详细地址"
                    min-width="100"
                />
                <el-table-column
                    prop="contactFullname"
                    label="联系人姓名"
                    width="100"
                />
                <el-table-column
                    prop="contactTel"
                    label="联系人电话"
                    width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="stationDialog.pagination"
                    @input="queryStationDialog"
                />
            </div>

            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="confirmStationDialog"
                    :disabled="!stationDialog.currentTableSelect.length"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="cancelStationDialog"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'SchedulingPlanView',
    mixins: [pagesMixin],
    data() {
        return {
            detail: {},
            formModel: {
                id: '',
                objectList: [],
            },
            formRules: {
                objectList: {
                    required: true,
                    message: '请选择站点',
                },
            },
            stationDialog: {
                isVisible: false,
                queryFormModel: {
                    stationName: '',
                    stationSn: '',
                    stationRegionId: '',
                    contactFullname: '',
                    contactTel: '',
                    isAvailable: 1,
                },
                tableData: [],
                currentTableSelect: [],
                pagination: {},
            },
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        init() {
            this.$api.Pe.SchedulingPlan.getDetail({
                id: this.formModel.id,
            }).then(json => {
                const res = json.data;
                this.detail = res.data;
                this.formModel.objectList = (res.data || {}).objectList || [];
            });
        },
        onSave() {
            this.$refs.addForm.validate().then(() => {
                this.$api.Pe.SchedulingPlan.savePlanObject({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        // region stationDialog
        openStationDialog() {
            this.stationDialog.isVisible = true;
            this.$nextTick(() => {
                const { queryStationForm } = this.$refs;
                if (queryStationForm) {
                    queryStationForm.resetFields();
                }
                this.queryStationDialog();
                const { stationDialogTable } = this.$refs;
                // 初始化已选择数据
                if (stationDialogTable) {
                    stationDialogTable.clearSelection();
                }
                this.formModel.objectList.forEach(item => {
                    stationDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        cancelStationDialog() {
            this.stationDialog.isVisible = false;
        },
        changeSelectedStationDialog(val) {
            this.stationDialog.currentTableSelect = val;
        },
        queryStationDialog() {
            this.$api.Pe.Station.data({
                ...this.stationDialog.queryFormModel,
                ...this.stationDialog.pagination,
            }).then(json => {
                const res = json.data;
                this.stationDialog.tableData = res.data;
                this.stationDialog.pagination = res.pagination;
            });
        },
        resetStationDialog() {
            this.$refs.queryStationForm.resetFields();
        },
        confirmStationDialog() {
            const { currentTableSelect } = this.stationDialog;
            if (currentTableSelect && currentTableSelect.length) {
                const { objectList } = this.formModel;
                const temp = [];
                currentTableSelect.forEach(item => {
                    let isFind = false;
                    if (objectList && objectList.length) {
                        objectList.forEach(oldItem => {
                            if (+oldItem.id === +item.id) {
                                temp.push({ ...oldItem });
                                isFind = true;
                            }
                        });
                    }
                    if (!isFind) {
                        temp.push({ ...item });
                    }
                });

                this.formModel.objectList = [...temp];
                this.stationDialog.isVisible = false;
            }
        },
        deleteStationDialog(row) {
            this.formModel.objectList.splice(this.formModel.objectList.indexOf(row), 1);
        },
        // endregion
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
