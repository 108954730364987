/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Am } from './Am';

class ScaleFactor extends Am {
    constructor() {
        super();
        this.baseUrl += '/scaleFactor';
    }

    list({ scaleId } = {}) {
        return super.get('/list', {
            scaleId,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, scaleId, factorName, scoreExpression, remarks, orderNum } = {}) {
        return super.post('/save', {
            id,
            scaleId,
            factorName,
            scoreExpression,
            remarks,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ScaleFactor();
export { ScaleFactor };
