/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import User from './User';
import Menu from './Menu';
import Role from './Role';
import Region from './Region';
import Variable from './Variable';
import Dic from './Dic';
import DicEntry from './DicEntry';
import App from './App';
import Org from './Org';
import Express from './Express';
import ExchangeRate from './ExchangeRate';
import Console from './Console';
import LanguageDataTrans from './LanguageDataTrans';
import LanguageDataConf from './LanguageDataConf';
import LanguageModule from './LanguageModule';
import LanguageEntry from './LanguageEntry';

export default {
    User,
    Menu,
    Role,
    Region,
    Variable,
    Dic,
    DicEntry,
    App,
    Org,
    Express,
    ExchangeRate,
    Console,
    LanguageDataTrans,
    LanguageDataConf,
    LanguageModule,
    LanguageEntry,
};
