/**
 * Created by henian.xu on 2018/7/17.
 *
 */

import { get, post, createCancelToken, postJson } from 'vmf';
class HttpBase {
    constructor() {
        this.baseUrl = '';
    }

    /**
     * 创建取消令牌
     */
    createCancelToken() {
        return createCancelToken();
    }

    /**
     * get 请求
     * @param url
     * @param params
     * @param cfg
     * @returns {AxiosPromise<any>}
     */
    get(url, params = {}, cfg = {}) {
        return get(`${this.baseUrl}${url}`, params, cfg);
    }

    /**
     * post 请求
     * @param url
     * @param params
     * @param cfg
     * @returns {AxiosPromise<any>}
     */
    post(url, params = {}, cfg = {}) {
        return post(`${this.baseUrl}${url}`, params, cfg);
    }

    /**
     * post 请求
     * @param url
     * @param params
     * @returns {AxiosPromise<any>}
     */
    postJson(url, params = {}) {
        return postJson(`${this.baseUrl}${url}`, params, {
            headers: { 'Content-Type': 'application/json' },
            paramsSerializer: () => {},
            transformRequest: [data => JSON.stringify(data)],
        });
    }
}

export default HttpBase;
const HttpInstance = new HttpBase();
export { HttpBase, HttpInstance };

/*function createOneKey(fn, delay) {
    var time = 0;
    return function() {
        if (time) {
            clearInterval(time);
            time = 0;
        } else {
            time = setTimeout(function() {
                fn();
            }, delay);
        }
    };
}
var onKey = createOneKey(function() {
    // 这里会定时执行
}, 2000);

// 启动与关闭切换
onKey();*/
