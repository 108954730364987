<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="labelName"
                    label="标签"
                >
                    <el-input
                        v-model="queryFormModel.labelName"
                        placeholder="请输入标签"
                    />
                </el-form-item>
                <el-form-item
                    prop="idCardNo"
                    label="身份证号"
                >
                    <el-input
                        v-model="queryFormModel.idCardNo"
                        placeholder="请输入身份证号"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="联系电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入联系电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="sex"
                    label="性别"
                >
                    <dictionaries-picker
                        type="sex"
                        v-model="queryFormModel.sex"
                    />
                </el-form-item>
                <el-form-item
                    prop="doctorStatus"
                    label="医生状态"
                >
                    <dictionaries-picker
                        type="doctorStatus"
                        v-model="queryFormModel.doctorStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/pe/doctor/doctor/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <!--                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="50"
                />-->
                <el-table-column
                    prop="avatarUrl"
                    label="照片"
                    width="100"
                >
                    <template v-slot="scope">
                        <imageList
                            :data="scope.row.avatarUrl"
                            :item-width="50"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="labelName"
                    label="标签"
                    min-width="100"
                />
                <el-table-column
                    prop="idCardNo"
                    label="身份证号"
                    width="150"
                />
                <el-table-column
                    prop="mobile"
                    label="联系电话"
                    width="100"
                />
                <el-table-column
                    prop="sexName"
                    label="性别"
                    width="60"
                />
                <el-table-column
                    prop="birthday"
                    label="出生日期"
                    width="100"
                />
                <el-table-column
                    prop="graduatedSchool"
                    label="毕业学校"
                    min-width="100"
                />
                <el-table-column
                    prop="jobTitle"
                    label="职务级别"
                    min-width="100"
                />
                <el-table-column
                    prop="reservationPrice"
                    label="预约价格"
                    width="100"
                />
                <el-table-column
                    prop="doctorStatusName"
                    label="医生状态"
                    width="80"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template #default="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pe/doctor/doctor/edit/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="copyToClipboard(scope.row.id)">
                            复制地址
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Doctor',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                fullName: '',
                labelName: '',
                idCardNo: '',
                mobile: '',
                sex: '',
                doctorStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            loading: '',
        };
    },
    methods: {
        copyToClipboard(id) {
            const url = `pages/doctor/detail?doctorId=${id}`;
            const input = document.createElement('input');
            input.value = url;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);

            this.$message.success('地址已复制到剪贴板');
        },
        getListData(queryData) {
            return this.$api.Pe.Doctor.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pe.Doctor.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style scoped lang="scss">
</style>
