/**
 * Created by henian.xu on 2019/10/12.
 *
 */
import Vue from 'vue';
import { AxiosRequestConfig } from 'axios';
import { RouterOptions } from 'vue-router';
import { VueMetaOptions } from 'vue-meta';

interface GlobalVar {
    isDev: boolean;
    docTitle: string;
    appConfig?: {
        AXIOS_DEFAULT_CONFIG?: AxiosRequestConfig;
        ROUTER_DEFAULT_CONFIG?: RouterOptions;
        USER_API?: string;
        APP_LIST_API?: string;
        DEFAULT_IMG?: string;
        VUE_META?: VueMetaOptions;
        [k: string]: any;
    };
    [k: string]: any;
}

declare const window: Window & {
    __GLOBAL_VAR__: GlobalVar;
};

const GlobalVar: GlobalVar = window.__GLOBAL_VAR__ || {};

GlobalVar.docTitle = document.title; // 默认页面标题
GlobalVar.isDev = process.env.NODE_ENV === 'development';

function mergeConfig(config: any) {
    // TODO 写一个配置合并方法
    GlobalVar.appConfig = {
        ...GlobalVar.appConfig,
        ...config,
    };
}

const requireConfig = (require as any).context(
    '@',
    true,
    /\.(\/.[^/]*)?\/(config|config\/index)\.js$/,
    // 只搜索1、2级目录下的的config.js或config/index.js
);

requireConfig.keys().forEach((fileName: string) => {
    const config = requireConfig(fileName);
    if (!config) return;
    mergeConfig(config);
    // console.log(config);
});
// console.log('config', requireConfig.keys());

export default Vue.observable(GlobalVar);
