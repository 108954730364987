<!-- Created by henian.xu on 2019/12/25. -->

<template>
    <div class="notice-message">
        <div class="header">
            <div class="label">
                {{ data.title }}
            </div>
            <div class="time">
                {{ data.sendTime | moment('YYYY-MM-DD HH:mm:ss') }}
            </div>
        </div>
        <div
            v-if="$slots.default"
            class="body"
        >
            <slot/>
        </div>
        <div class="footer">
            <div class="sn">
                {{ data.content }}
                <!--<div @click="onHandleMessage">
                    {{ data.noticeSn }}
                </div>-->
            </div>
            <div class="status">
                {{ data.isRead ? '已读' : '未读' }}
            </div>
        </div>
    </div>
</template>

<script>
import { router } from 'vmf';

export default {
    name: 'Message',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        callBack: {
            type: Function,
            default: () => {},
        },
    },
    methods: {
        async onHandleMessage() {
            const { noticeUrl } = this.data;
            const { data } = await this.$api.Ms.UserMessage.updateMessageRead({ ids: this.data.id });
            this.callBack();
            this.$emit('updateReadCount', data);
            this.$emit('change', {
                ...this.data,
                isRead: true,
            });
            const { path } = router.currentRoute;
            if (noticeUrl && path !== noticeUrl) {
                return router.push(noticeUrl);
            }
        },
    },
};
</script>

<style lang="scss">
.notice-message {
    padding: $padding;

    + .notice-message {
        border-top: 1px solid $color-border;
    }

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .label {
            flex: 1;
            font-size: 120%;
        }

        > .time {
            flex: 0 0 auto;
        }
    }

    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .sn {
            flex: 1;

            > div {
                display: inline-block;
                color: $color-primary;
                cursor: pointer;
            }
        }

        > .status {
            flex: 0 0 auto;
        }
    }
}
</style>
