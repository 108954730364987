/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';

class ServantDecoration extends Sv {
    constructor() {
        super();
        this.baseUrl += '/servantDecoration';
    }

    data({ pageType, pageName, currentPage, pageSize }) {
        return super.get('/data', { pageType, pageName, currentPage, pageSize });
    }

    saveJsonData({ id, pageType, adPageType, distributorLevelId, JsonData, pageName, isAvailable } = {}) {
        return super.post('/saveJsonData', {
            id,
            pageType,
            adPageType,
            distributorLevelId,
            JsonData,
            pageName,
            isAvailable,
        });
    }

    getJsonData({ id } = {}) {
        return super.get('/getJsonData', {
            id,
        });
    }

    updateIsAvailable({ id } = {}) {
        return super.post('/updateIsAvailable', {
            id,
        });
    }

    delete({ id } = {}) {
        return super.post('/delete', {
            id,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    select() {
        return super.get('/select');
    }
}

export default new ServantDecoration();
export { ServantDecoration };
