<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基础信息</span>
                </div>
                <div class="item">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="schedulingRuleType"
                                label="规则类型"
                                label-width="9em"
                            >
                                <dictionaries-picker
                                    type="schedulingRuleType"
                                    v-model="formModel.schedulingRuleType"
                                    placeholder="请选择"
                                    @change="changeRuleType"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="schedulingPlanName"
                                label="计划名称"
                                label-width="9em"
                            >
                                <el-input
                                    v-model="formModel.schedulingPlanName"
                                    placeholder="请输入计划名称"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="planDate"
                                label="计划日期"
                                label-width="9em"
                            >
                                <el-date-picker
                                    :picker-options="$utils.CommonConfig.pickerOptions"
                                    v-model="formModel.planDate"
                                    type="daterange"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="remarks"
                                label="备注"
                                label-width="9em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.remarks"
                                    placeholder="请输入备注"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>排班信息</span>
                    <el-button
                        class="ma-l"
                        type="success"
                        size="small"
                        @click="addPlanDetail"
                    >
                        新增
                    </el-button>
                </div>
                <div class="item">
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="formModel.planDetailList"
                        style="width: 100%"
                        @selection-change="changePlanDetailListSelected"
                        v-if="formModel.schedulingRuleType"
                    >
                        <el-table-column
                            prop="week"
                            label="星期"
                            min-width="100"
                            v-if="+formModel.schedulingRuleType === 1"
                        >
                            <template #default="scope">
                                <checkbox-group-andOr
                                    type="week"
                                    v-model="scope.row.week"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailDate"
                            label="计划日期"
                            min-width="100"
                            v-if="+formModel.schedulingRuleType === 2"
                        >
                            <template #default="scope">
                                <el-date-picker
                                    size="small"
                                    :picker-options="$utils.CommonConfig.pickerOptions"
                                    v-model="scope.row.detailDate"
                                    type="daterange"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailTime"
                            label="计划时间"
                            min-width="100"
                        >
                            <template #default="scope">
                                <el-time-picker
                                    size="small"
                                    is-range
                                    v-model="scope.row.detailTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    range-separator="至"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    placeholder="选择时间范围"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="businessTableId"
                            label="站点"
                            min-width="100"
                        >
                            <template #default="scope">
                                <RemoteSelect
                                    filterable
                                    remote="/pe/station/data"
                                    placeholder="请输入"
                                    :props="{id:'id',name:'selectName'}"
                                    :remote-params="{isAvailable: 1}"
                                    v-model="scope.row.businessTableId"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="100"
                        >
                            <template #default="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="deletePlanDetail(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>医生信息</span>
                    <el-button
                        class="ma-l"
                        size="small"
                        type="success"
                        @click="openDoctorDialog"
                    >
                        选择
                    </el-button>
                </div>
                <div class="item">
                    <el-table
                        class="ma-t"
                        stripe
                        border
                        size="small"
                        ref="doctorDialogTable"
                        :data="formModel.objectList"
                        row-key="id"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="avatarUrl"
                            label="照片"
                            width="100"
                        >
                            <template v-slot="scope">
                                <imageList
                                    :data="scope.row.avatarUrl"
                                    :item-width="50"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="fullName"
                            label="姓名"
                            min-width="100"
                        />
                        <el-table-column
                            prop="labelName"
                            label="标签"
                            min-width="100"
                        />
                        <el-table-column
                            prop="idCardNo"
                            label="身份证号"
                            width="150"
                        />
                        <el-table-column
                            prop="mobile"
                            label="联系电话"
                            width="100"
                        />
                        <el-table-column
                            prop="sexName"
                            label="性别"
                            width="60"
                        />
                        <el-table-column
                            prop="birthday"
                            label="出生日期"
                            width="100"
                        />
                        <el-table-column
                            prop="graduatedSchool"
                            label="毕业学校"
                            min-width="100"
                        />
                        <el-table-column
                            prop="jobTitle"
                            label="职务级别"
                            min-width="100"
                        />
                        <el-table-column
                            prop="reservationPrice"
                            label="预约价格"
                            width="100"
                        />
                        <el-table-column
                            prop="doctorStatusName"
                            label="医生状态"
                            width="80"
                        />
                        <el-table-column
                            prop="remarks"
                            label="备注"
                            min-width="100"
                        />
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="60"
                        >
                            <template #default="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="deleteDoctorDialog(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </el-form>

        <div
            class="ma-t"
            style="margin-left: 10.5%"
        >
            <el-button
                type="primary"
                size="small"
                @click="onSave"
            >
                保 存
            </el-button>
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>

        <el-dialog
            title="选择医生"
            center
            width="1200px"
            :visible.sync="doctorDialog.isVisible"
            @closed="cancelDoctorDialog"
        >
            <el-form
                ref="queryDoctorForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="doctorDialog.queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="doctorDialog.queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="labelName"
                    label="标签"
                >
                    <el-input
                        v-model="doctorDialog.queryFormModel.labelName"
                        placeholder="请输入标签"
                    />
                </el-form-item>
                <el-form-item
                    prop="idCardNo"
                    label="身份证号"
                >
                    <el-input
                        v-model="doctorDialog.queryFormModel.idCardNo"
                        placeholder="请输入身份证号"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="联系电话"
                >
                    <el-input
                        v-model="doctorDialog.queryFormModel.mobile"
                        placeholder="请输入联系电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="sex"
                    label="性别"
                >
                    <dictionaries-picker
                        type="sex"
                        v-model="doctorDialog.queryFormModel.sex"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="queryDoctorDialog()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="resetDoctorDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-table
                stripe
                border
                size="small"
                ref="doctorDialogTable"
                :data="doctorDialog.tableData"
                row-key="id"
                style="width: 100%"
                @selection-change="changeSelectedDoctorDialog"
            >
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="50"
                />
                <el-table-column
                    prop="avatarUrl"
                    label="照片"
                    width="100"
                >
                    <template v-slot="scope">
                        <imageList
                            :data="scope.row.avatarUrl"
                            :item-width="50"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="labelName"
                    label="标签"
                    min-width="100"
                />
                <el-table-column
                    prop="idCardNo"
                    label="身份证号"
                    width="150"
                />
                <el-table-column
                    prop="mobile"
                    label="联系电话"
                    width="100"
                />
                <el-table-column
                    prop="sexName"
                    label="性别"
                    width="60"
                />
                <el-table-column
                    prop="birthday"
                    label="出生日期"
                    width="100"
                />
                <el-table-column
                    prop="graduatedSchool"
                    label="毕业学校"
                    min-width="100"
                />
                <el-table-column
                    prop="jobTitle"
                    label="职务级别"
                    min-width="100"
                />
                <el-table-column
                    prop="reservationPrice"
                    label="预约价格"
                    width="100"
                />
                <el-table-column
                    prop="doctorStatusName"
                    label="医生状态"
                    width="80"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="doctorDialog.pagination"
                    @input="queryDoctorDialog"
                />
            </div>

            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="confirmDoctorDialog"
                    :disabled="!doctorDialog.currentTableSelect.length"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="cancelDoctorDialog"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'SchedulingPlanEdit',
    mixins: [pagesMixin],
    data() {
        return {
            formModel: {
                schedulingRuleType: '',
                schedulingObjectType: 2,
                schedulingPlanName: '',
                planDate: [],
                remarks: '',
                planDetailList: [],
                objectList: [],
            },
            currentPlanDetailTableSelect: [],
            formRules: {
                schedulingRuleType: {
                    required: true,
                    message: '请选择',
                },
                schedulingPlanName: {
                    required: true,
                    message: '请输入',
                },
                planDate: {
                    required: true,
                    message: '请输入',
                },
            },
            doctorDialog: {
                isVisible: false,
                queryFormModel: {
                    fullName: '',
                    labelName: '',
                    idCardNo: '',
                    mobile: '',
                    sex: '',
                    doctorStatus: 100,
                },
                tableData: [],
                currentTableSelect: [],
                pagination: {},
            },
        };
    },
    methods: {
        onSave() {
            this.$refs.addForm.validate().then(() => {
                this.$api.Pe.SchedulingPlan.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        changePlanDetailListSelected(val) {
            this.currentPlanDetailTableSelect = val;
        },
        changeRuleType() {
            this.formModel.planDetailList = [];
        },
        addPlanDetail() {
            if (!this.formModel.schedulingRuleType) {
                this.$alert('请先选择规则类型', '错误提示', {
                    type: 'error',
                });
                return;
            }
            if (!this.formModel.planDetailList) {
                this.formModel.planDetailList = [];
            }
            this.formModel.planDetailList.push({
                week: '',
                detailDate: [],
                detailTime: '',
                businessTableId: '',
            });
        },
        deletePlanDetail(row) {
            this.formModel.planDetailList.splice(this.formModel.planDetailList.indexOf(row), 1);
        },

        // region doctorDialog
        openDoctorDialog() {
            this.doctorDialog.isVisible = true;
            this.$nextTick(() => {
                const { queryDoctorForm } = this.$refs;
                if (queryDoctorForm) {
                    queryDoctorForm.resetFields();
                }
                this.queryDoctorDialog();
                const { doctorDialogTable } = this.$refs;
                // 初始化已选择数据
                if (doctorDialogTable) {
                    doctorDialogTable.clearSelection();
                }
                this.formModel.objectList.forEach(item => {
                    doctorDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        cancelDoctorDialog() {
            this.doctorDialog.isVisible = false;
        },
        changeSelectedDoctorDialog(val) {
            this.doctorDialog.currentTableSelect = val;
        },
        queryDoctorDialog() {
            this.$api.Pe.Doctor.data({
                ...this.doctorDialog.queryFormModel,
                ...this.doctorDialog.pagination,
            }).then(json => {
                const res = json.data;
                this.doctorDialog.tableData = res.data;
                this.doctorDialog.pagination = res.pagination;
            });
        },
        resetDoctorDialog() {
            this.$refs.queryDoctorForm.resetFields();
        },
        confirmDoctorDialog() {
            const { currentTableSelect } = this.doctorDialog;
            if (currentTableSelect && currentTableSelect.length) {
                const { objectList } = this.formModel;
                const temp = [];
                currentTableSelect.forEach(item => {
                    let isFind = false;
                    if (objectList && objectList.length) {
                        objectList.forEach(oldItem => {
                            if (+oldItem.id === +item.id) {
                                temp.push({ ...oldItem });
                                isFind = true;
                            }
                        });
                    }
                    if (!isFind) {
                        temp.push({ ...item });
                    }
                });

                this.formModel.objectList = [...temp];
                this.doctorDialog.isVisible = false;
            }
        },
        deleteDoctorDialog(row) {
            this.formModel.objectList.splice(this.formModel.objectList.indexOf(row), 1);
        },
        // endregion
    },
};
</script>

<style scoped lang="scss">
</style>
