import { Pe } from './Pe';

class PhysicalExamReservation extends Pe {
    constructor() {
        super();
        this.baseUrl += '/physicalExamReservation';
    }

    data({ fullName, idCardNo, stationName, mobile, reservationDate, reservationStatus, currentPage, pageSize } = {}) {
        let reservationDateBegin, reservationDateEnd;
        if (reservationDate && reservationDate.length === 2) {
            [reservationDateBegin, reservationDateEnd] = reservationDate;
        }
        return super.get('/data', {
            fullName,
            idCardNo,
            stationName,
            mobile,
            reservationDateBegin,
            reservationDateEnd,
            reservationStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }
}
export default new PhysicalExamReservation();
export { PhysicalExamReservation };
