<!-- Created by henian.xu on 2019/8/22. -->

<template>
    <el-header class="layout-menu-nav">
        <div
            v-if="!isCollapse"
            :style="brandStyle"
            class="brand"
        >
            <div
                v-if='siteDetail.shortName'
                :style='navBrandStyle.inner'
                class='inner'
            >
                {{ siteDetail.shortName }}
            </div>
        </div>
        <div
            class="collapse-btn"
            @click="()=>isCollapse=!isCollapse"
        >
            <i
                v-if="isCollapse"
                class="el-icon-s-unfold fs-big"
            />
            <i
                v-else
                class="el-icon-s-fold fs-big"
            />
        </div>
        <NavMenu />
        <div
            class='remainder-menu'
            ref='remainderMenu'></div>
        <!--<RemainderMenu />-->
        <UserMenu />
    </el-header>
</template>

<script>
import Vue from 'vue';
import UserMenu from './_userMenu.vue';
// import RemainderMenu from './_remainderMenu.vue';
import NavMenu from './_navMenu.vue';
import { menuState } from './MenuState';

export default {
    name: 'Nav',
    inject: {
        Menu: { default: {} },
    },
    components: { UserMenu, NavMenu /* , RemainderMenu */ },
    data() {
        return {
            isMounted: false,
            siteDetail: this.$utils.GlobalVar.site || { shortName: this.$$t('vmf_management_system', '管理系统') },
            remainderMenuInstance: null,
        };
    },
    computed: {
        remainderMenu() {
            const { isMounted } = this;
            if (!isMounted) return null;
            return menuState.remainderMenu;
        },
        isCollapse: {
            get() {
                return menuState.isCollapse;
            },
            set(val) {
                menuState.isCollapse = val;
            },
        },
        navBrandStyle() {
            const { navBrandStyle } = this.Menu;
            return navBrandStyle || {};
        },
        brandStyle() {
            const { navBrandStyle, brandBackgroundImageUrl } = this.$utils.GlobalVar.site || {};
            const res = {};
            if (brandBackgroundImageUrl) res.backgroundImage = `url(${brandBackgroundImageUrl})`;
            return {
                ...res,
                ...navBrandStyle,
                inner: undefined,
            };
        },
    },
    watch: {
        remainderMenu: {
            handler(val) {
                const {
                    $refs: { remainderMenu },
                    remainderMenuInstance,
                } = this;
                if (!val) {
                    if (remainderMenu && remainderMenuInstance) {
                        remainderMenu.removeChild(remainderMenuInstance.$el);
                    }
                    return;
                }
                this.remainderMenuInstance = new (Vue.extend(val))();
                const node = document.createElement('div');
                remainderMenu.appendChild(node);
                this.remainderMenuInstance.$mount(node);
            },
            immediate: true,
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.layout-menu-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
    background-color: #125454;

    > .brand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 200px;
        color: #fff;
        font-size: 26px;
        letter-spacing: 8px;
        font-weight: bold;
        background: transparent center no-repeat;
        background-size: contain;
        > .inner {
            line-height: 1.2;
            @include text-line(1);
        }
    }

    // 子组件
    > .collapse-btn,
    > .remainder-btn {
        padding: 0 $padding;
        min-width: 44px;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
    }

    > .user-menu,
    > .remainder-menu {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 $padding;
        color: #fff;
    }
}
</style>
