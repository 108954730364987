import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@/assets/styles/innerStyle.scss';
import { Vue, Utils } from 'vmf';
import './router';
import './store';
import Components from '@vmf/components';
import LocalComponents from '@/components';
import api from './api/install';
import WebSocket from './plugins/WebSocket';

const { GlobalVar } = Utils;
Vue.prototype.$globalVar = GlobalVar;

Vue.use(Components);
Vue.use(LocalComponents);
Vue.use(WebSocket);
Vue.use(api);
