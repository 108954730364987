/**
 * Created by henian.xu on 2019/11/24.
 *
 */

import { VueConstructor } from 'vue';
import { GlobalVar } from '@vmf/shared';
import VueMeta from 'vue-meta';

export default (vue: VueConstructor) => {
    const { appConfig = {} } = GlobalVar;
    vue.prototype.$defaultImg = appConfig.DEFAULT_IMG;
    vue.use(VueMeta, appConfig.VUE_META);
};
