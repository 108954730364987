import { Mb } from './Mb';

class MemberVolunteer extends Mb {
    constructor() {
        super();
        this.baseUrl += '/memberVolunteer';
    }

    data({
        keyword,
        mobile,
        regionId,
        communityName,
        companyName,
        jobTitle,
        roleCategory,
        applyTime,
        approvalTime,
        approvalStatus,
        currentPage,
        pageSize,
    } = {}) {
        let applyTimeBegin, applyTimeEnd;
        if (applyTime && applyTime.length === 2) {
            [applyTimeBegin, applyTimeEnd] = applyTime;
        }
        let approvalTimeBegin, approvalTimeEnd;
        if (approvalTime && approvalTime.length === 2) {
            [approvalTimeBegin, approvalTimeEnd] = approvalTime;
        }
        return super.get('/data', {
            keyword,
            mobile,
            regionId,
            communityName,
            companyName,
            jobTitle,
            roleCategory,
            applyTimeBegin,
            applyTimeEnd,
            approvalTimeBegin,
            approvalTimeEnd,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    audit({ id, approvalStatus, approvalRemark } = {}) {
        return super.post('/audit', {
            id,
            approvalStatus,
            approvalRemark,
        });
    }
}
export default new MemberVolunteer();
export { MemberVolunteer };
