<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="name"
                        label="病例名称"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.name"
                            placeholder="请输入病例名称"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="nameEn"
                        label="病例英文名"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.nameEn"
                            placeholder="请输入病例英文名"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="nameOther"
                        label="病例别名"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.nameOther"
                            placeholder="请输入病例别名"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="icon"
                        label="图标"
                        label-width="12em"
                    >
                        <upload
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.icon"
                            tips=""
                            validate-md5
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>

                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="caseCatId"
                        label="病例分类"
                        label-width="12em"
                    >
                        <el-tree
                            :data="TreeData.TreeData"
                            show-checkbox
                            node-key="id"
                            ref="tree"
                            :props="{
                                label: 'name',
                                children: 'children',
                            }"
                            :default-checked-keys="TreeData.caseCatIdList"
                            @check="changeCaseCatId"
                        />
                    </el-form-item>

                    <el-form-item
                        prop="overview"
                        label="概述"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.overview"
                            image-upload-tips=""
                        />
                    </el-form-item>

                    <el-form-item
                        prop="symptoms"
                        label="症状"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.symptoms"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="causes"
                        label="病因"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.causes"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="treatment"
                        label="治疗"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.treatment"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="daily"
                        label="日常"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.daily"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="seekingMedicalAdvice"
                        label="就医"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.seekingMedicalAdvice"
                            image-upload-tips=""
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="isRecommend"
                        label="是否推荐"
                        label-width="12em"
                    >
                        <el-switch
                            v-model="formModel.isRecommend"
                            :active-value="true"
                            :inactive-value="false"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="12em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="12em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            placeholder="请输入备注"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { Utils } from 'vmf';

export default {
    name: 'CaseItemEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                caseCatIdList: [],
                id: '',
                icon: [],
                name: '',
                nameEn: '',
                nameOther: '',
                overview: '',
                symptoms: '',
                causes: '',
                treatment: '',
                daily: '',
                seekingMedicalAdvice: '',
                remarks: '',
                isAvailable: true,
                isRecommend: false,
            },
            // 表单校验规则
            formRules: {
                name: {
                    required: true,
                    message: '请输入',
                },
                nameEn: {
                    required: true,
                    message: '请输入',
                },
                nameOther: {
                    required: true,
                    message: '请输入',
                },
                icon: {
                    required: true,
                    message: '请输入',
                    type: 'array',
                },
                isRecommend: {
                    required: true,
                    message: '请选择',
                },
            },
            selectApi: this.$api.Pe.CaseCat,
            TreeData: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            if (!this.formModel.caseCatIdList || this.formModel.caseCatIdList.length === 0) {
                this.$message({
                    message: '病例分类不能为空！',
                    type: 'warning',
                });
                return;
            }
            addForm.validate().then(() => {
                this.$api.Pe.CaseItem.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pe.CaseItem.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.TreeData.caseCatIdList = res.caseCatIdList;
            });
        },
        initCaseCat() {
            this.$api.Pe.CaseCat.selectAll({ isFilterAuth: 1 }).then(json => {
                const res = json.data.data;
                this.$set(this.TreeData, 'TreeData', this.$utils.Convert.listToTree(res));
            });
        },
        changeCaseCatId() {
            this.formModel.caseCatIdList = this.$refs.tree.getCheckedKeys(true);
        },
    },
    created() {
        this.initCaseCat();
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
