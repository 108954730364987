import { Mb } from './Mb';

class MemberSettlement extends Mb {
    constructor() {
        super();
        this.baseUrl += '/memberSettlement';
    }

    data({ userName, mobile, fullName, applyTime, approvalTime, approvalStatus, currentPage, pageSize } = {}) {
        let applyTimeBegin, applyTimeEnd;
        if (applyTime && applyTime.length === 2) {
            [applyTimeBegin, applyTimeEnd] = applyTime;
        }
        let approvalTimeBegin, approvalTimeEnd;
        if (approvalTime && approvalTime.length === 2) {
            [approvalTimeBegin, approvalTimeEnd] = approvalTime;
        }
        return super.get('/data', {
            userName,
            mobile,
            fullName,
            applyTimeBegin,
            applyTimeEnd,
            approvalTimeBegin,
            approvalTimeEnd,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    audit({ id, approvalStatus, approvalRemark } = {}) {
        return super.post('/audit', {
            id,
            approvalStatus,
            approvalRemark,
        });
    }
}
export default new MemberSettlement();
export { MemberSettlement };
