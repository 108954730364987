<template>
    <page back>
        <el-row>
            <el-col
                :span="24"
                v-if="formModel.courseStatus === 40">
                <el-form
                    ref="auditForm"
                    class="second-header npa-b"
                    size="small"
                    :model="auditModel"
                    :rules="auditRules"
                >
                    <el-card
                        class="ma-b"
                        size="small"
                        shadow="never"
                    >
                        <div
                            slot="header"
                            class="clearfix"
                        >
                            <span>审核结果</span>
                        </div>
                        <el-col :span="24">
                            <el-col :span="16">
                                <el-form-item
                                    label="审核不通过原因:"
                                    label-width="11em"
                                >
                                    {{businessAuditLog.auditOpinion}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="审核时间:"
                                    label-width="11em"
                                >
                                    {{businessAuditLog.auditTime | moment('YYYY-MM-DD HH:mm:ss')}}
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-card>
                </el-form>
            </el-col>

            <el-tabs
                class="classify-tabs"
                v-model="tabType"
                type="card"
                v-if="formModel.id !== undefined && formModel.courseLearnType === 1"
            >
                <el-tab-pane
                    label="课程"
                    name="1"
                />
                <el-tab-pane
                    label="课程详情"
                    name="2"
                />
            </el-tabs>
            <div style="height: 30px"/>
            <el-form
                ref="addForm"
                class="second-header npa-b"
                size="small"
                :model="formModel"
                :rules="formRules"
                :disabled="formModel.id && formModel.courseStatus === 30"
            >
                <el-col
                    :span="24"
                    v-if="+tabType === 1">
                    <el-col :span="18">
                        <el-form-item
                            prop="courseLearnType"
                            label="课程学习方式"
                            label-width="8em"
                        >
                            <dictionaries-picker
                                type="courseLearnType"
                                v-model="formModel.courseLearnType"
                                :disabled="!!formModel.id"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="courseCatId"
                            label="课程分类"
                            label-width="8em"
                        >
                            <CascaderPicker
                                :api-class="selectApi"
                                v-model="formModel.courseCatId"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="courseName"
                            label="课程名称"
                            label-width="8em"
                        >
                            <el-input
                                v-model="formModel.courseName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="courseImage"
                            label="课程图片"
                            label-width="8em"
                        >
                            <upload
                                :validate-md5="true"
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="10"
                                v-model="formModel.courseImage"
                                tips="只能上传jpg/png文件，且不超过500kb"
                                :oss-action="$globalVar.appConfig.OSS_ACTION"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="courseDesc"
                            label="上课简介"
                            label-width="8em"
                        >
                            <Tinymce
                                :height="300"
                                image-action="/rs/attachment/uploadFileLibrary"
                                v-model="formModel.courseDesc"
                                id="courseDesc"
                                image-upload-tips="图片大小不能超过5M，格式支持jpg、png、bmp"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="courseMemberNum"
                            label="课程人数"
                            label-width="8em"
                        >
                            <el-input-number
                                v-model="formModel.courseMemberNum"
                                controls-position="right"
                                :min="0"
                                :precision="0"
                            />为0 不限制人数
                        </el-form-item>
                        <el-form-item
                            prop="courseAmount"
                            label="课程金额"
                            label-width="8em"
                        >
                            <el-input-number
                                v-model="formModel.courseAmount"
                                controls-position="right"
                                :min="0"
                                :precision="2"
                            />为0 课程免费
                        </el-form-item>
                        <template v-if="formModel.courseLearnType == 2">
                            <el-form-item
                                prop="courseTime"
                                label="课程时间"
                                label-width="8em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.courseTime"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="courseRegionId"
                                label="课程地址"
                                label-width="8em"
                            >
                                <CascaderPicker
                                    filterable
                                    :api-class="selectApi1"
                                    v-model="formModel.courseRegionId"
                                    :p-id="0"
                                    :min-lv="1"
                                    :query-params="{minLv:1}"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="courseDetailAddress"
                                label="课程详细地址"
                                label-width="8em"
                            >
                                <el-input
                                    v-model="formModel.courseDetailAddress"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="latLng"
                                label="课程坐标"
                                label-width="8em"
                            >
                                <LatLngPicker v-model="formModel.latLng"/>
                            </el-form-item>
                        </template>
                        <!--                        <el-form-item
                            prop="courseHour"
                            label="等效学时"
                            label-width="8em"
                            v-if="formModel.courseHour !== '' && formModel.courseHour !== null"
                        >
                            {{ formModel.courseHour }}
                        </el-form-item>-->
                    </el-col>
                </el-col>
                <el-col
                    :span="24"
                    v-if="+tabType === 2 && formModel.id !== undefined">
                    <el-col :span="20">
                        <el-form-item
                            prop="courseDetailList"
                            label="课程详情"
                            label-width="8em"
                        >
                            <div class="ma-b">
                                <el-button-group>
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAdd2()"
                                    >
                                        新增
                                    </el-button>
                                </el-button-group>
                            </div>

                            <el-table
                                stripe
                                border
                                size="small"
                                :data="formModel.courseDetailList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="orderNum"
                                    label="排序号"
                                    width="100"
                                />
                                <el-table-column
                                    prop="courseName"
                                    label="课程名称"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="courseTypeName"
                                    label="课程类型"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="validMinutes"
                                    label="有效分钟数"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="courseHour"
                                    label="等效学时"
                                    min-width="100"
                                />
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="100"
                                >
                                    <template slot-scope="scope2">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onEdit2(scope2.row)"
                                        >
                                            编辑
                                        </el-button>
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDelete2(scope2.row.id)"
                                        >
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="12">
                        <el-form-item
                            label-width="8em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm1"
                                v-if="+tabType === 1"
                            >
                                保 存
                            </el-button>
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                返 回
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-form>

            <el-col
                :span="24"
                v-if="formModel.courseStatus === 30 && $power(['course:audit'])">
                <el-form
                    ref="auditForm"
                    class="second-header npa-b"
                    size="small"
                    :model="auditModel"
                    :rules="auditRules"
                >
                    <el-card
                        class="ma-t"
                        size="small"
                        shadow="never"
                    >
                        <div
                            slot="header"
                            class="clearfix"
                        >
                            <span>审核</span>
                        </div>
                        <el-col :span="24">
                            <el-col :span="12">
                                <el-form-item
                                    prop="auditStatus"
                                    label="审核是否通过:"
                                    label-width="11em"
                                >
                                    <el-select
                                        v-model="auditModel.auditStatus"
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            label="审核通过"
                                            value="100"
                                        />
                                        <el-option
                                            label="审核不通过"
                                            value="99"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="+auditModel.auditStatus === 99"
                        >
                            <el-col :span="12">
                                <el-form-item
                                    prop="auditRemarks"
                                    label="备注:"
                                    label-width="11em"
                                >
                                    <el-input
                                        type="textarea"
                                        v-model="auditModel.auditRemarks"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :span="24">
                            <el-col :span="12">
                                <el-form-item label-width="11em">
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="submitAudit"
                                    >
                                        提交审核
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-card>
                </el-form>
            </el-col>
        </el-row>
        <!-- 弹框 - 证考课程详情 -->
        <el-dialog
            :title="addDialog2.formModel.id ? '编辑' : '新增'"
            center
            width="1000px"
            :visible.sync="addDialog2.isVisible"
            @closed="onAddDialogCancel2"
        >
            <el-form
                ref="addForm2"
                size="small"
                :model="addDialog2.formModel"
                :rules="addDialog2.formRules"
            >
                <el-form-item
                    prop="courseName"
                    label="课程名称"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog2.formModel.courseName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseImage"
                    label="课程图片"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog2.formModel.courseImage"
                        tips="只能上传jpg/png文件，且不超过500kb"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                        accept="image/*"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseType"
                    label="课程类型"
                    label-width="9em"
                >
                    <dictionaries-picker
                        type="courseType"
                        v-model="addDialog2.formModel.courseType"
                    />
                </el-form-item>
                <el-form-item
                    v-if="+addDialog2.formModel.courseType === 1"
                    prop="courseFile1"
                    label="课件"
                    label-width="9em"
                >
                    <Tinymce
                        :height="300"
                        image-action="/rs/attachment/uploadFileLibrary"
                        v-model="addDialog2.formModel.courseFile1"
                        id="courseFile1"
                        image-upload-tips="图片大小不能超过5M，格式支持jpg、png、bmp"
                    />
                </el-form-item>
                <el-form-item
                    v-if="+addDialog2.formModel.courseType === 2"
                    prop="courseFile2"
                    label="课件"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="20"
                        v-model="addDialog2.formModel.courseFile2"
                        tips="只能上传jpg/png文件，且不超过500kb"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                        accept="image/*"
                    />
                </el-form-item>
                <el-form-item
                    v-if="+addDialog2.formModel.courseType === 3"
                    prop="courseFile3"
                    label="课件"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog2.formModel.courseFile3"
                        tips="请上传MP4格式文件"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                        accept="video/*"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseFile4"
                    label="课件"
                    label-width="9em"
                    v-if="+addDialog2.formModel.courseType === 4"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog2.formModel.courseFile4"
                        tips="请上传PDF文件"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                        accept="application/pdf"
                    />
                </el-form-item>
                <!--                <el-form-item
                    prop="courseVoiceFile"
                    label="课程语音"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog2.formModel.courseVoiceFile"
                        tips="(注：请上传语音文件)"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                    />
                </el-form-item>-->
                <el-form-item
                    prop="courseDesc"
                    label="课程描述"
                    label-width="9em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog2.formModel.courseDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="validMinutes"
                    label="课程有效分钟数"
                    label-width="9em"
                >
                    <el-input-number
                        v-model="addDialog2.formModel.validMinutes"
                        controls-position="right"
                        :min="1"
                        :precision="0"
                        @change="changeValidMinutes"
                    />
                    (分)
                </el-form-item>
                <el-form-item
                    prop="courseHour"
                    label="等效学时"
                    label-width="9em"
                >
                    <el-input-number
                        v-model="addDialog2.formModel.courseHour"
                        controls-position="right"
                        :min="0.01"
                        :precision="2"
                    />
                    1学时={{ courseHourMinute }}分钟
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    label="排序号"
                    label-width="9em"
                >
                    <el-input-number
                        v-model="addDialog2.formModel.orderNum"
                        controls-position="right"
                        :min="1"
                        :precision="0"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm2"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel2"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'CourseEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                courseCatId: '',
                courseName: '',
                courseImage: [],
                courseDesc: '',
                courseDetailList: [],
                courseHour: '',
                courseLearnType: '',
                courseMemberNum: 0,
                courseAmount: 0,
                courseTime: '',
                courseRegionId: '',
                courseDetailAddress: '',
                latLng: '',

                courseStatus: '',
            },
            // 表单校验规则
            formRules: {
                courseName: [
                    {
                        required: true,
                        message: '请输入课程名称',
                        trigger: 'blur',
                    },
                ],
                courseCatId: [
                    {
                        required: true,
                        message: '请选择课程分类',
                    },
                ],
                courseLearnType: [
                    {
                        required: true,
                        message: '请选择课程学习方式',
                    },
                ],
                courseDetailList: {
                    required: true,
                    message: '请添加课程详情',
                },
                courseMemberNum: {
                    required: true,
                    message: '请输入课程学习人数',
                },
                courseAmount: {
                    required: true,
                    message: '请输入课程金额',
                },
                courseTime: {
                    required: true,
                    message: '请输入课程时间',
                },
                courseRegionId: {
                    required: true,
                    message: '请选择课程地址',
                },
                courseDetailAddress: {
                    required: true,
                    message: '请选择课程详细地址',
                },
                latLng: {
                    required: true,
                    message: '请选择课程地址坐标',
                },
            },
            addDialog2: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    courseId: '',
                    courseName: '',
                    courseImage: [],
                    courseType: '',
                    courseFile1: '',
                    courseFile2: [],
                    courseFile3: [],
                    courseFile4: [],
                    courseVoiceFile: [],
                    courseDesc: '',
                    courseHour: '',
                    orderNum: '',
                    validMinutes: '',
                },
                // 表单校验规则
                formRules: {
                    courseName: {
                        required: true,
                        message: '请输入课程名称',
                        trigger: 'blur',
                    },
                    courseType: {
                        required: true,
                        message: '请选择课程类型',
                    },
                    courseFile1: {
                        required: true,
                        message: '请输入课件',
                        trigger: 'blur',
                    },
                    courseFile2: {
                        type: 'array',
                        required: true,
                        message: '请上传课件',
                    },
                    courseFile3: {
                        type: 'array',
                        required: true,
                        message: '请上传课件',
                    },
                    courseFile4: {
                        type: 'array',
                        required: true,
                        message: '请上传课件',
                    },
                    courseHour: {
                        required: true,
                        message: '请输入等效学时',
                        trigger: 'blur',
                    },
                    validMinutes: {
                        required: true,
                        message: '请输入课程有效分钟数',
                        trigger: 'blur',
                    },
                },
            },
            tabType: '1',
            loading: '',
            courseHourMinute: '',
            selectApi: this.$api.Ce.CourseCat,
            selectApi1: this.$api.Rs.Region,
            auditModel: {
                auditStatus: '',
                auditRemarks: '',
            },
            auditRules: {
                auditStatus: {
                    required: true,
                    message: '请选择审核结果',
                },
                auditRemarks: {
                    required: true,
                    message: '请输入审核备注',
                    trigger: 'blur',
                },
            },
            businessAuditLog: {},
        };
    },
    methods: {
        onConfirm1() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ce.Course.save1({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        /**
         * 初始化
         */
        init() {
            this.$api.Ce.Course.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                if (this.formModel.id) {
                    this.$utils.formModelMerge(this.formModel, res.course);
                    this.formModel.courseDetailList = res.courseDetailList;
                }
                this.courseHourMinute = res.courseHourMinute;
                this.businessAuditLog = res.businessAuditLog;
            });
        },
        // 证考课程详情
        onAdd2() {
            this.addDialog2.isVisible = true;
            this.$nextTick(() => {
                const { addForm2 } = this.$refs;
                addForm2.resetFields();
                const { formModel } = this.addDialog2;
                formModel.id = '';
                formModel.courseFile1 = '';
                formModel.courseFile2 = [];
                formModel.courseFile3 = [];
                formModel.courseFile4 = [];
                formModel.courseId = this.formModel.id;
            });
        },
        onEdit2(row) {
            const { formModel } = this.addDialog2;
            this.$api.Ce.CourseDetail.getDetail({ id: row.id }).then(json => {
                const res = json.data.data.courseDetail;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog2.isVisible = true;
        },
        onDelete2(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ce.CourseDetail.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
        onAddDialogConfirm2() {
            const { addForm2 } = this.$refs;
            addForm2.validate().then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '保存中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ce.CourseDetail.save({
                    ...this.addDialog2.formModel,
                }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.addDialog2.isVisible = false;
                    this.init();
                });
            });
        },
        onAddDialogCancel2() {
            const { addForm2 } = this.$refs;
            addForm2.resetFields();
            const { formModel } = this.addDialog2;
            formModel.id = '';
            this.addDialog2.isVisible = false;
        },
        changeValidMinutes() {
            this.addDialog2.formModel.courseHour = this.addDialog2.formModel.validMinutes / this.courseHourMinute;
        },
        submitAudit() {
            this.$confirm('是否确定要提交审核！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                const { auditForm } = this.$refs;
                auditForm.validate().then(() => {
                    this.$api.Ce.Course.audit({ id: this.formModel.id, ...this.auditModel }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
