/**
 * Created by henian.xu on 2019/10/8.
 *
 */

import { assert, isFunction, isObject, hasOwn } from '@vmf/shared';

interface ApiModule {
    [k: string]: any;
}
export interface ApiWrap extends ApiModule {
    // addModule?<T extends object>(module: T): void;
}

// 全局 Api 模块都会挂在这里
let Api: ApiWrap = {
    // addModule<T extends object>(module: T): void {},
};
export function getApi() {
    return Api;
}
/**
 * 为了兼容老版 api 规范
 * @param api
 */
export function setApi(api: ApiWrap) {
    // GlobalVar.isDev && console.warn('[@vmf/request] 设置 $api 对象只是为了兼容老版 api 规范,建议使用新规范.');
    Api = api;
}

const rawToObserved: WeakMap<any, any> = new WeakMap();
const observedToRaw: WeakMap<any, any> = new WeakMap();
const builtInSymbols = new Set(
    Object.getOwnPropertyNames(Symbol)
        .map(key => (Symbol as any)[key])
        .filter(value => typeof value === 'symbol'),
);

/* function apiMethodsProxy<T extends Function>(fn: T, currentPath: string[]): T {
    return ((...args: any[]) => fn(...args, currentPath.join('/'))) as any;
} */

/**
 * 首字母小写
 * @param str
 */
function firstLowerCase(str: string) {
    str += '';
    return str.replace(/\b(\w)|\s(\w)/g, m => m.toLowerCase());
}

export interface MethodThis {
    url: string;
    baseUrl: string;
    paths: string[];
}
const apiProxyHandlers: ProxyHandler<any> = {
    get(target: any, key: string | number | symbol, receiver: any): any {
        const res = Reflect.get(target, key, receiver);
        if (typeof key === 'symbol' && builtInSymbols.has(key)) return res;

        // console.log('proxy', target.__parentPath__, key, res, isObject(res));
        const currentPath = [...(target.__parentPath__ || ([] as string[]))];
        currentPath.push(firstLowerCase(key as string));

        // if (isFunction(res)) return apiMethodsProxy(res, currentPath);
        // if (isFunction(res)) return (...args: any[]) => res(...args, currentPath.join('/'));
        if (isFunction(res)) {
            const bindThis: MethodThis = {
                url: currentPath.join('/'),
                baseUrl: [...currentPath].splice(0, Math.max(0, currentPath.length - 2)).join('/'),
                paths: [...currentPath],
            };
            const callBack = res.bind(bindThis);
            return (...args: any[]) => callBack(...args);
        }

        // eslint-disable-next-line no-use-before-define
        return isObject(res) ? observeObj(res, currentPath) : res;
    },
};

function observeObj<T extends object>(target: T, parentPath?: string[]): T;
function observeObj(target: object, parentPath?: string[]) {
    let observed = rawToObserved.get(target);
    if (observed !== undefined) return observed;
    if (observedToRaw.has(target)) return target;
    // 创建观察者
    observed = new Proxy(target, apiProxyHandlers);

    observed.__parentPath__ = parentPath;
    rawToObserved.set(target, observed);
    observedToRaw.set(observed, target);
    return observed;
}

export function createApiWrap<T extends object>(target: T): T;
export function createApiWrap(target: object) {
    /* const observed: ApiWrap = observeObj(target) as ApiWrap;
    observed.addModule = <T extends object>(module: T): void => {};
    return observed; */

    Object.keys(target).forEach((key: string) => {
        const observed = observeObj((target as ApiModule)[key], [firstLowerCase(key)]);
        assert(!hasOwn(Api, key), `${key} 模块已经存在了`, 'Api');
        Api[key] = observed;
    });
    return Api;
}
