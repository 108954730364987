<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="planName"
                    label="计划名称">
                    <el-input
                        v-model="queryFormModel.planName"
                        placeholder="请输入计划名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="scaleName"
                    label="量表名称">
                    <el-input
                        v-model="queryFormModel.scaleName"
                        placeholder="请输入量表名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="会员电话">
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="beginTime"
                    label="测评时间">
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.beginTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <!--                <el-table-column
                    type="selection"
                    width="36"
                />-->
                <el-table-column
                    prop="planName"
                    label="计划名称"
                    min-width="100"
                />
                <el-table-column
                    prop="scaleName"
                    label="量表名称"
                    min-width="100"
                />
                <el-table-column
                    prop="scaleSn"
                    label="量表编码"
                    min-width="100"
                />
                <el-table-column
                    prop="scaleTypeName"
                    label="量表类型"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="会员手机号"
                    min-width="100"
                />
                <el-table-column
                    label="测评时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="
                                $router.push(`/am/assess/healthyAssess/result/` + scope.row.id)
                            "
                        >
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'ExamMember',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                planName: '',
                scaleName: '',
                mobile: '',
                beginTime: [],
                planType: 1,
                orderByKey: '',
                orderByType: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Am.Assess.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        applySortChange(val) {
            if (val.order) {
                this.queryFormModel.orderByKey = val.prop;
                this.queryFormModel.orderByType = val.order === 'ascending' ? 'asc' : 'desc';
            } else {
                this.queryFormModel.orderByKey = '';
                this.queryFormModel.orderByType = '';
            }
            this.onQuery(this.pagination);
        },
    },
};
</script>

<style lang="scss">
</style>
