/**
 * Created by henian.xu on 2019/10/31.
 *
 */
import Vue from 'vue';
import { axios, Utils } from 'vmf';
import { ElementUI } from '@vmf/components';
import Api from './index';

const { Message } = ElementUI;
const { GlobalVar, Runtime } = Utils;

const httpStatusHandler = {
    isStatusProcessing: {
        403: false,
    },
    403(response) {
        if (this.isStatusProcessing[403] || GlobalVar.showModifyPasswordDialog) return;
        const { status, data } = response;
        const { code, msg } = data;
        if (status !== 403 || code !== 4031) return;
        this.isStatusProcessing[403] = true;
        Vue.prototype.$set(GlobalVar, 'showModifyPasswordDialog', true);
        Message.error({ message: msg || '', offset: 70 });
    },
};

axios.interceptors.request.use(config => {
    const router = Runtime.getRouter();
    const currentRoute = router.currentRoute || {};
    const { path, meta } = currentRoute;
    const url = meta.parentUrl || path || '';
    if (url) config.headers.menu_url = url;
    // console.log(111111, config, Runtime.getRouter(), url);
    return config;
});
axios.interceptors.response.use(
    response => {
        // console.log(11111, response);
        return response;
    },
    error => {
        const { response } = error || {};
        if (!response || !+response.status) return Promise.reject(error);
        const { status } = response;
        if (httpStatusHandler[status]) httpStatusHandler[status](response);

        return Promise.reject(error);
    },
);

export default vue => {
    // Api
    /* Object.defineProperty(vue, '$api', {
        get() {
            return Api;
        },
    });
    Object.defineProperty(vue.prototype, '$api', {
        get() {
            return Api;
        },
    }); */
    vue.prototype.$api = Api;
};
