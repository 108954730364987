<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='module-statistics'>
        <div class='item'>
            <div class='icon'><img src='./images/icon03.png'></div>
            <div class='content'>
                <div class='value'>{{ feedback.mailboxNum }}</div>
                <div class='label'>书记信箱</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'><img src='./images/icon04.png'></div>
            <div class='content'>
                <div class='value'>{{ feedback.suggestionNum }}</div>
                <div class='label'>建言献策</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'><img src='./images/icon05.png'></div>
            <div class='content'>
                <div class='value'>{{ feedback.takePhotoNum }}</div>
                <div class='label'>随手拍</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'><img src='./images/icon06.png'></div>
            <div class='content'>
                <div class='value'>{{ feedback.anonymousReportNum }}</div>
                <div class='label'>匿名举报</div>
            </div>
        </div>
    </div>
</template>

<script>
import { rsLargeScreenMixin } from 'pages/largeScreen/store';

export default {
    name: 'ModuleStatistics',
    mixins: [rsLargeScreenMixin],
    data() {
        return {};
    },
};
</script>

<style lang='scss'>
.module-statistics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > .item {
        flex: 0 0 (100% / 4);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .icon {
            width: 66px;
            margin-right: $margin;
        }
        > .content {
            flex: 1 1 1%;
            > .value {
                font-size: 24px;
                font-weight: bold;
                color: #f8ca6c;
            }
            > .label {
                font-size: 14px;
            }
        }
    }
}
</style>
