/**
 * Created by henian.xu on 2019/11/1.
 *
 */

export function sortAtOrderNum(list: any[]) {
    list.sort((prev, next) => prev.orderNum - next.orderNum || prev.id - next.id);
}

export function sortTreeData(list: any[]) {
    if (!Array.isArray(list) && typeof list !== 'object') return;
    list.forEach(item => {
        if (item.children && item.children.length) {
            sortTreeData(item.children);
        }
    });
    sortAtOrderNum(list);
}

export function listToTree(list: any[], lv: number) {
    // console.log('convertListToTree');
    const res: any[] = [];
    const parentNodeMap: {
        [k: string]: any;
    } = {};
    let minLv = 9999999;
    list.forEach(item => {
        // item.id 有值时才可能是父节点 (这个有数据问题 id 不应该不存在)
        if (item.id) {
            parentNodeMap[item.id] = item;
            minLv = lv || (item.lv < minLv ? item.lv : minLv);
        }
    });
    list.forEach(item => {
        // item.lv = minLv 时是根节点
        if (item.lv === minLv) {
            res.push(item);
        } else {
            const parentNode = parentNodeMap[item.parentId];
            if (parentNode) {
                parentNode.children = parentNode.children || [];
                parentNode.children.push(item);
            } else {
                console.error('此节点数据有问题', item);
            }
        }
    });
    // console.log(res);
    sortTreeData(res);
    // console.log(res);
    return res;
}

export default {
    listToTree,
    sortAtOrderNum,
    sortTreeData,
};
