import { Mb } from './Mb';

class ActivityComment extends Mb {
    constructor() {
        super();
        this.baseUrl += '/activityComment';
    }

    data({ activityId, activityCommentId, departmentId, fullName, mobile, comments, currentPage, pageSize } = {}) {
        return super.get('/data', {
            activityId,
            activityCommentId,
            departmentId,
            fullName,
            mobile,
            comments,
            currentPage,
            pageSize,
        });
    }

    status({ id, isDelete } = {}) {
        return super.post('/status', {
            id,
            isDelete,
        });
    }
}
export default new ActivityComment();
export { ActivityComment };
