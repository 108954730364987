/**
 * Created by henian.xu on 2023/12/19.
 *
 */

import Vue from 'vue';
import { Utils, get } from 'vmf';

export const state = Vue.observable({
    rawData: null,
});

export const getRsLargeScreen = (() => {
    const fn = Utils.integrateAsync(async function func() {
        const res = await get(
            '/rs/getScreen',
            {},
            {
                isAxiosResponseData: true,
            },
        );
        console.log(1111, res);
        state.rawData = res.data;
    });
    return function func(params) {
        return fn.call(this, params /* , { groupKey: `${storeId}` } */);
    };
})();

export const rsLargeScreenMixin = {
    data() {
        return {};
    },
    computed: {
        detail() {
            const { rawData } = state;
            if (rawData === null) {
                getRsLargeScreen();
            }
            return rawData || {};
        },
        study() {
            return this.detail.study || {};
        },
        maleFemaleRatio() {
            return this.detail.maleFemaleRatio || {};
        },
        ageAnalysis() {
            return this.detail.ageAnalysis || {};
        },
        branchPartyMember() {
            return this.detail.branchPartyMember || {};
        },
        ranking() {
            return this.detail.ranking || [];
        },
        partyDue() {
            return this.detail.partyDue || {};
        },
        partyMemberDistribution() {
            return this.detail.partyMemberDistribution || [];
        },
        feedback() {
            return this.detail.feedback || {};
        },
    },
};
