import { Mb } from './Mb';

class PartyMember extends Mb {
    constructor() {
        super();
        this.baseUrl += '/partyMember';
    }

    data({
        selectOrgId,
        partyMemberType,
        fullName,
        mobile,
        idCardNo,
        sex,
        nationality,
        joinPartyDate,
        highestDegree,
        partyPositions,
        regionId,
        currentPage,
        pageSize,
    } = {}) {
        let joinPartyDateBegin, joinPartyDateEnd;
        if (joinPartyDate && joinPartyDate.length === 2) {
            [joinPartyDateBegin, joinPartyDateEnd] = joinPartyDate;
        }
        return super.get('/data', {
            selectOrgId,
            partyMemberType,
            fullName,
            mobile,
            idCardNo,
            sex,
            nationality,
            joinPartyDateBegin,
            joinPartyDateEnd,
            highestDegree,
            partyPositions,
            regionId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const res = json.data.data;
                const { fileBasePath, avatar } = res;
                if (fileBasePath && avatar) {
                    res.avatar = avatar.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                } else {
                    res.avatar = [];
                }

                return json;
            });
    }

    save({
        id,
        orgId,
        partyMemberType,
        avatar,
        fullName,
        mobile,
        alternateContact,
        idCardNo,
        sex,
        birthdayDate,
        nationality,
        joinPartyDate,
        highestDegree,
        majorName,
        jobTitle,
        partyPositions,
        jobName,
        regionId,
    } = {}) {
        let avatarUrl;
        if (avatar && avatar.length) {
            avatarUrl = avatar
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/save', {
            id,
            orgId,
            partyMemberType,
            avatar: avatarUrl,
            fullName,
            mobile,
            alternateContact,
            idCardNo,
            sex,
            birthdayDate,
            nationality,
            joinPartyDate,
            highestDegree,
            majorName,
            jobTitle,
            partyPositions,
            jobName,
            regionId,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}
export default new PartyMember();
export { PartyMember };
