<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='party-member-branch-info'>
        <div class='header'>
            <div class='item'>
                <div class='label'>支部数量合计</div>
                <div class='value'><span class='val'>{{ branchPartyMember.orgNum }}</span><span class='unit'>个</span></div>
            </div>
            <div class='item'>
                <div class='label'>党员数合计</div>
                <div class='value'><span class='val'>{{ branchPartyMember.partyMemberSum }}</span><span class='unit'>个</span></div>
            </div>
        </div>
        <div
            v-if='partyMemberDistribution && partyMemberDistribution.length'
            class='body'>
            <div
                v-for='(item,index) in partyMemberDistribution'
                :key='index'
                class='item'>
                <div class='bg'></div>
                <div class='inner'>
                    <div class='label'>{{ item.orgName }}</div>
                    <div class='value'>{{item.partyMemberNum}}人</div>
                    <div class='time'>{{ item.createDate }}成立</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { rsLargeScreenMixin } from 'pages/largeScreen/store';

export default {
    name: 'PartyMemberBranchInfo',
    mixins: [rsLargeScreenMixin],
    data() {
        return {};
    },
    computed: {
        partyMemberDistribution() {
            const list = this.branchPartyMember.partyMemberDistribution || [];
            return [...list].splice(0, 4);
        },
    },
};
</script>

<style lang='scss'>
.party-member-branch-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding 0;
        > .item {
            position: relative;
            flex: 1 1 50%;
            padding-left: $padding-big;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 3em;
                border-left: 5px solid #ba2825;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
            }

            > .label {
                font-size: 14px;
            }
            > .value {
                > .val {
                    font-size: 24px;
                    color: #f0ce8f;
                }
            }
        }
    }
    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding: $padding 0;
        > .item {
            position: relative;

            > .bg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(to right, #3e1406, transparent);
                background-color: transparent;
                transform: skewX(-20deg);
            }
            > .inner {
                position: relative;
                z-index: 10;
                padding: 0 $padding;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;

                > .label {
                    flex: 1 1 1%;
                    padding: $padding-small;
                }
                > .value {
                    flex: 0 0 100px;
                    padding: $padding-small;
                    color: #f0ce8f;
                }
                > .time {
                    flex: 0 0 150px;
                    padding: $padding-small;
                    text-align: right;
                }
            }

            + .item {
                margin-top: $margin;
            }
        }
    }
}
</style>
