import { Mb } from './Mb';

class PartyDue extends Mb {
    constructor() {
        super();
        this.baseUrl += '/partyDue';
    }

    data({
        selectOrgId,
        fullName,
        idCardNo,
        mobile,
        dueDate,
        partyDueStatus,
        insertTime,
        updateTime,
        paymentTime,
        paymentSn,
        externalPaymentSn,
        remarks,
        currentPage,
        pageSize,
    } = {}) {
        let dueDateBegin, dueDateEnd;
        if (dueDate && dueDate.length === 2) {
            [dueDateBegin, dueDateEnd] = dueDate;
        }
        let insertTimeBegin, insertTimeEnd;
        if (insertTime && insertTime.length === 2) {
            [insertTimeBegin, insertTimeEnd] = insertTime;
        }
        let updateTimeBegin, updateTimeEnd;
        if (updateTime && updateTime.length === 2) {
            [updateTimeBegin, updateTimeEnd] = updateTime;
        }
        let paymentTimeBegin, paymentTimeEnd;
        if (paymentTime && paymentTime.length === 2) {
            [paymentTimeBegin, paymentTimeEnd] = paymentTime;
        }
        return super.get('/data', {
            selectOrgId,
            fullName,
            idCardNo,
            mobile,
            dueDate,
            partyDueStatus,
            dueDateBegin,
            dueDateEnd,
            insertTimeBegin,
            insertTimeEnd,
            updateTimeBegin,
            updateTimeEnd,
            paymentTimeBegin,
            paymentTimeEnd,
            paymentSn,
            externalPaymentSn,
            remarks,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    save({ id, dueDate, dueAmount, remarks } = {}) {
        return super.post('/save', {
            id,
            dueDate,
            dueAmount,
            remarks,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}
export default new PartyDue();
export { PartyDue };
