<!-- Created by henian.xu on 2019/8/23. -->

<template>
    <el-dropdown
        class="user-menu"
        @command="onCommand"
    >
        <span class="el-dropdown-link"> {{$$t('vmf_welcome','欢迎您')}}:{{ fullName }}<i class="el-icon-arrow-down el-icon--right" /> </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-if="user.id === 1"
                command="clearCache"
            >
                {{$$t('vmf_clear_cache','清除缓存')}}
            </el-dropdown-item>
            <el-dropdown-item
                command="modifyPassword"
            >
                {{$$t('vmf_change_password','修改密码')}}
            </el-dropdown-item>
            <el-dropdown-item
                divided
                command="logout"
            >
                {{$$t('vmf_exit','退出')}}
            </el-dropdown-item>
            <!--<el-dropdown-item divided>蚵仔煎</el-dropdown-item>-->
        </el-dropdown-menu>


        <el-dialog
            center
            :title="$$t('vmf_change_password','修改密码')"
            width="500px"
            :close-on-click-modal='modifyPasswordDialog.showClose'
            :close-on-press-escape='modifyPasswordDialog.showClose'
            :show-close='modifyPasswordDialog.showClose'
            :visible.sync="modifyPasswordDialog.isVisible"
        >
            <el-form
                ref="modifyPasswordForm"
                label-width="6em"
                :model="formModel"
                :rules="formRules"
            >
                <el-form-item
                    :label="`${$$t('vmf_old_password','旧密码')}:`"
                    prop="oldPassword"
                >
                    <el-input
                        type="password"
                        v-model="formModel.oldPassword"
                    />
                </el-form-item>
                <el-form-item
                    :label="`${$$t('vmf_new_password','新密码')}:`"
                    prop="password"
                >
                    <el-input
                        type="password"
                        v-model="formModel.password"
                    />
                </el-form-item>
                <el-form-item
                    :label="`${$$t('vmf_confirm_password','确认密码')}:`"
                    prop="confirmPassword"
                >
                    <el-input
                        type="password"
                        v-model="formModel.confirmPassword"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="()=>{onModifyPasswordDialogConfirm()}"
                >
                    {{ $$t('vmf_confirm', '确 定') }}
                </el-button>
                <el-button
                    v-if='modifyPasswordDialog.showClose'
                    size="small"
                    @click="modifyPasswordDialog.isVisible = false"
                >
                    {{ $$t('vmf_cancel', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </el-dropdown>
</template>

<script>
import { Utils } from 'vmf';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('User');

const showModifyPasswordDialogMixin = {
    computed: {
        showModifyPasswordDialog: {
            get() {
                return this.$utils.GlobalVar.showModifyPasswordDialog || null;
            },
            set(val) {
                this.$set(this.$utils.GlobalVar, 'showModifyPasswordDialog', val);
            },
        },
    },
    watch: {
        showModifyPasswordDialog: {
            handler(val) {
                if (!val) return;
                this.modifyPasswordDialog = {
                    showClose: false,
                    isVisible: true,
                };
            },
            immediate: true,
        },
    },
};

export default {
    name: 'UserMen',
    mixins: [showModifyPasswordDialogMixin],
    data() {
        return {
            modifyPasswordDialog: {
                isVisible: false,
            },
            formModel: {
                oldPassword: this.$utils.GlobalVar.isDev ? '123456' : '',
                password: this.$utils.GlobalVar.isDev ? '0123456' : '',
                confirmPassword: this.$utils.GlobalVar.isDev ? '0123456' : '',
            },
            formRules: {
                password: [
                    {
                        required: true,
                        message: this.$$t('vmf_can_not_be_empty', '不能为空'),
                    },
                    {
                        validator: this.checkPassword,
                    },
                ],
                oldPassword: {
                    required: true,
                    message: this.$$t('vmf_can_not_be_empty', '不能为空'),
                },
                confirmPassword: [
                    {
                        required: true,
                        message: this.$$t('vmf_can_not_be_empty', '不能为空'),
                    },
                    {
                        validator: this.againPassword,
                        message: this.$$t('vmf_password_error2', '两处密码不相等'),
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(['userData', 'fullName']),
        user() {
            return (this.userData || {}).user || {};
        },
    },
    methods: {
        ...mapActions({
            userLogout: 'logout',
            userModifyPassword: 'modifyPassword',
            userClearCache: 'clearCache',
        }),
        onCommand(command) {
            if (this[command]) this[command]();
        },
        async logout() {
            Utils.$bus.$emit('before-logout');
            await this.userLogout();
            Utils.$bus.$emit('logout');
        },
        async clearCache() {
            Utils.$bus.$emit('before-clear-cache');
            await this.userClearCache();
            Utils.$bus.$emit('clear-cache');
        },
        async checkPassword(rule, value, callback /* , source, options */) {
            const api = (this.$utils.GlobalVar.appConfig || {}).CHECKOUT_PASSWORD || '';
            if (!api) {
                callback();
                return;
            }
            const { success, msg } = await this.$http.post(
                api,
                {
                    password: value,
                },
                {
                    isHandleError: true,
                    isAxiosResponseData: true,
                },
            );
            if (!success) {
                callback(new Error(msg));
                return;
            }
            callback();
        },
        againPassword(rule, value, callback /* , source, options */) {
            if (value !== this.formModel.password) {
                callback(new Error(rule.message));
            } else {
                callback();
            }
        },
        modifyPassword() {
            this.modifyPasswordDialog = {
                showClose: true,
                isVisible: true,
            };
        },
        async onModifyPasswordDialogConfirm() {
            await this.$refs.modifyPasswordForm.validate();
            Utils.$bus.$emit('before-modify-password');
            await this.userModifyPassword(this.formModel);
            Utils.$bus.$emit('modify-password');
            this.modifyPasswordDialog.isVisible = false;
            this.showModifyPasswordDialog = null;
        },
    },
};
</script>

<style lang="scss">
</style>
