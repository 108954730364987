<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                >
                    <el-tabs
                        tab-position="left"
                        v-model="tabName"
                    >
                        <el-tab-pane
                            v-for="item in servantPaymentConfigList"
                            :key="item.id"
                            :label="item.paymentTypeName"
                            :name="item.paymentTypeName"
                        >
                            <el-col
                                :span="12"
                                v-if="item.id === 1"
                            >
                                <el-form-item
                                    prop="bankAccountName"
                                    label="银行开户名"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.bankAccountName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="bankAccountNo"
                                    label="银行账号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.bankAccountNo"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="depositBank"
                                    label="开户银行"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.depositBank"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="branchBankName"
                                    label="支行名称"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.branchBankName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="24"
                                v-if="item.id === 2"
                            >
                                <el-form-item
                                    prop="wxaccountAppid"
                                    label="公众号appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.wxaccountAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchid"
                                    label="支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.wxaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchkey"
                                    label="支付商户key"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.wxaccountMchkey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="certSerialNo"
                                    label="证书序列号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.certSerialNo"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="subWxaccountAppid"
                                    label="子公众号appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.subWxaccountAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="subWxaccountMchid"
                                    label="子支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.subWxaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <!--                                <el-form-item
                                                                    prop="withdrawWxaccountMchid"
                                                                    label="提现支付商户ID"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.withdrawWxaccountMchid"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="withdrawWxaccountMchkey"
                                                                    label="提现支付商户key"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.withdrawWxaccountMchkey"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isMergePay"
                                                                    label="是否合单支付"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isMergePay"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="paymentPoundageRate"
                                                                    label="手续费(%)"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="2"
                                                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="paymentPoundageCostRate"
                                                                    label="手续费成本(%)"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="2"
                                                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="exchangeRate"
                                                                    label="汇率"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="6"
                                                                        v-model.number="item.paymentConfig.exchangeRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    label="渠道商手续费"
                                                                    label-width="10em"
                                                                >
                                                                    <div class="ma-b">
                                                                        <el-button @click="onAdd(item)">
                                                                            新增
                                                                        </el-button>
                                                                    </div>
                                                                    <el-table
                                                                        stripe
                                                                        border
                                                                        size="small"
                                                                        :data="item.paymentConfig.externalPoundageAmountList"
                                                                    >
                                                                        <el-table-column label="付款渠道">
                                                                            <template slot-scope="scope">
                                                                                <el-input
                                                                                    v-model="scope.row.paymentChannel"
                                                                                    :maxlength="20"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column label="手续费比例(%)">
                                                                            <template slot-scope="scope">
                                                                                <el-input-number
                                                                                    controls-position="right"
                                                                                    :min="0"
                                                                                    :precision="2"
                                                                                    v-model.number="scope.row.poundageRate"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="操作"
                                                                            width="60"
                                                                        >
                                                                            <template slot-scope="scope">
                                                                                <el-button
                                                                                    size="small"
                                                                                    type="text"
                                                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                                                >
                                                                                    <span>删除</span>
                                                                                </el-button>
                                                                            </template>
                                                                        </el-table-column>
                                                                    </el-table>
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isCustomsClearance"
                                                                    label="是否海关报送"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isCustomsClearance"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsWxaccountAppid"
                                                                    label="报关公众号appid"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsWxaccountAppid"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsWxaccountMchid"
                                                                    label="报关商户ID"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsWxaccountMchid"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsWxaccountMchkey"
                                                                    label="报关商户key"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsWxaccountMchkey"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsPlace"
                                                                    label="海关编号"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsPlace"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="merchantCustomsName"
                                                                    label="商户海关备案名称"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.merchantCustomsName"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="merchantCustomsCode"
                                                                    label="商户海关备案编号"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.merchantCustomsCode"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isProfitSharing"
                                                                    label="是否分账"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isProfitSharing"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    label="分账配置"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isProfitSharing"
                                                                >
                                                                    <div class="ma-b">
                                                                        <el-button @click="onAdd1(item)">
                                                                            新增
                                                                        </el-button>
                                                                    </div>
                                                                    <el-table
                                                                        stripe
                                                                        border
                                                                        size="small"
                                                                        :data="item.paymentConfig.profitSharingConfig"
                                                                    >
                                                                        <el-table-column
                                                                            label="接收方类型"
                                                                            width="180">
                                                                            <template slot-scope="scope">
                                                                                <el-select
                                                                                    v-model="scope.row.type"
                                                                                    placeholder="请选择"
                                                                                    style="width: 150px"
                                                                                >
                                                                                    <el-option
                                                                                        label="商户号"
                                                                                        value="MERCHANT_ID"
                                                                                    />
                                                                                    <el-option
                                                                                        label="个人"
                                                                                        value="PERSONAL_OPENID"
                                                                                    />
                                                                                </el-select>
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column label="接收方信息">
                                                                            <template slot-scope="scope">
                                                                                <div>
                                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">商户号：</span>
                                                                                    <span v-else>个人openid：</span>
                                                                                    <el-input
                                                                                        v-model="scope.row.account"
                                                                                        :maxlength="64"
                                                                                        style="width: 300px"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    class="ma-t">
                                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">商户全称：</span>
                                                                                    <span v-else>个人姓名：</span>
                                                                                    <el-input
                                                                                        v-model="scope.row.name"
                                                                                        :maxlength="64"
                                                                                        style="width: 300px"
                                                                                    />
                                                                                </div>
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="分账比例(%)"
                                                                            width="150">
                                                                            <template slot-scope="scope">
                                                                                <el-input-number
                                                                                    controls-position="right"
                                                                                    :min="0"
                                                                                    :max="100"
                                                                                    :precision="2"
                                                                                    v-model.number="scope.row.rate"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="操作"
                                                                            width="60"
                                                                        >
                                                                            <template slot-scope="scope">
                                                                                <el-button
                                                                                    size="small"
                                                                                    type="text"
                                                                                    @click="onDelete1(scope.$index, item.paymentConfig.profitSharingConfig)"
                                                                                >
                                                                                    <span>删除</span>
                                                                                </el-button>
                                                                            </template>
                                                                        </el-table-column>
                                                                    </el-table>
                                                                </el-form-item>-->
                            </el-col>
                            <el-col
                                :span="24"
                                v-if="item.id === 4"
                            >
                                <el-form-item
                                    prop="mpaccountAppid"
                                    label="小程序appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.mpaccountAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchid"
                                    label="支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.mpaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchkey"
                                    label="支付商户key"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.mpaccountMchkey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="certSerialNo"
                                    label="证书序列号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.certSerialNo"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="subMpaccountMchid"
                                    label="子支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.subMpaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <!--                                <el-form-item
                                                                    prop="withdrawWxaccountMchid"
                                                                    label="提现支付商户ID"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.withdrawWxaccountMchid"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="withdrawWxaccountMchkey"
                                                                    label="提现支付商户key"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.withdrawWxaccountMchkey"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isMergePay"
                                                                    label="是否合单支付"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isMergePay"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="paymentPoundageRate"
                                                                    label="手续费(%)"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="2"
                                                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="paymentPoundageCostRate"
                                                                    label="手续费成本(%)"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="2"
                                                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="exchangeRate"
                                                                    label="汇率"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="6"
                                                                        v-model.number="item.paymentConfig.exchangeRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    label="渠道商手续费"
                                                                    label-width="10em"
                                                                >
                                                                    <div class="ma-b">
                                                                        <el-button @click="onAdd(item)">
                                                                            新增
                                                                        </el-button>
                                                                    </div>
                                                                    <el-table
                                                                        stripe
                                                                        border
                                                                        size="small"
                                                                        :data="item.paymentConfig.externalPoundageAmountList"
                                                                    >
                                                                        <el-table-column label="付款渠道">
                                                                            <template slot-scope="scope">
                                                                                <el-input
                                                                                    v-model="scope.row.paymentChannel"
                                                                                    :maxlength="20"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column label="手续费比例(%)">
                                                                            <template slot-scope="scope">
                                                                                <el-input-number
                                                                                    controls-position="right"
                                                                                    :min="0"
                                                                                    :precision="2"
                                                                                    v-model.number="scope.row.poundageRate"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="操作"
                                                                            width="60"
                                                                        >
                                                                            <template slot-scope="scope">
                                                                                <el-button
                                                                                    size="small"
                                                                                    type="text"
                                                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                                                >
                                                                                    <span>删除</span>
                                                                                </el-button>
                                                                            </template>
                                                                        </el-table-column>
                                                                    </el-table>
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isCustomsClearance"
                                                                    label="是否海关报送"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isCustomsClearance"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsWxaccountAppid"
                                                                    label="报关公众号appid"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsWxaccountAppid"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsWxaccountMchid"
                                                                    label="报关商户ID"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsWxaccountMchid"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsWxaccountMchkey"
                                                                    label="报关商户key"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsWxaccountMchkey"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsPlace"
                                                                    label="海关编号"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsPlace"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="merchantCustomsName"
                                                                    label="商户海关备案名称"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.merchantCustomsName"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="merchantCustomsCode"
                                                                    label="商户海关备案编号"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.merchantCustomsCode"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isProfitSharing"
                                                                    label="是否分账"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isProfitSharing"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    label="分账配置"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isProfitSharing"
                                                                >
                                                                    <div class="ma-b">
                                                                        <el-button @click="onAdd1(item)">
                                                                            新增
                                                                        </el-button>
                                                                    </div>
                                                                    <el-table
                                                                        stripe
                                                                        border
                                                                        size="small"
                                                                        :data="item.paymentConfig.profitSharingConfig"
                                                                    >
                                                                        <el-table-column
                                                                            label="接收方类型"
                                                                            width="180">
                                                                            <template slot-scope="scope">
                                                                                <el-select
                                                                                    v-model="scope.row.type"
                                                                                    placeholder="请选择"
                                                                                    style="width: 150px"
                                                                                >
                                                                                    <el-option
                                                                                        label="商户号"
                                                                                        value="MERCHANT_ID"
                                                                                    />
                                                                                    <el-option
                                                                                        label="个人"
                                                                                        value="PERSONAL_OPENID"
                                                                                    />
                                                                                </el-select>
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column label="接收方信息">
                                                                            <template slot-scope="scope">
                                                                                <div>
                                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">商户号：</span>
                                                                                    <span v-else>个人openid：</span>
                                                                                    <el-input
                                                                                        v-model="scope.row.account"
                                                                                        :maxlength="64"
                                                                                        style="width: 300px"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    class="ma-t">
                                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">商户全称：</span>
                                                                                    <span v-else>个人姓名：</span>
                                                                                    <el-input
                                                                                        v-model="scope.row.name"
                                                                                        :maxlength="64"
                                                                                        style="width: 300px"
                                                                                    />
                                                                                </div>
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="分账比例(%)"
                                                                            width="150">
                                                                            <template slot-scope="scope">
                                                                                <el-input-number
                                                                                    controls-position="right"
                                                                                    :min="0"
                                                                                    :max="100"
                                                                                    :precision="2"
                                                                                    v-model.number="scope.row.rate"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="操作"
                                                                            width="60"
                                                                        >
                                                                            <template slot-scope="scope">
                                                                                <el-button
                                                                                    size="small"
                                                                                    type="text"
                                                                                    @click="onDelete1(scope.$index, item.paymentConfig.profitSharingConfig)"
                                                                                >
                                                                                    <span>删除</span>
                                                                                </el-button>
                                                                            </template>
                                                                        </el-table-column>
                                                                    </el-table>
                                                                </el-form-item>-->
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 8"
                            >
                                <el-form-item
                                    prop="airwallexClientId"
                                    label="airwallexClientId"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.airwallexClientId"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="airwallexApiKey"
                                    label="airwallexApiKey"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.airwallexApiKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="airwallexSecretKey"
                                    label="airwallexSecretKey"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.airwallexSecretKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <!--                                <el-form-item
                                                                    prop="paymentPoundageRate"
                                                                    label="手续费(%)"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="2"
                                                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="paymentPoundageCostRate"
                                                                    label="手续费成本(%)"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="2"
                                                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="exchangeRate"
                                                                    label="汇率"
                                                                    label-width="10em"
                                                                >
                                                                    <el-input-number
                                                                        controls-position="right"
                                                                        :min="0"
                                                                        :precision="6"
                                                                        v-model.number="item.paymentConfig.exchangeRate"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    label="渠道商手续费"
                                                                    label-width="10em"
                                                                >
                                                                    <div class="ma-b">
                                                                        <el-button @click="onAdd(item)">
                                                                            新增
                                                                        </el-button>
                                                                    </div>
                                                                    <el-table
                                                                        stripe
                                                                        border
                                                                        size="small"
                                                                        :data="item.paymentConfig.externalPoundageAmountList"
                                                                    >
                                                                        <el-table-column label="付款渠道">
                                                                            <template slot-scope="scope">
                                                                                <el-input
                                                                                    v-model="scope.row.paymentChannel"
                                                                                    :maxlength="20"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column label="手续费比例(%)">
                                                                            <template slot-scope="scope">
                                                                                <el-input-number
                                                                                    controls-position="right"
                                                                                    :min="0"
                                                                                    :precision="2"
                                                                                    v-model.number="scope.row.poundageRate"
                                                                                />
                                                                            </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                            label="操作"
                                                                            width="60"
                                                                        >
                                                                            <template slot-scope="scope">
                                                                                <el-button
                                                                                    size="small"
                                                                                    type="text"
                                                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                                                >
                                                                                    <span>删除</span>
                                                                                </el-button>
                                                                            </template>
                                                                        </el-table-column>
                                                                    </el-table>
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="isCustomsClearance"
                                                                    label="是否海关报送"
                                                                    label-width="10em"
                                                                >
                                                                    <el-switch
                                                                        v-model="item.paymentConfig.isCustomsClearance"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="customsPlace"
                                                                    label="海关编号"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.customsPlace"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="merchantCustomsName"
                                                                    label="商户海关备案名称"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.merchantCustomsName"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>
                                                                <el-form-item
                                                                    prop="merchantCustomsCode"
                                                                    label="商户海关备案编号"
                                                                    label-width="10em"
                                                                    v-if="item.paymentConfig.isCustomsClearance"
                                                                >
                                                                    <el-input
                                                                        v-model="item.paymentConfig.merchantCustomsCode"
                                                                        auto-complete="off"
                                                                    />
                                                                </el-form-item>-->
                            </el-col>
                        </el-tab-pane>
                    </el-tabs>
                </el-form>
            </el-col>
            <div class="pa-t">
                <el-button
                    size="small"
                    type="primary"
                    @click="onConfirm()"
                >
                    保 存
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                    v-show="servantId != null"
                >
                    返 回
                </el-button>
            </div>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ServantPaymentConfig',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            servantPaymentConfigList: [],
            servantId: '',
            tabName: '',
        };
    },
    methods: {
        onConfirm() {
            this.$api.Sv.ServantPaymentConfig.save({
                servantId: this.servantId,
                configJson: JSON.stringify(this.servantPaymentConfigList),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        initServantPaymentConfig() {
            this.$api.Sv.ServantPaymentConfig.data({ servantId: this.servantId }).then(json => {
                const res = json.data.data;
                res.forEach(item => {
                    if (!item.paymentConfig) {
                        item.paymentConfig = {
                            externalPoundageAmountList: [],
                            profitSharingConfig: [],
                        };
                    } else {
                        item.paymentConfig = JSON.parse(item.paymentConfig);
                        if (!item.paymentConfig.externalPoundageAmountList) {
                            item.paymentConfig.externalPoundageAmountList = [];
                        }
                        if (!item.paymentConfig.profitSharingConfig) {
                            item.paymentConfig.profitSharingConfig = [];
                        }
                    }
                });
                this.tabName = res[0].paymentTypeName;
                this.servantPaymentConfigList = res;
            });
        },
        onAdd(item) {
            item.paymentConfig.externalPoundageAmountList.push({
                paymentChannel: '',
                poundageRate: 0,
            });
        },
        onDelete(index, row) {
            row.splice(index, 1);
        },
        onAdd1(item) {
            item.paymentConfig.profitSharingConfig.push({
                type: 'MERCHANT_ID',
            });
        },
        onDelete1(index, row) {
            row.splice(index, 1);
        },
    },
    created() {
        this.servantId = this.$route.params.id;
        this.initServantPaymentConfig();
    },
};
</script>

<style lang="scss">
</style>
