<!-- Created by henian.xu on 2018/7/20. -->

<template>
  <page>
    <!-- 表单区 -->
    <template slot="secondHeader">
      <el-tabs
        class="classify-tabs"
        v-model="queryFormModel.type"
        type="card"
        @tab-click="onQuery()"
      >
        <el-tab-pane label="文字" name="1" />
        <el-tab-pane label="图片" name="2" />
        <el-tab-pane label="导航链接" name="3" />
        <el-tab-pane label="富文本" name="4" />
      </el-tabs>
      <el-form
        ref="queryForm"
        class="second-header npa-b"
        size="small"
        :inline="true"
        :model="queryFormModel"
      >
        <el-form-item prop="portalInfoName" label="字段名称">
          <el-input
            v-model="queryFormModel.portalInfoName"
            placeholder="请输入字段名称"
          />
        </el-form-item>
        <!--<el-form-item
            prop="portalInfoKey"
            label="门户信息key"
        >
            <el-input
                v-model="queryFormModel.portalInfoKey"
                placeholder="请输入门户信息key"
            />
        </el-form-item>-->
        <el-form-item prop="isAvailable" label="状态">
          <el-select v-model="queryFormModel.isAvailable" placeholder="请选择">
            <el-option label="全部" value="" />
            <el-option label="启用" value="1" />
            <el-option label="禁用" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onQuery()"> 查询</el-button>
          <el-button type="success" @click="onReset"> 重置</el-button>
        </el-form-item>
      </el-form>
    </template>

    <!-- 内容 -->
    <div class="body" slot="body" v-loading="querying">
      <!-- 表格 -->
      <el-table
        stripe
        border
        size="small"
        :data="tableData"
        style="width: 100%"
        @selection-change="onTableSelectionChange"
      >
        <el-table-column type="selection" width="39" />
        <el-table-column
          prop="portalInfoTypeName"
          label="字段类型"
          min-width="100"
        />
        <el-table-column
          prop="portalInfoName"
          label="字段名称"
          min-width="100"
        />
        <el-table-column
          prop="imageSizeRequire"
          label="图片尺寸要求"
          min-width="100"
          v-if="+queryFormModel.type === 2"
        />
        <!--<el-table-column
            prop="portalInfoKey"
            label="门户信息key"
            min-width="100"
        />-->
        <el-table-column
          prop="portalInfoValue"
          label="参数值"
          min-width="200"
          v-if="+queryFormModel.type === 1 || +queryFormModel.type === 3"
          :key="Math.random()"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.portalInfoValue"
              v-if="queryFormModel.type == 1"
              @change="changeValue(scope)"
            />
            <span v-if="queryFormModel.type == 3">{{
              scope.row.portalInfoValue
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" min-width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isAvailable"
              @input="onIsAvailableInput($event, scope)"
              disabled
              v-if="!scope.row.id"
            />
            <el-switch
              v-model="scope.row.isAvailable"
              @input="onIsAvailableInput($event, scope)"
              v-if="scope.row.id"
            />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="90">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="onTableEdit(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="ma-t ta-r">
        <pagination v-model="pagination" @input="onQuery" />
      </div>
    </div>

    <!-- 弹框 -->
    <!-- 新增/编辑 -->
    <el-dialog
      :title="addDialog.formModel.id ? '编辑' : '新增'"
      center
      width="1200px"
      :visible.sync="addDialog.isVisible"
      @closed="onAddDialogCancel"
    >
      <el-form
        ref="addForm"
        size="small"
        :model="addDialog.formModel"
        :rules="addDialog.formRules"
      >
        <el-form-item prop="portalInfoType" label="字段类型" label-width="8em">
          <dictionaries-picker
            type="portalInfoType"
            v-model="addDialog.formModel.portalInfoType"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item prop="portalInfoName" label="字段名称" label-width="8em">
          <el-input
            v-model="addDialog.formModel.portalInfoName"
            auto-complete="off"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          prop="imageSizeRequire"
          label="图片尺寸要求"
          label-width="8em"
          v-if="addDialog.formModel.portalInfoType === 2"
        >
          <el-input
            v-model="addDialog.formModel.imageSizeRequire"
            auto-complete="off"
            :disabled="true"
          />
        </el-form-item>
        <!--<el-form-item
            prop="portalInfoKey"
            label="门户信息key"
            label-width="8em"
        >
            <el-input
                v-model="addDialog.formModel.portalInfoKey"
                auto-complete="off"
                :disabled="true"
            />
        </el-form-item>-->
        <!--普通参数-->
        <el-form-item
          prop="portalInfoValue"
          label="参数值"
          label-width="8em"
          v-if="
            addDialog.formModel.portalInfoType === 1 ||
            addDialog.formModel.portalInfoType === 3
          "
        >
          <el-input
            v-model="addDialog.formModel.portalInfoValue"
            auto-complete="off"
          />
        </el-form-item>
        <!--富文本-->
        <el-form-item
          prop="text"
          label="参数值"
          label-width="8em"
          v-if="addDialog.formModel.portalInfoType === 4"
        >
          <Tinymce
            :height="500"
            image-action="/rs/attachment/uploadFileLibrary"
            v-model="addDialog.formModel.text"
            image-upload-tips="图片大小不能超过5M，格式支持jpg、png、bmp"
          />
        </el-form-item>
        <el-form-item
          label="信息"
          label-width="8em"
          v-if="addDialog.formModel.portalInfoType === 2"
        >
          <div class="ma-b">
            <el-button-group>
              <el-button
                type="success"
                icon="el-icon-plus"
                size="small"
                @click="onAddRow"
                v-if="
                  !addDialog.formModel.isMultipleImage && rowDataList.length < 1
                "
              >
                新增一行
              </el-button>
              <el-button
                type="success"
                icon="el-icon-plus"
                size="small"
                @click="onAddRow"
                v-if="
                  addDialog.formModel.isMultipleImage && rowDataList.length >= 0
                "
              >
                新增一行
              </el-button>
            </el-button-group>
          </div>
          <!-- 表格 -->
          <el-table
            stripe
            border
            size="small"
            :data="rowDataList"
            style="width: 100%"
          >
            <el-table-column prop="number" label="序号" min-width="100">
              <template slot-scope="scope">
                <el-input-number
                  controls-position="right"
                  :min="1"
                  :max="255"
                  v-model.number="scope.row.number"
                />
              </template>
            </el-table-column>
            <el-table-column prop="imageArr" label="图片" min-width="100">
              <template slot-scope="scope">
                <upload
                  :validate-md5="true"
                  action="/rs/attachment/uploadFileLibrary"
                  :limit="1"
                  v-model="scope.row.imageArr"
                  tips=""
                  :oss-action="$globalVar.appConfig.OSS_ACTION"
                />
              </template>
            </el-table-column>
            <el-table-column prop="text" label="文字" min-width="100">
              <template slot-scope="scope">
                <el-input v-model="scope.row.text" />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="链接" min-width="100">
              <template slot-scope="scope">
                <el-input v-model="scope.row.address" />
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="50">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="onDeleteRow(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="onAddDialogConfirm">
          确 定
        </el-button>
        <el-button size="small" @click="onAddDialogCancel"> 取 消</el-button>
      </div>
    </el-dialog>
  </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
  name: 'PortalInfoConfig',
  mixins: [pagesMixin, queryFormList],
  data() {
    return {
      queryFormModel: {
        type: '1', // 页签类型
        portalInfoName: '',
        portalInfoType: '',
        portalInfoKey: '',
        isAvailable: '',
      },
      // 新增/编辑
      addDialog: {
        // 对话框显示
        isVisible: false,
        // 表单数据
        formModel: {
          id: '',
          portalInfoType: '',
          portalInfoName: '',
          imageSizeRequire: '',
          isMultipleImage: true,
          portalInfoKey: '',
          portalInfoValue: '',
          isAvailable: true,
          // 普通参数
          text: '',
        },
        // 表单校验规则
        formRules: {
          // 普通参数
          text: {
            required: true,
            message: '请输入参数值',
            trigger: 'blur',
          },
        },
      },
      // 表格数据
      tableData: [],
      // 表格当前选中行
      currentTableSelect: [],
      // 信息
      rowDataList: [],
    };
  },
  methods: {
    /* -- 方法 -- */
    // 请求列表数据
    getListData(queryData) {
      return this.$api.Pt.PortalInfoConfig.data(queryData).then((json) => {
        const res = json.data;
        this.tableData = res.data.map((item) => {
          if (
            item.portalInfoValue !== undefined &&
            +this.queryFormModel.type === 4
          ) {
            item.portalInfoValue = JSON.parse(item.portalInfoValue).text;
          }
          return item;
        });
        this.pagination = res.pagination;
        return Promise.resolve(json);
      });
    },
    /* -- 事件 -- */
    onTableSelectionChange(val) {
      this.currentTableSelect = val;
    },
    onTableEdit(row) {
      const { formModel } = this.addDialog;
      this.$api.Pt.PortalInfoConfig.getDetail({ id: row.id }).then((json) => {
        const res = json.data.data.portalInfoConfig;
        this.$utils.formModelMerge(formModel, res);
        if (res.portalInfoValue != null && res.portalInfoValue !== '') {
          if (+res.portalInfoType !== 1) {
            const valueObject = JSON.parse(res.portalInfoValue);
            if (+res.portalInfoType === 4) {
              formModel.text = valueObject.text;
            }
            if (+res.portalInfoType === 2 || +res.portalInfoType === 3) {
              this.rowDataList = valueObject.sort(this.sortRowList);
            }
          }
        } else {
          if (+res.portalInfoType === 4) {
            formModel.text = '';
          }
          if (+res.portalInfoType === 2 || +res.portalInfoType === 3) {
            this.rowDataList = [];
          }
        }
      });
      this.addDialog.isVisible = true;
    },
    onAddDialogConfirm() {
      const { addForm } = this.$refs;
      addForm.validate().then(() => {
        // 普通参数
        if (+this.addDialog.formModel.portalInfoType === 4) {
          const valueObject = {
            text: this.addDialog.formModel.text,
          };
          this.addDialog.formModel.portalInfoValue = JSON.stringify(
            valueObject,
          );
        }
        // 广告位图片&导航链接
        if (+this.addDialog.formModel.portalInfoType === 2) {
          if (this.rowDataList.length !== 0) {
            this.rowDataList = this.rowDataList.map((item) => {
              if (item.imageArr[0] != null) {
                item.image = item.imageArr[0].name;
              }
              if (item.address === '') {
                item.address = '#';
              }
              return item;
            });
            this.addDialog.formModel.portalInfoValue = JSON.stringify(
              this.rowDataList,
            );
          } else {
            this.addDialog.formModel.portalInfoValue = null;
          }
        }
        this.$api.Pt.PortalInfoConfig.save({
          ...this.addDialog.formModel,
        }).then((json) => {
          const res = json.data;
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.onQuery(this.pagination);
          this.addDialog.isVisible = false;
        });
      });
    },
    onAddDialogCancel() {
      const { addForm } = this.$refs;
      addForm.resetFields();
      const { formModel } = this.addDialog;
      formModel.id = '';
      this.addDialog.isVisible = false;
    },
    onIsAvailableInput(val, scope) {
      const { row } = scope;
      if (!row) return;
      this.$api.Pt.PortalInfoConfig.status({
        id: row.id,
        isAvailable: row.isAvailable,
      })
        .then((json) => {
          const res = json.data;
          this.$message({
            message: res.msg,
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            message: '操作失败请重试！',
            type: 'error',
          });
          row.isAvailable = !row.isAvailable;
        });
    },
    onAddRow() {
      const list = {
        number: this.rowDataList.length + 1,
        imageArr: [],
        image: '',
        text: '',
        address: '',
      };
      this.rowDataList.push(list);
    },
    onDeleteRow(row) {
      this.rowDataList.splice(this.rowDataList.indexOf(row), 1);
    },
    sortRowList(a, b) {
      return a.orderNum - b.orderNum;
    },
    changeValue(scope) {
      const { row } = scope;
      if (!row) return;
      this.$api.Pt.PortalInfoConfig.changeValue({
        id: row.id,
        portalInfoValue: row.portalInfoValue,
      })
        .then((json) => {
          const res = json.data;
          this.$message({
            message: res.msg,
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            message: '操作失败请重试！',
            type: 'error',
          });
          row.isAvailable = !row.isAvailable;
        });
    },
  },
};
</script>

<style lang="scss"></style>
