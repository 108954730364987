/**
 * Created by henian.xu on 2019/11/25.
 *
 */

import { VueConstructor } from 'vue';
import * as Utils from '@vmf/shared';

export { Utils };
export default (vue: VueConstructor) => {
    Object.defineProperty(vue, '$utils', {
        get(): typeof Utils {
            return Utils;
        },
    });
    Object.defineProperty(vue.prototype, '$utils', {
        get(): typeof Utils {
            return Utils;
        },
    });
};

declare module 'vue/types/vue' {
    export interface Vue {
        readonly $utils?: typeof Utils;
    }
}
