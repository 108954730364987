<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基础信息</span>
                </div>
                <div class="item">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                prop="deviceType"
                                label="设备类型："
                                label-width="9em"
                            >
                                {{detail.deviceTypeName}}
                            </el-form-item>
                            <el-form-item
                                prop="itemName"
                                label="项目名称："
                                label-width="9em"
                            >
                                {{detail.itemName}}
                            </el-form-item>
                            <el-form-item
                                prop="itemSn"
                                label="项目编号："
                                label-width="9em"
                            >
                                {{detail.itemSn}}
                            </el-form-item>
                            <el-form-item
                                prop="isAvailable"
                                label="是否可用："
                                label-width="9em"
                            >
                                {{detail.isAvailable?"是":"否"}}
                            </el-form-item>
                            <el-form-item
                                prop="itemDesc"
                                label="项目描述："
                                label-width="9em"
                            >
                                <div
                                    class="editor-content rich-text-content"
                                    v-html="detail.itemDesc"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="remarks"
                                label="备注："
                                label-width="9em"
                            >
                                {{detail.remarks}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>体检项目详情</span>
                </div>
                <div class="item">
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="detail.detailList"
                        style="width: 100%"
                        default-expand-all
                    >
                        <el-table-column
                            type="expand"
                        >
                            <template v-slot="scope">
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    style="width: 95%"
                                    :data="scope.row.rangeList"
                                    v-if="scope.row.rangeList && +scope.row.detailRangeType === 2"
                                >
                                    <el-table-column
                                        prop="rangeBegin"
                                        label="开始值"
                                        width="155"
                                    >
                                        <template v-slot="range">
                                            {{range.row.rangeBegin}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="rangeEnd"
                                        label="结束值"
                                        width="155"
                                    >
                                        <template v-slot="range">
                                            {{range.row.rangeEnd}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultName"
                                        label="结果名称"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.resultName}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultInfo"
                                        label="结果描述"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.resultInfo}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="suggestion"
                                        label="建议"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.suggestion}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAbnormal"
                                        label="是否异常"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            {{range.row.isAbnormal?"是":"否"}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            {{range.row.isAvailable?"是":"否"}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    style="width: 95%"
                                    :data="scope.row.rangeList"
                                    v-if="scope.row.rangeList && +scope.row.detailRangeType === 3"
                                >
                                    <el-table-column
                                        prop="rangeValue"
                                        label="评定值"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{ range.row.rangeValue }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultName"
                                        label="结果名称"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.resultName}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultInfo"
                                        label="结果描述"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.resultInfo}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="suggestion"
                                        label="建议"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.suggestion}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAbnormal"
                                        label="是否异常"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            {{range.row.isAbnormal?"是":"否"}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            {{range.row.isAvailable?"是":"否"}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    style="width: 95%"
                                    :data="scope.row.rangeList"
                                    v-if="scope.row.rangeList && (+scope.row.detailRangeType === 4 || +scope.row.detailRangeType === 5)"
                                >
                                    <el-table-column
                                        prop="resultName"
                                        label="结果名称"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.resultName}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resultInfo"
                                        label="结果描述"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.resultInfo}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="suggestion"
                                        label="建议"
                                        min-width="100"
                                    >
                                        <template v-slot="range">
                                            {{range.row.suggestion}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAbnormal"
                                        label="是否异常"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            {{range.row.isAbnormal?"是":"否"}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="isAvailable"
                                        label="是否可用"
                                        width="80"
                                    >
                                        <template v-slot="range">
                                            {{range.row.isAvailable?"是":"否"}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailName"
                            label="名称"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                {{scope.row.detailName}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailSn"
                            label="编号"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                {{scope.row.detailSn}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailDesc"
                            label="描述"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                {{scope.row.detailDesc}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="unitName"
                            label="单位名称"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                {{scope.row.unitName}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailRangeType"
                            label="评定范围类型"
                            width="155"
                        >
                            <template v-slot="scope">
                                {{scope.row.detailRangeTypeName}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailRangeDesc"
                            label="评定范围描述"
                            width="180"
                        >
                            <template v-slot="scope">
                                {{scope.row.detailRangeDesc}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="isAvailable"
                            label="是否可用"
                            width="80"
                        >
                            <template v-slot="scope">
                                {{scope.row.isAvailable?"是":"否"}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="detailDesc"
                            label="描述"
                            min-width="100"
                        >
                            <template v-slot="scope">
                                {{scope.row.detailDesc}}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </el-form>

        <div
            class="ma-t"
            style="text-align: center"
        >
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>

    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'PhysicalExamItemView',
    mixins: [pagesMixin],
    data() {
        return {
            id: '',
            detail: {},
        };
    },
    methods: {
        init() {
            this.$api.Pe.PhysicalExamItem.getDetail({
                id: this.id,
            }).then(json => {
                const res = json.data;
                this.detail = res.data;
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
