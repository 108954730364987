<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='male-to-female-ratio'>
        <v-chart
            v-if='isMounted'
            class="echarts"
            :option="echartsOptions"
            :theme='echartsTheme'
            autoresize
        />
        <!--        <ECharts
            class="echarts"
            ref='echarts'
            :theme='echartsTheme'
            auto-resize
            :options="echartsOptions"></ECharts>-->
    </div>
</template>

<script>
import echartsTheme from './echartsTheme/customize';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import { rsLargeScreenMixin } from 'pages/largeScreen/store';
use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
    name: 'MaleToFemaleRatio',
    mixins: [rsLargeScreenMixin],
    components: { VChart },
    data() {
        return {
            echartsTheme,
            isMounted: false,
        };
    },
    computed: {
        echartsOptions() {
            const { maleFemaleRatio } = this;
            return {
                /*tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                },*/

                legend: {
                    // orient: 'vertical',
                    // left: 10,
                    data: ['男性党员', '女性党员'],
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['50%', '60%'],
                        center: ['50%', '55%'],
                        // avoidLabelOverlap: false,
                        label: {
                            formatter: '{b}\n{aa|{c}人}',
                            rich: {
                                aa: {
                                    color: '#fff',
                                    fontSize: 16,
                                },
                            },
                            // show: false,
                            // position: 'center',
                        },
                        /*labelLine: {
                            show: false,
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },*/
                        data: [
                            { value: maleFemaleRatio.maleNum, name: '男性党员' },
                            { value: maleFemaleRatio.femaleNum, name: '女性党员' },
                            // { value: 10, name: '男性党员' },
                            // { value: 20, name: '女性党员' },
                        ],
                    },
                ],
            };
        },
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, 200);
    },
};
</script>

<style lang='scss'>
.male-to-female-ratio {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: $padding;
    > .echarts {
        flex: 1 1 1%;
        width: 100%;
        height: 100%;
    }
}
</style>
