<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div :class='["progress-bar",theme]'>
        <div class='inner'>
            <div
                :style='barStyles'
                class='bar'></div>
            <div class='label'>{{value}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    data() {
        return {};
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        maxValue: {
            type: Number,
            default: 100,
        },
        theme: {
            type: String,
            default: '',
        },
    },
    computed: {
        progress() {
            const { value, maxValue } = this;
            if (!value) return 0;
            return (value / maxValue) * 100;
        },
        barStyles() {
            const { progress } = this;
            const val = Math.max(Math.min(progress, 100), 0);
            return {
                width: `${val}%`,
            };
        },
    },
};
</script>

<style lang='scss'>
.progress-bar {
    > .inner {
        position: relative;
        //background-color: #e4423f;
        border: 1px solid #e4423f;
        height: 20px;
        padding: 2px;
        > .bar {
            height: 100%;
            background-image: linear-gradient(to right, #54140c, #e4423f);
            mask-image: url('./images/mask2.png');
            mask-repeat: no-repeat;
            mask-size: 100% 100%;
        }

        > .label {
            position: absolute;
            top: 50%;
            right: $padding;
            transform: translateY(-50%);
            font-size: 14px;
            text-align: right;
        }
    }

    &.secondary {
        > .inner {
            border: 1px solid #e29735;
            > .bar {
                background-image: linear-gradient(to right, #725000, #e29735);
            }
        }
    }
}
</style>
