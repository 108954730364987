var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_c('template',{slot:"secondHeader"},[_c('el-form',{ref:"queryForm",staticClass:"second-header npa-b",attrs:{"size":"small","inline":true,"model":_vm.queryFormModel}},[_c('el-form-item',{attrs:{"prop":"scaleName","label":"量表名称"}},[_c('el-input',{attrs:{"placeholder":"请输入量表名称"},model:{value:(_vm.queryFormModel.scaleName),callback:function ($$v) {_vm.$set(_vm.queryFormModel, "scaleName", $$v)},expression:"queryFormModel.scaleName"}})],1),_c('el-form-item',{attrs:{"prop":"scaleSn","label":"量表编码"}},[_c('el-input',{attrs:{"placeholder":"请输入量表编码"},model:{value:(_vm.queryFormModel.scaleSn),callback:function ($$v) {_vm.$set(_vm.queryFormModel, "scaleSn", $$v)},expression:"queryFormModel.scaleSn"}})],1),_c('el-form-item',{attrs:{"prop":"isAvailable","label":"是否可用"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.queryFormModel.isAvailable),callback:function ($$v) {_vm.$set(_vm.queryFormModel, "isAvailable", $$v)},expression:"queryFormModel.isAvailable"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_c('el-option',{attrs:{"label":"是","value":"1"}}),_c('el-option',{attrs:{"label":"否","value":"0"}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onQuery()}}},[_vm._v(" 查询 ")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.querying),expression:"querying"}],staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"ma-b"},[_c('el-button-group',[_c('el-button',{attrs:{"type":"success","icon":"el-icon-plus","size":"small"},on:{"click":function($event){return _vm.$router.push(
                            '/am/scale/healthyScale/edit?scaleType=' +
                                _vm.queryFormModel.scaleType
                        )}}},[_vm._v(" 新增 ")])],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"stripe":"","border":"","size":"small","data":_vm.tableData},on:{"selection-change":_vm.onTableSelectionChange}},[_c('el-table-column',{attrs:{"prop":"scaleName","label":"量表名称","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"scaleSn","label":"量表编码","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"testMinutes","label":"测试时长（分钟）","width":"120"}}),_c('el-table-column',{attrs:{"prop":"orderNum","label":"排序号","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"isAvailable","label":"是否可用","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.isAvailable ? '是' : '否')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","width":"135"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("moment")(scope.row.insertTime,'YYYY-MM-DD HH:mm:ss'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"修改时间","width":"135"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("moment")(scope.row.updateTime,'YYYY-MM-DD HH:mm:ss'))+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push(
                                    '/am/scale/healthyScale/edit/' +
                                        scope.row.id +
                                        '?scaleType=' +
                                        _vm.queryFormModel.scaleType +
                                        '&type=1'
                                )}}},[_vm._v(" 编辑量表 ")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push(
                                    '/am/scale/healthyScale/edit/' +
                                        scope.row.id +
                                        '?scaleType=' +
                                        _vm.queryFormModel.scaleType +
                                        '&type=2'
                                )}}},[_vm._v(" 编辑题目 ")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push(
                                    '/am/scale/healthyScale/factor/' + scope.row.id
                                )}}},[_vm._v(" 编辑因子 ")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onTableDelete(scope.row.id)}}},[_vm._v(" 删除 ")])],_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push('/am/scale/healthyScale/view/' + scope.row.id)}}},[_vm._v(" 查看详情 ")])]}}])})],1),_c('div',{staticClass:"ma-t ta-r"},[_c('pagination',{on:{"input":_vm.onQuery},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }