/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Utils } from 'vmf';
import { Pt } from './Pt';

const { GlobalVar } = Utils;

class PortalMenu extends Pt {
    constructor() {
        super();
        this.baseUrl += '/portalMenu';
    }

    data({ pId, portalMenuType, name, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            portalMenuType,
            name,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { portalMenuImage } = json.data.data;
            if (!portalMenuImage) {
                json.data.data.portalMenuImage = [];
            } else {
                json.data.data.portalMenuImage = portalMenuImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        parentId,
        name,
        orderNum,
        isAvailable,
        portalMenuImage,
        portalMenuType,
        linkUrl,
        textColor,
        portalMenuDesc,
        isShowMiniApp,
        isShowMiniAppService,
    } = {}) {
        const portalMenuImageStr = portalMenuImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            parentId,
            name,
            orderNum,
            isAvailable,
            portalMenuImage: portalMenuImageStr,
            portalMenuType,
            linkUrl,
            textColor,
            portalMenuDesc,
            isShowMiniApp,
            isShowMiniAppService,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name, portalMenuType } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
            portalMenuType,
        });
    }
}

export default new PortalMenu();
export { PortalMenu };
