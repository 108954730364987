/**
 * Created by henian.xu on 2020/5/22.
 *
 */

export const NOOP = () => {};
export const getType = (obj: any): string => Object.prototype.toString.call(obj).slice(8, -1);
export const { isArray } = Array;
export const isFunction = (val: any): val is Function => typeof val === 'function';
export const isString = (val: any): val is string => typeof val === 'string';
export const isBoolean = (val: any): val is string => typeof val === 'boolean';
export const isObject = (val: any): val is Record<any, any> => val !== null && typeof val === 'object';
// const urlReg = new RegExp(/[a-zA-z]+:\/\/[^\s]*/);
export const isUrl = (url: string): boolean => /[a-zA-z]+:\/\/[^\s]*/.test(url);
export function assert(condition: any, msg: string, module: string = 'vmf'): void {
    if (!condition) throw new Error(`[${module}] ${msg}`);
}
export const isDef = (val: any): boolean => val !== undefined;
export const getUniqueId = (() => {
    let autoIncrement = 0;
    return (prefix = '') => {
        autoIncrement += 1;
        const cDate = new Date().getTime();
        const offDate = new Date(2010, 1, 1).getTime();
        const offset = cDate - offDate;
        return prefix + parseFloat(`${offset}`).toString(16) + autoIncrement;
    };
})();
export function getKey() {
    // const t  = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    const t = 'xxxxxxxx';
    return t.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/**
 * 检查对象是否具有该属性。
 */
export const hasOwn = (() => {
    const { hasOwnProperty } = Object.prototype;
    return (obj: object, key: PropertyKey) => hasOwnProperty.call(obj, key);
})();

/**
 * 是否 VNode
 * @param node
 */
export const isVNode = (node: any) => node !== null && typeof node === 'object' && hasOwn(node, 'componentOptions');

/**
 * 防抖
 * (与underscore 不同的是 immediate 为 true 时仅仅只是第一次触发会立即调用，
 * 而 underscore immediate 为 true 时会把调用前置，既:触发会立即调用且在 delay 内再触发触发的不调用。
 * 与 underscore 的不同导致不会严格按照 delay 时间间隔调用，因为正好在 delay 调用后触发会立即调用)
 * @param func
 * @param delay
 * @param immediate
 */
export function debounce(func: Function, delay = 200, immediate = false) {
    let timeout: any = null;
    function debounced(this: any, ...args: any[]) {
        let callback: Function;
        const promise = new Promise(resolve => {
            callback = resolve;
        });
        if (timeout) clearTimeout(timeout);
        if (immediate) {
            const callNow = !timeout;
            // 如果 timeout 为 false 立即调用，侧 setTimeout 就无需重复调用，否侧相反
            timeout = setTimeout(
                (now: boolean) => {
                    timeout = null;
                    if (now) callback(func.apply(this, args));
                },
                delay,
                !callNow,
            );
            if (callNow) callback!(func.apply(this, args));
        } else {
            timeout = setTimeout(() => callback(func.apply(this, args)), delay);
        }
        return promise;
    }

    debounced.cancel = () => {
        clearTimeout(timeout);
        timeout = null;
    };

    return debounced;
}

/**
 * 节流
 * underscore的实现
 * @param func
 * @param delay
 * @param options
 */
export function throttle(
    func: Function,
    delay = 200,
    options: { leading?: boolean; trailing?: boolean } = { leading: true, trailing: true },
) {
    let timeout: any;
    let context: any;
    let _args: any[] | null;
    let previous = 0;
    if (!options) options = {};

    const later = (resolve: Function) => {
        previous = options.leading === false ? 0 : new Date().getTime();
        timeout = null;
        resolve(func.apply(context, _args));
        if (!timeout) {
            context = null;
            _args = null;
        }
    };

    function throttled(this: any, ...args: any[]) {
        let callback: Function;
        const promise = new Promise(resolve => {
            callback = resolve;
        });
        const now = new Date().getTime();
        if (!previous && options.leading === false) previous = now;
        const remaining = delay - (now - previous);
        context = this;
        _args = args;
        if (remaining <= 0 || remaining > delay) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            callback!(func.apply(context, _args));
            if (!timeout) {
                context = null;
                _args = null;
            }
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining, callback!);
        }
        return promise;
    }

    throttled.cancel = () => {
        clearTimeout(timeout);
        previous = 0;
        timeout = null;
        context = null;
        _args = null;
    };

    return throttled;
}

/**
 * 整合异步
 * @param func
 */
export function integrateAsync<T extends (...args: any) => any>(func: T) {
    const promisesMap: {
        [k: string]: {
            resolve: (value: ReturnType<T>) => void;
            reject: (value: ReturnType<T>) => void;
        }[];
    } = {};
    return async function integrate(this: any, ...args: Parameters<T>) {
        let options = [...Array.from(args)].pop() as {
            groupKey?: string;
        };
        if (!isObject(options)) options = {};
        const groupKey = `${options.groupKey || 'default'}`;
        const promises = promisesMap[groupKey];
        if (promises) {
            return new Promise<ReturnType<T>>((resolve, reject) => {
                promises.push({ resolve, reject });
            });
        }
        promisesMap[groupKey] = [];
        let res: ReturnType<T> | Promise<ReturnType<T>> | null = null;
        try {
            res = await func.apply(this, args);
        } catch (e) {
            res = Promise.reject(e);
        }
        promisesMap[groupKey].forEach(({ resolve, reject }) => {
            if (res instanceof Promise) {
                res.then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            } else {
                resolve(res as ReturnType<T>);
            }
        });
        delete promisesMap[groupKey];
        return res;
    };
}

export function delayRun(this: any, func: Function, delay: number, ...args: any[]) {
    return setTimeout(() => func.apply(this, args), delay);
}

// 加载图片
export function loadImg(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        // 当为线上图片时，需要设置 crossOrigin 属性；
        if (src.indexOf('http') === 0) img.crossOrigin = '*';
        img.onload = () => {
            resolve(img);
        };
        img.onerror = () => {
            reject(img);
        };
        img.src = src;
    });
}

export function loadImgs(srcs: string[]): Promise<HTMLImageElement[]> {
    const lng = srcs.length;
    let count = 0;
    const imgs: HTMLImageElement[] = [];
    return new Promise((resolve, reject) => {
        srcs.forEach((item, index) => {
            loadImg(item)
                .then(img => {
                    count += 1;
                    imgs[index] = img;
                    if (count >= lng) {
                        resolve(imgs);
                    }
                })
                .catch(_err => {
                    reject(_err);
                });
        });
    });
}

// 合并表单项
export function formModelMerge(formModel: { [key: string]: any }, obj: { [key: string]: any }) {
    Object.keys(formModel).forEach(key => {
        let item = obj[key];
        if (typeof item === 'string') {
            item = item.trim();
        }
        const isBooleanAndNumber = typeof item === 'boolean' || typeof item === 'number';
        if (
            !(
                !isBooleanAndNumber &&
                (item === undefined || (Array.isArray(item) && !item.length) || JSON.stringify(item) === '{}')
            )
        ) {
            formModel[key] = item;
        }
    });
}
/**
 * 数值格式化
 * @param value
 * @param length
 * @param strict
 */
export function filterNumber(value: string | number = 0, length: number = 2, strict: boolean = false): string {
    if (value === null) return '';
    let numberList: string[] | number[] = [];
    if (Number.isNaN(+value)) {
        numberList = `${value}`.split('-');
    } else {
        numberList = [`${value}`];
    }
    return numberList
        .reduce((pre: string[], cur: string): string[] => {
            let item = '';
            if (!Number.isNaN(+cur) && cur !== '') {
                item = (+cur).toFixed(length);
                if (!strict) {
                    item = `${+item}`;
                }
            }
            pre.push(item);
            return pre;
        }, [])
        .join('-');
}

// 获取当前月份第一天
export function getSysMonthFirstDayDate() {
    const date = new Date();
    return `${date.getFullYear()}-${(Array(2).join('0') + (date.getMonth() + 1)).slice(-2)}-01`;
}

// 获取当前年月日
export function getSysDayDate() {
    const date = new Date();
    return `${date.getFullYear()}-${(Array(2).join('0') + (date.getMonth() + 1)).slice(-2)}-${(
        Array(2).join('0') + date.getDate()
    ).slice(-2)}`;
}

// 获取当年第一个月
export function getSysYearFirstMonthDate() {
    const date = new Date();
    return `${date.getFullYear()}-01`;
}

// 获取当前年月
export function getSysMonthDate() {
    const date = new Date();
    return `${date.getFullYear()}-${(Array(2).join('0') + (date.getMonth() + 1)).slice(-2)}`;
}

// 表单校验
export function validateForm(forms: any[], showFirstErrorField = true): Promise<boolean> {
    forms = forms.filter(form => form._isVue && form.validate);
    let callback: Function;
    let count = 0;
    const len = forms.length;
    const invalidFields: any[] = [];
    const formsMap: { [key: string]: any } = {};
    let resultValid = true;
    if (!forms.length) return Promise.resolve(true);
    const promise = new Promise<boolean>((resolve, reject) => {
        callback = (valid: any, invalidFieldMap: any, _uid: any) => {
            if (!valid) resultValid = false;
            Object.keys(invalidFieldMap || {}).forEach(field => {
                invalidFields.push(formsMap[_uid].fieldsMap[field]);
            });
            count += 1;
            if (count !== len) return;
            if (!resultValid) {
                if (showFirstErrorField && invalidFields.length) {
                    invalidFields[0].scrollIntoView(true);
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ errorField: invalidFields });
            } else {
                resolve(true);
            }
        };
    });
    forms.forEach(form => {
        formsMap[form._uid] = {
            fieldsMap: form.fields.reduce((prev: any, curr: any) => {
                prev[curr.prop] = curr.$el;
                return prev;
            }, {}),
        };
        form.validate((valid: any, invalidFieldMap: any) => callback(valid, invalidFieldMap, form._uid));
    });
    return promise;
}

// 取出对象中的深层属性
export function pluckDeep(path: any, data: any, isCreate = false) {
    const pathArr = `${path}`.split('.');
    const lng = pathArr.length;
    return pathArr.reduce((prev, next, index) => {
        const now = prev[next];
        if (now !== null && typeof now === 'object' && !Array.isArray(now)) {
            return now;
        }
        if (!isCreate) {
            if (index + 1 === lng) {
                return now;
            }
            throw new Error(`${path}超出对像范围`);
        }
        prev[next] = {};
        return prev[next];
    }, data);
}

/* 转换 css 的值 */
export function transformCssValue(val: any, unit = 'px') {
    const int = parseFloat(val);
    if (!int) return '';
    if (!+val) return val;
    return int + unit;
}

export function makeEnum<T extends { code?: any; key?: any; name?: string } & Record<string, any>>(obj: T[] | T) {
    if (!isObject(obj)) throw new Error(`构建枚举的对象必须是 array 或 object`);
    let list = obj;
    if (!isArray(list)) {
        list = Object.entries(obj).reduce(
            (pre, cur) => {
                const [code, key] = cur;
                pre.push({ key, code } as any);
                return pre;
            },
            [] as T[],
        );
    }
    const result = {};
    Object.defineProperty(result, '_rawList', {
        get() {
            return list;
        },
    });

    return list.reduce((pre, cur) => {
        const { key, code, name } = cur;
        if (!key || (!code && !name)) {
            throw new Error(`构建枚举的数组项必须包含{key,[code|name]}`);
        }
        if (code) {
            if (hasOwn(pre, code)) throw new Error(`有重复 code:${code} 值`);
            Object.defineProperty(pre, code, { get: () => key });
        } else if (name) {
            if (hasOwn(pre, name)) throw new Error(`有重复 name:${name} 值`);
            Object.defineProperty(pre, name, { get: () => key });
        }
        if (hasOwn(pre, key)) throw new Error(`有重复 key:${key} 值`);
        Object.defineProperty(pre, key, {
            get() {
                const res = { ...cur };
                if (code) res.code = code;
                if (name || code) res.name = name || code;
                return res;
            },
            enumerable: true,
        });
        return pre;
    }, result);
}

export default {
    getType,
    isFunction,
    isArray,
    isString,
    isObject,
    isUrl,
    assert,
    getUniqueId,
    getKey,
    hasOwn,
    isVNode,
    debounce,
    throttle,
    integrateAsync,
    delayRun,
    loadImg,
    loadImgs,
    formModelMerge,
    filterNumber,
    getSysMonthFirstDayDate,
    getSysDayDate,
    getSysYearFirstMonthDate,
    getSysMonthDate,
    validateForm,
    pluckDeep,
    transformCssValue,
    makeEnum,
};

export function dataURLtoBlob(dataurl: string, fileName = '') {
    const arr = dataurl.split(',');
    const mime = (arr[0].match(/:(.*?);/) || [])[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
        n -= 1;
        u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });
    if (fileName) {
        // ios 某些版本不支持 new File
        // return new File([u8arr], fileName, { type: mime });
        (blob as any).name = fileName;
        (blob as any).lastModifiedDate = new Date();
    }
    return blob;
}
export const createObjectURL = (() => {
    const { createObjectURL: createUrl } = (window || {}).URL || {};
    return (obj: any) => createUrl && createUrl(obj);
})();

export function formatDuration(ms: number) {
    if (ms < 0) ms = -ms;
    const time = {
        day: Math.floor(ms / 86400000),
        hour: Math.floor(ms / 3600000) % 24,
        minute: Math.floor(ms / 60000) % 60,
        second: Math.floor(ms / 1000) % 60,
        // millisecond: Math.floor(ms) % 1000,
    };
    const splitMap = {
        day: ' ',
        hour: ':',
        minute: ':',
        second: '',
        // millisecond: '"',
    };
    return Object.entries(time)
        .filter(val => val[1] !== 0 || val[0] === 'second' || val[0] === 'minute')
        .map(([key, val]) => `${`${val}`.padStart(2, '0')}${splitMap[key as keyof typeof splitMap]}`)
        .join('');
}

export const startCountdown = (() => {
    type Timestamp = {
        uid: string;
        active: boolean;
        timestamp: number;
        callBack: Function;
        finish: Function;
    };
    let heartbeatId: any = null;
    const timestampMap: Record<string, Timestamp> = {};
    function heartbeat() {
        heartbeatId = setInterval(() => {
            const timestampList = Object.values(timestampMap);
            if (heartbeatId && (!timestampList || !timestampList.length)) {
                clearInterval(heartbeatId);
                heartbeatId = null;
                return;
            }
            timestampList.forEach(item => {
                const { uid, active, callBack, finish } = item;
                if (!active) {
                    delete timestampMap[uid];
                    return;
                }
                item.timestamp -= 1000;
                if (item.timestamp > 0) {
                    callBack(formatDuration(item.timestamp), item.timestamp);
                } else {
                    item.active = false;
                    callBack(formatDuration(0), 0);
                    finish(0);
                }
            });
        }, 1000);
    }
    function stop(uid: string) {
        const item = timestampMap[uid];
        if (!item) return;
        item.active = false;
    }
    return function handler(timestamp: number, callBack = NOOP, finish = NOOP) {
        const uid = getUniqueId('countdown-');
        timestampMap[uid] = {
            uid,
            active: true,
            timestamp,
            callBack,
            finish,
        };
        if (!heartbeatId) heartbeat();
        return () => stop(uid);
    };
})();

export function addScript(src: string): (() => void) | null {
    if (!src) return null;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.body.appendChild(script);
    return () => {
        document.body.removeChild(script);
    };
}

export async function loadExternalScript(src: string, check: () => any, delay = 500) {
    if (!src) return Promise.reject(new Error('src 不能为空'));
    if (isFunction(check)) {
        const res = check();
        if (res) return res;
    }
    let checkCount = 0;
    return new Promise((resolve, reject) => {
        const unload = addScript(src);
        if (!unload) {
            reject(new Error('加载脚本失败'));
            return;
        }
        if (isFunction(check)) {
            const timerId = setInterval(() => {
                const res = check();
                if (res) {
                    resolve(res);
                    clearInterval(timerId);
                } else if (checkCount > 5) {
                    clearInterval(timerId);
                    unload();
                    reject(new Error(`已检查 5遍了，脚本未加载成功！`));
                }
                checkCount += 1;
            }, delay);
        }
    });
}

const sizeMap = { b: 0, kb: 1, mb: 2, gb: 3 };
type SizeKey = keyof typeof sizeMap;
export function fileSize(value: string | number = 0, format: SizeKey, isUpperCase = false) {
    let val = +value || 0;
    let unit: string = `${format || ''}`.toLocaleLowerCase();

    if (val) {
        if (unit) {
            val = Array(sizeMap[unit as SizeKey] || 0)
                .fill(null)
                .reduce(pre => {
                    pre /= 1024;
                    return pre;
                }, val);
        } else if (~~(val / 1024 ** 3)) {
            val /= 1024 ** 3;
            unit = 'gb';
        } else if (~~(val / 1024 ** 2)) {
            val /= 1024 ** 2;
            unit = 'mb';
        } else if (~~(val / 1024 ** 1)) {
            val /= 1024 ** 1;
            unit = 'kb';
        } else {
            unit = 'b';
        }
    }
    if (isUpperCase) unit = unit.toLocaleUpperCase();
    return `${+val.toFixed(2)}${unit}`;
}
