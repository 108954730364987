import { Pe } from './Pe';

class PhysicalExamItem extends Pe {
    constructor() {
        super();
        this.baseUrl += '/physicalExamItem';
    }

    data({ deviceType, itemName, itemSn, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            deviceType,
            itemName,
            itemSn,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    save({ id, deviceType, itemName, itemSn, itemDesc, isAvailable, remarks, detailList } = {}) {
        return super.post('/save', {
            id,
            deviceType,
            itemName,
            itemSn,
            itemDesc,
            isAvailable,
            remarks,
            details: JSON.stringify(detailList),
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    checkItemSn({ id, itemSn } = {}) {
        return super.get('/checkItemSn', {
            id,
            itemSn,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new PhysicalExamItem();
export { PhysicalExamItem };
