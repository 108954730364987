/**
 * Created by henian.xu on 2019/10/8.
 *
 */

import Axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import Qs from 'qs';
import { hasOwn } from '@vmf/shared';
import { AxiosRequestConfigExtend, AxiosResponseData, AxiosRequestParams } from './interface';
import { requestSuccess, requestFail, responseSuccess, responseFail, getExtendConfig } from './interceptors';
import defaultConfig, { defaultExtendConfig } from './defaultConfig';

export { Axios, AxiosRequestParams };

// 全局唯一的 axios 实例
export const axios = Axios.create(defaultConfig);
// 设置全局拦截器
axios.interceptors.request.use(requestSuccess, requestFail);

axios.interceptors.response.use(responseSuccess, responseFail);

// 创建取消令牌
export function createCancelToken(): CancelTokenSource {
    return Axios.CancelToken.source();
}

// 设置内部扩展配置
function setInsideConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    const cancelSource = config.cancelSource || createCancelToken();
    const cancelConfig: AxiosRequestConfigExtend = {
        cancelSource,
        ...defaultExtendConfig,
    };

    type KeyType = keyof Omit<Required<AxiosRequestConfigExtend>, 'cancelSource'>;
    Object.keys(defaultExtendConfig).forEach(key => {
        if (hasOwn(config, key)) {
            cancelConfig[key as KeyType] = config[key as KeyType] as any;
        }
    });

    cancelSource.token.extendConfig = cancelConfig;
    return {
        ...config,
        cancelToken: cancelSource.token,
    };
}

export function setToken(token: string) {
    axios.defaults.headers.token = token;
}

function requestReturn(result: AxiosResponse<AxiosResponseData>): AxiosResponseData | AxiosResponse<AxiosResponseData> {
    const { config } = result;
    const extendConfig = getExtendConfig(config);
    return extendConfig && extendConfig.isAxiosResponseData ? result.data : result;
}

/**
 * get 请求
 * @param url
 * @param params 对应 axios 中的 params
 * @param config
 */
export function get(
    url: string,
    params: AxiosRequestParams = {},
    config: Partial<AxiosRequestConfig> = {},
): Promise<AxiosResponseData | AxiosResponse<AxiosResponseData>> {
    config = {
        ...setInsideConfig(config),
        params: {
            _T: new Date().getTime(),
            ...params,
        },
    };
    return axios.get<AxiosResponseData>(url, config).then(result => requestReturn(result));
}

/**
 * post 请求
 * @param url
 * @param params 对应 axios 中的 data, 如果有 url 上的参数应该写在 config.params 中
 * @param config
 */
export function post(
    url: string,
    params: AxiosRequestParams = {},
    config: Partial<AxiosRequestConfig> = {},
): Promise<AxiosResponseData | AxiosResponse<AxiosResponseData>> {
    config = setInsideConfig({
        isCancelBefore: false, // 默认值与get的相反,取消之后的请求
        ...config,
    });
    return axios.post(url, params, config).then(result => requestReturn(result));
}

/**
 * postJson 请求
 * @param url
 * @param params
 * @param config
 */
export function postJson(
    url: string,
    params: AxiosRequestParams = {},
    config: Partial<AxiosRequestConfig> = {},
): Promise<AxiosResponseData | AxiosResponse<AxiosResponseData>> {
    return post(url, params, {
        ...config,
        headers: { 'Content-Type': 'application/json' },
        paramsSerializer: () => '',
        transformRequest: [data => JSON.stringify(data)],
    });
}

/**
 * 页面跳转
 * @param url
 * @param params
 * @param newWindow
 * @param baseURL
 */
export function href(
    url: string,
    params: AxiosRequestParams = {},
    { newWindow = false, baseURL = defaultConfig.baseURL } = {},
) {
    if (newWindow) {
        window.open(`${baseURL + url}?${Qs.stringify(params)}`);
    } else {
        window.location.href = `${baseURL + url}?${Qs.stringify(params)}`;
    }
}
