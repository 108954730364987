import { Mb } from '@/api/mb/Mb';

class Post extends Mb {
    constructor() {
        super();
        this.baseUrl += '/post';
    }

    data({
        postCatId,
        departmentId,
        fullName,
        mobile,
        title,
        approvalStatus,
        isAvailable,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            postCatId,
            departmentId,
            fullName,
            mobile,
            title,
            approvalStatus,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', {
            id,
        });
    }

    auditPost({ id, auditStatus, auditRemarks }) {
        return super.post('/auditPost', {
            id,
            auditStatus,
            auditRemarks,
        });
    }

    status({ id, isAvailable, isHot, isTop } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
            isHot,
            isTop,
        });
    }
}
export default new Post();
export { Post };
