/**
 * Created by henian.xu on 2019/8/23.
 *
 */

import Vue from 'vue';
// import { Device } from '@vmf/shared';

export const menuState = Vue.observable({
    rawData: null,
    listMenuMap: {},
    remainderMenu: null,
    // isCollapse: !Device.desktop,
    isCollapse: false,
    headerIndex: '',
    asideIndex: '',
});
export function recursiveMenu(list, key = 'id') {
    if (!list || !list.length) return {};
    return list.reduce((pre, cur) => {
        const keyVal = cur[key];
        if (keyVal) {
            pre[keyVal] = cur;
        }
        if (Array.isArray(cur.children)) {
            pre = {
                ...pre,
                ...recursiveMenu(cur.children, key),
            };
        }
        return pre;
    }, {});
}

export function setRawData(data) {
    const listMenuMap = recursiveMenu((data || {}).listMenu || []);
    menuState.rawData = data;
    menuState.listMenuMap = listMenuMap;
}

export function getMenuById(id) {
    return menuState.listMenuMap[id] || null;
}

export function setRemainderMenu(data) {
    menuState.remainderMenu = data;
}
