import { Pe } from './Pe';

class SchedulingPlan extends Pe {
    constructor() {
        super();
        this.baseUrl += '/schedulingPlan';
    }

    data({
        schedulingRuleType,
        schedulingObjectType,
        schedulingPlanName,
        beginDate,
        endDate,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            schedulingRuleType,
            schedulingObjectType,
            schedulingPlanName,
            beginDate,
            endDate,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    save({
        schedulingRuleType,
        schedulingObjectType,
        schedulingPlanName,
        planDate,
        remarks,
        planDetailList,
        objectList,
    } = {}) {
        let beginDate;
        let endDate;
        if (planDate && planDate.length === 2) {
            [beginDate, endDate] = planDate;
        }
        let planDetails = [];
        if (planDetailList && planDetailList.length) {
            planDetails = JSON.parse(JSON.stringify(planDetailList));
            planDetails.forEach(item => {
                const { detailDate, detailTime } = item;
                if (detailDate && detailDate.length === 2) {
                    [item.beginDate, item.endDate] = detailDate;
                }
                if (detailTime && detailTime.length === 2) {
                    [item.beginTime, item.endTime] = detailTime;
                }
            });
        }
        let objectIds = '';
        if (objectList && objectList.length) {
            objectIds = objectList
                .reduce((prev, curr) => {
                    prev.push(curr.id);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post(
            '/save',
            {
                schedulingRuleType,
                schedulingObjectType,
                schedulingPlanName,
                beginDate,
                endDate,
                planDetails: JSON.stringify(planDetails),
                objectIds,
                remarks,
            },
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    savePlanObject({ id, objectList } = {}) {
        let objectIds = '';
        if (objectList && objectList.length) {
            objectIds = objectList
                .reduce((prev, curr) => {
                    prev.push(curr.id);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/savePlanObject', {
            id,
            objectIds,
        });
    }

    getSchedulingPlan({ objectId, businessTableName, selectDate } = {}) {
        return super.get('/getSchedulingPlan', {
            objectId,
            businessTableName,
            selectDate,
        });
    }
}
export default new SchedulingPlan();
export { SchedulingPlan };
