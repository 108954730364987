<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="会员昵称"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入会员昵称"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="会员手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入会员手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseCatId"
                    label="课程分类"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi1"
                        v-model="queryFormModel.courseCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseName"
                    label="课程名称"
                >
                    <el-input
                        v-model="queryFormModel.courseName"
                        placeholder="请输入课程名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            stripe
                            border
                            size="small"
                            :ref="'table' + scope.row.id"
                            :data="scope.row.courseDetailList"
                        >
                            <el-table-column
                                prop="courseName"
                                label="课程名称"
                                min-width="200"
                            />
                            <el-table-column
                                prop="courseHour"
                                label="课程学时"
                                min-width="100"
                            />
                            <el-table-column
                                prop="learnHour"
                                label="已学学时"
                                min-width="100"
                            />
                            <el-table-column
                                prop="learnCourse"
                                label="已学分钟数"
                                min-width="100"
                            />
                            <el-table-column
                                prop="completionRatio"
                                label="完成比例"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    {{scope.row.completionRatio * 100}}%
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="开始时间"
                                width="135"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.joinTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fullName"
                    label="会员昵称"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <div>{{scope.row.fullName}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    label="会员手机号"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <div>{{scope.row.mobile}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="courseCatNamePath"
                    label="课程分类"
                    min-width="100"
                />
                <el-table-column
                    prop="courseName"
                    label="课程名称"
                    min-width="100"
                />
                <el-table-column
                    label="报名时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.joinTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="learnHour"
                    label="已学课时"
                    min-width="100"
                />
                <el-table-column
                    prop="learnMinutes"
                    label="已学分钟数"
                    min-width="100"
                />
                <el-table-column
                    prop="completionRatio"
                    label="完成比例"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{scope.row.completionRatio * 100}}%
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CourseComment',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                courseCatId: '',
                courseName: '',
                fullName: '',
                mobile: '',
            },
            // 表格数据
            tableData: [],
            selectApi1: this.$api.Ce.CourseCat,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ce.CourseMember.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
