/**
 * Created by henian.xu on 2019/11/1.
 *
 */
import QRCODE, { QRCodeToDataURLOptions } from 'qrcode';

type QRCode = typeof QRCODE;

export async function getQRCode(): Promise<QRCode> {
    const { default: qrcode } = await import(/* webpackChunkName: "qrcode" */ 'qrcode');
    return qrcode;
}

export async function toDataURL(
    canvasElement: HTMLCanvasElement,
    text: string,
    options?: QRCodeToDataURLOptions,
): Promise<string>;
export async function toDataURL(text: string, options?: QRCodeToDataURLOptions): Promise<string>;
export async function toDataURL(...arg: any[]) {
    const qrcode = await getQRCode();
    if (arg.length <= 1) {
        arg.push({
            margin: 2,
            scale: 30,
            errorCorrectionLevel: 'H',
        });
    }
    return qrcode.toDataURL.apply(null, arg);
}

export default {
    getQRCode,
    toDataURL,
};
