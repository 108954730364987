<!-- Created by henian.xu on 2019/8/22. -->

<!--<template>
    <el-aside
        class="layout-menu-aside"
        width="null"
    >
        <AsideMenu />
    </el-aside>
</template>-->

<script lang="jsx">
import { menuState, recursiveMenu } from './MenuState';

export default {
    name: 'Aside',
    inject: {
        Menu: { default: {} },
    },
    computed: {
        isCollapse: {
            get() {
                return menuState.isCollapse;
            },
            set(val) {
                menuState.isCollapse = val;
            },
        },
        asideIndex: {
            get() {
                return menuState.asideIndex;
            },
            set(val) {
                menuState.asideIndex = val;
            },
        },
        headerIndex() {
            return menuState.headerIndex;
        },
        listMenu() {
            const { rawData: { listMenu = [] } = { listMenu: [] } } = menuState;
            return listMenu;
        },
        menuIdMap() {
            return recursiveMenu(this.listMenu);
        },
        asideMenu() {
            const { menuIdMap, headerIndex } = this;
            const menu = menuIdMap[headerIndex] || {};
            return menu.children || [];
        },
    },
    methods:{
        onItem(item,event) {
            event.stopPropagation()
            event.preventDefault()
            const res = { ...item };
            if (this.Menu && this.Menu.clickMenu) this.Menu.clickMenu(res);
            this.$root.$emit('menu-click', res);
        },
    },
    render() {
        const { asideMenu, asideIndex, isCollapse } = this;
        const buildMenu = (list) => {
            if (!list || !list.length) {
                return (
                    <el-menu-item disabled>
                        <span slot="title">{this.$$t('vmf_no_submenu', '暂无子菜单')}</span>
                    </el-menu-item>
                );
            }
            return list.map((item) => {
                if (!Array.isArray(item.children)) {
                    return (
                        <el-menu-item class="aside-menu-item" index={`${item.id}`} key={item.id}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {!item.url ? <div class='link' onClick={$event=>this.onItem(item,$event)}/> : (this.$utils.isUrl(item.url) ? (
                                <a href="/" class="link" target={item.target||''} href={item.url+item.parameter} />
                            ) : (
                                <router-link class="link" target={item.target||''} to={item.url+item.parameter} />
                            ))}
                            {item.icon && <XIcon content={item.icon}></XIcon>}
                            <span slot="title">{item.name}</span>
                        </el-menu-item>
                    );
                }
                return (
                    <el-submenu index={`${item.id}`} key={item.id}>
                        <template slot="title">
                            {item.icon && <XIcon content={item.icon}></XIcon>}
                            <span>{item.name}</span>
                        </template>
                        {buildMenu(item.children)}
                    </el-submenu>
                );
            });
        };
        return asideMenu && asideMenu.length ? (
            <el-aside class="layout-menu-aside" width="null">
                <el-menu default-active={asideIndex} collapse={isCollapse}>
                    {buildMenu(asideMenu)}
                </el-menu>
            </el-aside>) : ('');
    },
};
</script>

<style lang="scss">
.aside-menu-item {
    position: relative;
    > .link {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.layout-menu-aside {
    background-color: $gray8;
    @include beautify-scrollbar;

    > .el-menu {
        border-right: none;
        &:not(.el-menu--collapse) {
            min-width: 200px;
            //min-height: 400px;
        }

        &,
        .el-menu {
            background-color: $gray8;
        }
        .el-menu-item {
            color: #fff;

            &:focus,
            &:hover {
                background-color: mix(#000, $gray8, 10%);
                border-bottom: 0;
            }
            &.is-active {
                color: #82e6e6;
                background-color: mix(#000, $gray8, 20%);
                border-bottom: 0;
            }
        }

        .el-submenu {
            .x-icon {
                margin-right: $margin-small;
            }
            &.is-active {
                > .el-submenu__title {
                    color: #82e6e6;
                    i {
                        color: #82e6e6;
                    }
                }
            }
        }

        .el-submenu__title {
            &,
            i {
                color: #fff;
            }
            &:not(.is-disabled):hover {
                background-color: mix(#000, $gray8, 10%);
            }
        }
    }
    > .el-menu.el-menu--collapse {
        width: auto;
        .el-submenu {
            > .el-submenu__title {
                padding: 0 $padding !important;
            }
        }
    }
}
</style>
