<!-- Created by henian.xu on 2020/5/28. -->

<template>
    <div class="tag-picker">
        <div
            :class="['item',{active:`${model}` === `${item.id}`}]"
            v-for="item in options"
            :key="item.id"
            :style="{backgroundColor:`${item.code}`}"
            @click="onItem(item)"
        >
            <div class="mask"></div>
            <XIcon
                v-if="`${model}` === `${item.id}`"
                content="f017"
                theme="w"
            ></XIcon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TagPicker',
    data() {
        return {};
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        data: {
            type: Array,
            default: () => [],
        },
        props: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        propsMap() {
            const { props } = this;
            return {
                id: 'id',
                code: 'code',
                ...props,
            };
        },
        options() {
            const { data, propsMap } = this;
            return data.reduce((pre, cur) => {
                const {} = cur;
                pre.push({
                    ...cur,
                    id: cur[propsMap.id],
                    code: cur[propsMap.code],
                });
                return pre;
            }, []);
        },
    },
    methods: {
        onItem({ id }) {
            if (`${this.model}` === `${id}`) {
                this.model = '';
            } else {
                this.model = id;
            }
        },
    },
};
</script>

<style lang="scss">
.tag-picker {
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    > .item {
        position: relative;
        vertical-align: middle;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background-color: #f00;

        > .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgb(0, 0, 0, 0.3);
        }

        &.active {
            > .mask {
                display: none;
            }
        }
    }
}
</style>
