/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ce } from './Ce';

class CourseDetail extends Ce {
    constructor() {
        super();
        this.baseUrl += '/courseDetail';
    }

    data({ courseId, currentPage, pageSize } = {}) {
        return super.get('/data', {
            courseId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { fileBasePath } = json.data.data;
            const { courseImage, courseVoiceFile, courseType, courseFile } = json.data.data.courseDetail;
            if (!courseImage) {
                json.data.data.courseDetail.courseImage = [];
            } else {
                json.data.data.courseDetail.courseImage = courseImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (!courseVoiceFile) {
                json.data.data.courseDetail.courseVoiceFile = [];
            } else {
                json.data.data.courseDetail.courseVoiceFile = courseVoiceFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (+courseType === 1) {
                json.data.data.courseDetail.courseFile1 = courseFile;
            }
            if (+courseType === 2) {
                json.data.data.courseDetail.courseFile2 = courseFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (+courseType === 3) {
                json.data.data.courseDetail.courseFile3 = courseFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (+courseType === 4) {
                const courseFileData = JSON.parse(courseFile);
                json.data.data.courseDetail.courseFile4 = courseFileData.pdfFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        courseId,
        courseName,
        courseImage,
        courseType,
        courseFile1,
        courseFile2,
        courseFile3,
        courseFile4,
        courseVoiceFile,
        courseDesc,
        courseHour,
        orderNum,
        validMinutes,
    } = {}) {
        const courseImageStr = courseImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        let courseFileStr = '';
        if (+courseType === 1) {
            courseFileStr = courseFile1;
        }
        if (+courseType === 2) {
            courseFileStr = courseFile2
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        if (+courseType === 3) {
            courseFileStr = courseFile3
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        if (+courseType === 4) {
            courseFileStr = courseFile4
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        const courseVoiceFileStr = courseVoiceFile
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post(
            '/save',
            {
                id,
                courseId,
                courseName,
                courseImage: courseImageStr,
                courseType,
                courseFile: courseFileStr,
                courseVoiceFile: courseVoiceFileStr,
                courseDesc,
                courseHour,
                orderNum,
                validMinutes,
            },
            { timeout: 0 },
        );
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    select() {
        return super.get('/select', {});
    }
}

export default new CourseDetail();
export { CourseDetail };
