import { Mb } from '@/api/mb/Mb';

class PostComment extends Mb {
    constructor() {
        super();
        this.baseUrl += '/postComment';
    }

    data({
        postId,
        departmentId,
        postCommentId,
        fullName,
        mobile,
        content,
        isAvailable,
        approvalStatus,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            postId,
            departmentId,
            postCommentId,
            fullName,
            mobile,
            content,
            isAvailable,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', {
            id,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new PostComment();
export { PostComment };
