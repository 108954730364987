<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='party-member-study'>
        <div class='item'>
            <div class='icon'>
                <img src='./images/icon01.png'>
            </div>
            <div class='content'>
                <div class='value'>{{ study.cmsArticleNum }}</div>
                <div class='label'>堂建动态</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'>
                <img src='./images/icon01.png'>
            </div>
            <div class='content'>
                <div class='value'>{{ study.courseNum }}</div>
                <div class='label'>课程学习</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'>
                <img src='./images/icon01.png'>
            </div>
            <div class='content'>
                <div class='value'>{{ study.examNum }}</div>
                <div class='label'>在线考试</div>
            </div>
        </div>

        <div class='item'>
            <div class='icon'>
                <img src='./images/icon02.png'>
            </div>
            <div class='content'>
                <div class='value'>{{ study.organizeActivityNum }}</div>
                <div class='label'>组织活动</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'>
                <img src='./images/icon02.png'>
            </div>
            <div class='content'>
                <div class='value'>{{ study.voluntaryServiceNum }}</div>
                <div class='label'>志愿服务</div>
            </div>
        </div>
        <div class='item'>
            <div class='icon'>
                <img src='./images/icon02.png'>
            </div>
            <div class='content'>
                <div class='value'>{{ study.postNum }}</div>
                <div class='label'>党员论坛话题</div>
            </div>
        </div>
    </div>
</template>

<script>
import { rsLargeScreenMixin } from 'pages/largeScreen/store';

export default {
    name: 'PartyMemberStudy',
    mixins: [rsLargeScreenMixin],
    data() {
        return {};
    },
};
</script>

<style lang='scss'>
.party-member-study {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: $padding-big 0;

    > .item {
        flex: 0 0 (100% / 3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding 0;

        > .icon {
            width: 58px;
            margin-right: $margin;
        }
        > .content {
            flex: 1 1 1%;
            > .value {
                font-size: 20px;
                font-weight: bold;
                color: #f6efcc;
            }
            > .label {
                font-size: 12px;
            }
        }
    }
}
</style>
