<!-- Created by henian.xu on 2024/1/3. -->

<template>
    <div
        v-if="!!showStr"
        @click.stop="onPicker"
        class="x-address-warp"
    ><XIcon
         v-if="!hideIcon"
         content="f00a"></XIcon>{{ showStr }}


        <el-dialog
            :title="showStr"
            width="50%"
            :visible.sync="dialog.visible"
            append-to-body
            center
        >
            <div class='lat-lng-picker-body'>
                <baidu-map
                    v-if='dialog.visible && centerLatLng'
                    class="lat-lng-picker-map"
                    :center="centerLatLng"
                    :zoom="zoom"
                    :scroll-wheel-zoom="true"
                    :map-click="false"
                    @zoomend="syncCenterAndZoom"
                >
                    <bm-marker
                        :position="centerLatLng"
                        animation="BMAP_ANIMATION_BOUNCE"
                    />
                    <bm-geolocation
                        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                        :show-address-bar="true"
                        :auto-location="true"
                    />
                </baidu-map>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    @click="dialog.visible = false"
                >
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import coordtransform from 'coordtransform';
export default {
    name: 'XAddressWarp',
    data() {
        return {
            dialog: {
                visible: false,
            },
            zoom: 15,
        };
    },
    props: {
        dataset: {
            type: Object,
            default: () => ({}),
        },
        address: {
            type: String,
            default: '',
        },
        hideIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        address_() {
            return this.address || this.dataset.address || '';
        },
        coord() {
            const { dataset, address_: address } = this;
            const { lng, lat, name } = dataset;
            let longitude = +(lng || this.dataset.longitude) || 0;
            let latitude = +(lat || this.dataset.latitude) || 0;
            if (!longitude || !latitude) return null;
            [longitude, latitude] = coordtransform.gcj02tobd09(longitude, latitude);
            return {
                longitude,
                latitude,
                name,
                address,
            };
        },
        centerLatLng() {
            const { coord } = this;
            if (!coord) return null;
            return { lng: coord.longitude, lat: coord.latitude };
        },
        showStr() {
            const { address_: address, coord } = this;
            return address || (coord && `${coord.longitude},${coord.latitude}`) || '';
        },
    },
    methods: {
        syncCenterAndZoom($event) {
            this.zoom = $event.target.getZoom();
        },
        onPicker() {
            const { coord } = this;
            if (!coord) return;

            this.dialog.visible = true;
        },
    },
};
</script>

<style lang='scss'>
.x-address-warp {
    color: $color-blue;
    display: inline;
    cursor: pointer;
    //white-space: nowrap;
}
</style>
