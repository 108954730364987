/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';

class Shop extends Sv {
    constructor() {
        super();
        this.baseUrl += '/shop';
    }

    data({
        customerName,
        customerSn,
        customerStatus,
        principalName,
        principalTel,
        customerRegionId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            customerName,
            customerSn,
            customerStatus,
            principalName,
            principalTel,
            customerRegionId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            if (json.data.data.interfaceConfig) {
                json.data.data.interfaceConfig = JSON.parse(json.data.data.interfaceConfig);
                json.data.data.appSecret = json.data.data.interfaceConfig.appSecret;
                json.data.data.qskdAppKey = json.data.data.interfaceConfig.qskdAppKey;
                json.data.data.qskdAppSecret = json.data.data.interfaceConfig.qskdAppSecret;
                json.data.data.suntonAppKey = json.data.data.interfaceConfig.suntonAppKey;
                json.data.data.suntonAppSecret = json.data.data.interfaceConfig.suntonAppSecret;
                json.data.data.suntonAppRSASecret = json.data.data.interfaceConfig.suntonAppRSASecret;
            }
            return json;
        });
    }

    save({
        id,
        shopName,
        shopUrlAddress,
        principalName,
        principalTel,
        principalEmail,
        interfaceConfig,
        remarks,
        isAvailable,
        warehouseListJson,
    } = {}) {
        return super.post('/save', {
            id,
            shopName,
            shopUrlAddress,
            principalName,
            principalTel,
            principalEmail,
            interfaceConfig,
            remarks,
            isAvailable,
            warehouseListJson,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new Shop();
export { Shop };
