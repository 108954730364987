/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Lg } from './Lg';

class MenuVisitLog extends Lg {
  constructor() {
    super();
    this.baseUrl += '/menuVisitLog';
  }

  data({ logTime, userName, menuId, logType, currentPage, pageSize } = {}) {
    let logTimeBegin = null;
    let logTimeEnd = null;
    if (logTime != null && logTime.length === 2) {
      logTimeBegin = logTime[0];
      logTimeEnd = logTime[1];
    }
    return super.get('/data', {
      logTimeBegin,
      logTimeEnd,
      userName,
      menuId,
      logType,
      currentPage,
      pageSize,
    });
  }

  getDetail({ id }) {
    return super.get('/get', { id });
  }

  log({ menuUrl } = {}) {
    return super.post('/log', {
      menuUrl,
    });
  }
}

export default new MenuVisitLog();
export { MenuVisitLog };
