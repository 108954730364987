<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-row>
            <el-col :span="24">
                <el-form size="small">
                    <el-form-item
                        label="因子列表"
                        label-width="7em">
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddParam"
                        >
                            新增
                        </el-button>

                        <el-table
                            stripe
                            border
                            size="small"
                            :data="paramList"
                            style="width: 100%"
                            class="ma-t"
                        >
                            <el-table-column
                                prop="paramName"
                                label="参数名称"
                                width="100" />
                            <el-table-column
                                prop="paramCode"
                                label="参数代码"
                                width="70">
                                <template slot-scope="scope">
                                    ${{ scope.row.paramCode }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="questionTitles"
                                label="测试题目"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    {{
                                        scope.row.questionTitles ? scope.row.questionTitles : '总分'
                                    }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderNum"
                                label="排序号"
                                width="70" />
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="90">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onEditParam(scope.row)"
                                    >
                                        编辑
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelParam(scope.row.id)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        label="测试结果列表"
                        label-width="7em">
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddAnalysis"
                        >
                            新增
                        </el-button>

                        <el-table
                            stripe
                            border
                            size="small"
                            :data="analysisList"
                            style="width: 100%"
                            class="ma-t"
                        >
                            <el-table-column
                                prop="conditionExpression"
                                label="条件表达式"
                                min-width="100"
                            />
                            <el-table-column
                                prop="analysisComment"
                                label="评语"
                                min-width="100"
                            />
                            <!--              <el-table-column prop="isPrewarning" label="是否预警" width="100">
                <template slot-scope="scope">
                  {{ scope.row.isPrewarning ? '是' : '否' }}
                </template>
              </el-table-column>-->
                            <el-table-column
                                prop="orderNum"
                                label="排序号"
                                width="70" />
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="90">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onEditAnalysis(scope.row)"
                                    >
                                        编辑
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelAnalysis(scope.row.id)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        label="测试因子列表"
                        label-width="7em">
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddFactor"
                        >
                            新增
                        </el-button>

                        <el-table
                            stripe
                            border
                            size="small"
                            :data="factorList"
                            style="width: 100%"
                            class="ma-t"
                            default-expand-all
                        >
                            <el-table-column type="expand">
                                <template slot-scope="factor">
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddComment(factor.row)"
                                    >
                                        新增
                                    </el-button>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="factor.row.commentList"
                                        style="width: 100%"
                                        class="ma-t"
                                    >
                                        <el-table-column
                                            prop="scoreExpression"
                                            label="得分表达式"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="conditionExpression"
                                            label="条件表达式"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="commentTitle"
                                            label="评语标题"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="comment"
                                            label="评语"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="advice"
                                            label="建议"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="orderNum"
                                            label="排序号"
                                            width="70"
                                        />
                                        <el-table-column
                                            fixed="right"
                                            label="操作"
                                            width="90">
                                            <template slot-scope="scope">
                                                <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="onEditComment(scope.row)"
                                                >
                                                    编辑
                                                </el-button>
                                                <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="onDelComment(scope.row.id)"
                                                >
                                                    删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="factorName"
                                label="因子名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="scoreExpression"
                                label="得分式"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderNum"
                                label="排序号"
                                width="70" />
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="90">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onEditFactor(scope.row)"
                                    >
                                        编辑
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelFactor(scope.row.id)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item label-width="10em">
                        <el-button
                            size="small"
                            @click="$router.back()"> 返 回 </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <!--因子-->
        <el-dialog
            :title="addParamDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addParamDialog.isVisible"
            @closed="onAddParamDialogCancel"
        >
            <el-form
                ref="addParamForm"
                size="small"
                :model="addParamDialog.formModel"
                :rules="addParamDialog.formRules"
            >
                <el-form-item
                    prop="paramName"
                    label="参数名称"
                    label-width="6em">
                    <el-input
                        v-model="addParamDialog.formModel.paramName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="paramCode"
                    label="参数代码"
                    label-width="6em">
                    <el-input
                        v-model="addParamDialog.formModel.paramCode"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="questionIds"
                    label="测试题目"
                    label-width="6em">
                    <el-checkbox
                        :indeterminate="addParamDialog.isIndeterminate"
                        v-model="addParamDialog.checkAll"
                        @change="handleCheckAllParam"
                    >全选</el-checkbox
                    >
                    <el-checkbox-group
                        v-model="addParamDialog.formModel.questionIds"
                        @change="handleCheckedParamChange"
                    >
                        <div
                            v-for="item in questionList"
                            :key="item.id">
                            <el-checkbox
                                :label="item.id + ''"
                                :key="item.id">{{
                                    item.questionTitle
                                }}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="6em">
                    <el-input
                        type="textarea"
                        v-model="addParamDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="排序号"
                    label-width="6em">
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addParamDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer">
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddParamDialogConfirm">
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddParamDialogCancel">
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--测试结果-->
        <el-dialog
            :title="addAnalysisDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addAnalysisDialog.isVisible"
            @closed="onAddAnalysisDialogCancel"
        >
            <el-form
                ref="addAnalysisForm"
                size="small"
                :model="addAnalysisDialog.formModel"
                :rules="addAnalysisDialog.formRules"
            >
                <el-form-item
                    prop="conditionExpression"
                    label="条件表达式"
                    label-width="7em"
                >
                    <el-input
                        v-model="addAnalysisDialog.formModel.conditionExpression"
                        auto-complete="off"
                    />
                    可使用参数 {{ allParamCode }}
                </el-form-item>
                <el-form-item
                    prop="analysisComment"
                    label="评语"
                    label-width="7em">
                    <el-input
                        v-model="addAnalysisDialog.formModel.analysisComment"
                        auto-complete="off"
                    />
                </el-form-item>
                <!--                <el-form-item
                    prop="isPrewarning"
                    label="是否预警"
                    label-width="7em">
                    <el-select
                        v-model="addAnalysisDialog.formModel.isPrewarning"
                        placeholder="请选择"
                    >
                        <el-option
                            label="是"
                            :value="true" />
                        <el-option
                            label="否"
                            :value="false" />
                    </el-select>
                </el-form-item>-->
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="7em">
                    <el-input
                        type="textarea"
                        v-model="addAnalysisDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="排序号"
                    label-width="7em">
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addAnalysisDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer">
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddAnalysisDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddAnalysisDialogCancel">
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--测试因子-->
        <el-dialog
            :title="addFactorDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addFactorDialog.isVisible"
            @closed="onAddFactorDialogCancel"
        >
            <el-form
                ref="addFactorForm"
                size="small"
                :model="addFactorDialog.formModel"
                :rules="addFactorDialog.formRules"
            >
                <el-form-item
                    prop="factorName"
                    label="因子名称"
                    label-width="7em">
                    <el-input
                        v-model="addFactorDialog.formModel.factorName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="scoreExpression"
                    label="得分式"
                    label-width="7em">
                    <el-input
                        v-model="addFactorDialog.formModel.scoreExpression"
                        auto-complete="off"
                    />
                    可使用参数 {{ allParamCode }}
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="7em">
                    <el-input
                        type="textarea"
                        v-model="addFactorDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="排序号"
                    label-width="7em">
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addFactorDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer">
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddFactorDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddFactorDialogCancel">
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--测试因子评语-->
        <el-dialog
            :title="addCommentDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addCommentDialog.isVisible"
            @closed="onAddCommentDialogCancel"
        >
            <el-form
                ref="addCommentForm"
                size="small"
                :model="addCommentDialog.formModel"
                :rules="addCommentDialog.formRules"
            >
                <el-form-item
                    prop="scoreExpression"
                    label="得分式"
                    label-width="7em">
                    <el-input
                        v-model="addCommentDialog.formModel.scoreExpression"
                        auto-complete="off"
                    />
                    可使用参数 {{ allParamCode1 }}
                </el-form-item>
                <el-form-item
                    prop="conditionExpression"
                    label="条件表达式"
                    label-width="7em"
                >
                    <el-input
                        v-model="addCommentDialog.formModel.conditionExpression"
                        auto-complete="off"
                    />
                    可使用参数 {{ allParamCode1 }}, $score(注
                    $score的值即上面得分式计算结果，如得分式填$A 则$score=$A
                    如如得分式填$A+$B 则$score=$A+$B)
                </el-form-item>
                <el-form-item
                    prop="commentTitle"
                    label="评语标题"
                    label-width="7em">
                    <el-input
                        v-model="addCommentDialog.formModel.commentTitle"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="comment"
                    label="评语"
                    label-width="7em">
                    <el-input
                        type="textarea"
                        v-model="addCommentDialog.formModel.comment"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="advice"
                    label="建议"
                    label-width="7em">
                    <el-input
                        type="textarea"
                        v-model="addCommentDialog.formModel.advice"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="7em">
                    <el-input
                        type="textarea"
                        v-model="addCommentDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="排序号"
                    label-width="7em">
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addCommentDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer">
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddCommentDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddCommentDialogCancel">
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ScaleEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            id: '',
            questionList: [],
            addParamDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    paramName: '',
                    paramCode: '',
                    remarks: '',
                    orderNum: '',
                    questionIds: [],
                },
                // 表单校验规则
                formRules: {
                    paramName: {
                        required: true,
                        message: '请输入参数名称',
                        trigger: 'blur',
                    },
                    paramCode: {
                        required: true,
                        message: '请输入参数代码',
                        trigger: 'blur',
                    },
                },
                isIndeterminate: false,
                checkAll: false,
            },
            paramList: [],
            addAnalysisDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    conditionExpression: '',
                    analysisComment: '',
                    isPrewarning: false,
                    remarks: '',
                    orderNum: '',
                },
                // 表单校验规则
                formRules: {
                    conditionExpression: {
                        required: true,
                        message: '请输入条件表达式',
                        trigger: 'blur',
                    },
                    analysisComment: {
                        required: true,
                        message: '请输入评语',
                        trigger: 'blur',
                    },
                    isPrewarning: {
                        required: true,
                        message: '请选择是否预警',
                    },
                },
            },
            analysisList: [],
            addFactorDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    scoreExpression: '',
                    factorName: '',
                    remarks: '',
                    orderNum: '',
                },
                // 表单校验规则
                formRules: {
                    scoreExpression: {
                        required: true,
                        message: '请输入条件表达式',
                        trigger: 'blur',
                    },
                    factorName: {
                        required: true,
                        message: '请输入因子名称',
                        trigger: 'blur',
                    },
                },
            },
            factorList: [],
            addCommentDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    factorId: '',
                    scoreExpression: '',
                    conditionExpression: '',
                    commentTitle: '',
                    comment: '',
                    advice: '',
                    remarks: '',
                    orderNum: '',
                },
                // 表单校验规则
                formRules: {
                    conditionExpression: {
                        required: true,
                        message: '请输入条件表达式',
                        trigger: 'blur',
                    },
                    commentTitle: {
                        required: true,
                        message: '请输入评语标题',
                        trigger: 'blur',
                    },
                    comment: {
                        required: true,
                        message: '请输入评语',
                        trigger: 'blur',
                    },
                    advice: {
                        required: true,
                        message: '请输入建议',
                        trigger: 'blur',
                    },
                },
            },
        };
    },
    computed: {
        allQuestionIdList() {
            const idList = [];
            this.questionList.forEach(item => {
                idList.push(`${item.id}`);
            });
            return idList;
        },
        allParamCode() {
            const idList = [];
            idList.push('$score(代表总分的意思)');
            this.paramList.forEach(item => {
                idList.push('$' + `${item.paramCode}`);
            });
            return idList.join(',');
        },
        allParamCode1() {
            const idList = [];
            this.paramList.forEach(item => {
                idList.push('$' + `${item.paramCode}`);
            });
            return idList.join(',');
        },
    },
    methods: {
        init() {
            this.$api.Am.Scale.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.questionList = res.questionList;
            });
            this.$api.Am.ScaleParam.list({ scaleId: this.id }).then(json => {
                const res = json.data.data;
                this.paramList = res;
            });
            this.$api.Am.ScaleAnalysis.list({ scaleId: this.id }).then(json => {
                const res = json.data.data;
                this.analysisList = res;
            });
            this.$api.Am.ScaleFactor.list({ scaleId: this.id }).then(json => {
                const res = json.data.data;
                this.factorList = res;
            });
        },
        onAddParam() {
            this.addParamDialog.isVisible = true;
            this.$nextTick(() => {
                const { addParamForm } = this.$refs;
                addParamForm.resetFields();
                const { formModel } = this.addParamDialog;
                formModel.id = '';
            });
        },
        onEditParam(row) {
            const { formModel } = this.addParamDialog;
            this.$api.Am.ScaleParam.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                res.questionIds = res.questionIds ? res.questionIds.split(',') : [];
                const checkedCount = res.questionIds.length;
                this.addParamDialog.checkAll = checkedCount === this.questionList.length;
                this.addParamDialog.isIndeterminate = checkedCount > 0 && checkedCount < this.questionList.length;

                this.$utils.formModelMerge(formModel, res);
                this.addParamDialog.isVisible = true;
            });
        },
        onAddParamDialogConfirm() {
            const { addParamForm } = this.$refs;
            addParamForm.validate().then(() => {
                this.$api.Am.ScaleParam.save({
                    ...this.addParamDialog.formModel,
                    questionIds: this.addParamDialog.formModel.questionIds.join(','),
                    scaleId: this.id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.addParamDialog.isVisible = false;
                });
            });
        },
        onAddParamDialogCancel() {
            const { addParamForm } = this.$refs;
            addParamForm.resetFields();
            const { formModel } = this.addParamDialog;
            formModel.id = '';
            this.addParamDialog.isVisible = false;
        },
        onDelParam(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.ScaleParam.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
        handleCheckAllParam(val) {
            if (val) {
                // 全选
                this.addParamDialog.formModel.questionIds = this.allQuestionIdList;
            } else {
                // 取消全选
                this.addParamDialog.formModel.questionIds = [];
            }
            this.isIndeterminate = false;
        },
        handleCheckedParamChange(value) {
            const checkedCount = value.length;
            this.addParamDialog.checkAll = checkedCount === this.questionList.length;
            this.addParamDialog.isIndeterminate = checkedCount > 0 && checkedCount < this.questionList.length;
        },
        // 测试结果
        onAddAnalysis() {
            this.addAnalysisDialog.isVisible = true;
            this.$nextTick(() => {
                const { addAnalysisForm } = this.$refs;
                addAnalysisForm.resetFields();
                const { formModel } = this.addAnalysisDialog;
                formModel.id = '';
            });
        },
        onEditAnalysis(row) {
            const { formModel } = this.addAnalysisDialog;
            this.$api.Am.ScaleAnalysis.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;

                this.$utils.formModelMerge(formModel, res);
                this.addAnalysisDialog.isVisible = true;
            });
        },
        onAddAnalysisDialogConfirm() {
            const { addAnalysisForm } = this.$refs;
            addAnalysisForm.validate().then(() => {
                this.$api.Am.ScaleAnalysis.save({
                    ...this.addAnalysisDialog.formModel,
                    scaleId: this.id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.addAnalysisDialog.isVisible = false;
                });
            });
        },
        onAddAnalysisDialogCancel() {
            const { addAnalysisForm } = this.$refs;
            addAnalysisForm.resetFields();
            const { formModel } = this.addAnalysisDialog;
            formModel.id = '';
            this.addAnalysisDialog.isVisible = false;
        },
        onDelAnalysis(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.ScaleAnalysis.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
        // 测试因子
        onAddFactor() {
            this.addFactorDialog.isVisible = true;
            this.$nextTick(() => {
                const { addFactorForm } = this.$refs;
                addFactorForm.resetFields();
                const { formModel } = this.addFactorDialog;
                formModel.id = '';
            });
        },
        onEditFactor(row) {
            const { formModel } = this.addFactorDialog;
            this.$api.Am.ScaleFactor.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;

                this.$utils.formModelMerge(formModel, res);
                this.addFactorDialog.isVisible = true;
            });
        },
        onAddFactorDialogConfirm() {
            const { addFactorForm } = this.$refs;
            addFactorForm.validate().then(() => {
                this.$api.Am.ScaleFactor.save({
                    ...this.addFactorDialog.formModel,
                    scaleId: this.id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.addFactorDialog.isVisible = false;
                });
            });
        },
        onAddFactorDialogCancel() {
            const { addFactorForm } = this.$refs;
            addFactorForm.resetFields();
            const { formModel } = this.addFactorDialog;
            formModel.id = '';
            this.addFactorDialog.isVisible = false;
        },
        onDelFactor(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.ScaleFactor.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
        // 测试因子评语
        onAddComment(row) {
            this.addCommentDialog.isVisible = true;
            this.$nextTick(() => {
                const { addCommentForm } = this.$refs;
                addCommentForm.resetFields();
                const { formModel } = this.addCommentDialog;
                formModel.id = '';
                formModel.factorId = row.id;
            });
        },
        onEditComment(row) {
            const { formModel } = this.addCommentDialog;
            this.$api.Am.ScaleFactorComment.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;

                this.$utils.formModelMerge(formModel, res);
                this.addCommentDialog.isVisible = true;
            });
        },
        onAddCommentDialogConfirm() {
            const { addCommentForm } = this.$refs;
            addCommentForm.validate().then(() => {
                this.$api.Am.ScaleFactorComment.save({
                    ...this.addCommentDialog.formModel,
                    scaleId: this.id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.addCommentDialog.isVisible = false;
                });
            });
        },
        onAddCommentDialogCancel() {
            const { addCommentForm } = this.$refs;
            addCommentForm.resetFields();
            const { formModel } = this.addCommentDialog;
            formModel.id = '';
            this.addCommentDialog.isVisible = false;
        },
        onDelComment(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.ScaleFactorComment.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
