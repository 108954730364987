<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.approvalStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待审核"
                    name="10"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
                <el-tab-pane
                    label="审核通过"
                    name="100"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    label="账号"
                >
                    <el-input
                        v-model="queryFormModel.userName"
                        placeholder="请输入账号"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="applyTime"
                    label="申请时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.applyTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item
                    prop="approvalTime"
                    label="审核时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.approvalTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="userName"
                    label="账号"
                    min-width="100"
                >
                    <template v-slot="scope">
                        {{scope.row.userName}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    min-width="100"
                >
                    <template v-slot="scope">
                        {{scope.row.fullName}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    label="手机号"
                    width="100"
                />
                <el-table-column
                    prop="applyTime"
                    label="申请时间"
                    min-width="140"
                >
                    <template v-slot="scope">
                        {{scope.row.applyTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="approvalStatusName"
                    label="审核状态"
                    min-width="100"
                />
                <el-table-column
                    prop="approvalTime"
                    label="审核时间"
                    min-width="140"
                >
                    <template v-slot="scope">
                        {{ scope.row.approvalTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="approvalRemark"
                    label="审核备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="60"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/member/memberSettlement/view/' + scope.row.id)"
                        >
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'MemberSettlement',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                userName: '',
                fullName: '',
                mobile: '',
                applyTime: [],
                approvalTime: [],
                approvalStatus: '0',
            },
            currentTableSelect: [],
            tableData: [],
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Mb.MemberSettlement.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
    },
};
</script>

<style scoped lang="scss">
</style>
