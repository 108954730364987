<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="stationId"
                    label="站点"
                >
                    <RemoteSelect
                        filterable
                        remote="/pe/station/data"
                        placeholder="请输入"
                        :props="{id:'id',name:'selectName'}"
                        :remote-params="{isAvailable: 1}"
                        v-model="queryFormModel.stationId"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceType"
                    label="设备类型"
                >
                    <dictionaries-picker
                        type="deviceType"
                        v-model="queryFormModel.deviceType"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceSn"
                    label="设备号"
                >
                    <el-input
                        v-model="queryFormModel.deviceSn"
                        placeholder="请输入设备号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceName"
                    label="设备名称"
                >
                    <el-input
                        v-model="queryFormModel.deviceName"
                        placeholder="请输入设备名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceStatus"
                    label="设备状态"
                >
                    <dictionaries-picker
                        type="deviceStatus"
                        v-model="queryFormModel.deviceStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/pe/device/device/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <!--                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="50"
                />-->
                <el-table-column
                    prop="stationName"
                    label="站点名称"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceTypeName"
                    label="设备类型"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceSn"
                    label="设备号"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceName"
                    label="设备名称"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceStatusName"
                    label="设备状态"
                    min-width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template #default="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pe/device/device/edit/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Device',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                stationId: '',
                deviceType: '',
                deviceSn: '',
                deviceName: '',
                deviceStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            loading: '',
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Pe.Device.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pe.Device.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style scoped lang="scss">
</style>
