/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Am } from './Am';

class Assess extends Am {
    constructor() {
        super();
        this.baseUrl += '/assess';
    }

    data({ planName, scaleName, mobile, beginTime, planType, currentPage, pageSize } = {}) {
        let beginTimeBegin = null;
        let beginTimeEnd = null;
        if (beginTime != null && beginTime.length === 2) {
            [beginTimeBegin, beginTimeEnd] = beginTime;
        }
        return super.get('/data', {
            planName,
            scaleName,
            mobile,
            beginTimeBegin,
            beginTimeEnd,
            planType,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }
}

export default new Assess();
export { Assess };
