import { Pe } from './Pe';

class MedicalReport extends Pe {
    constructor() {
        super();
        this.baseUrl += '/medicalReport';
    }

    data({ fullName, idCardNo, stationName, mobile, medicalReportDate, currentPage, pageSize } = {}) {
        let medicalReportDateBegin, medicalReportDateEnd;
        if (medicalReportDate && medicalReportDate.length === 2) {
            [medicalReportDateBegin, medicalReportDateEnd] = medicalReportDate;
        }
        return super.get('/data', {
            fullName,
            idCardNo,
            stationName,
            mobile,
            medicalReportDateBegin,
            medicalReportDateEnd,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }
}
export default new MedicalReport();
export { MedicalReport };
