<template>
    <page class="hello">
        <div
            class="header"
            slot="header">
            <div class="label2">
                <span class="fw-bold">首页</span>
                <span
                    class="ma-l"
                    style="color: red">{{timeMap}}</span>
            </div>
        </div>
        <div slot="body">
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
                header="待办任务"
            >
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    采购单待审核
                                </div>
                                <Linker
                                    to="/svsp/purchasemg/purchaseOrderAudit"
                                    class="value"
                                >
                                    {{ taskNum.purchaseOrderNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    采购待入库
                                </div>
                                <Linker
                                    to="/svwh/checkin/purchaseOrderCheckin"
                                    class="value"
                                >
                                    {{ taskNum.purchaseOrderCheckinNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    采购退货待审核
                                </div>
                                <Linker
                                    to="/svsp/purchasemg/purchaseRefundAudit"
                                    class="value"
                                >
                                    {{ taskNum.purchaseRefundNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    采购退货待出库
                                </div>
                                <Linker
                                    to="/svwh/checkout/purchaseRefundCheckout"
                                    class="value"
                                >
                                    {{ taskNum.purchaseRefundCheckoutNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    销售单待审核
                                </div>
                                <Linker
                                    to="/svcs/salemg/saleOrderAudit"
                                    class="value"
                                >
                                    {{ taskNum.saleOrderNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    销售待出库
                                </div>
                                <Linker
                                    to="/svwh/checkout/saleOrderCheckout"
                                    class="value"
                                >
                                    {{ taskNum.saleOrderCheckoutNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    销售单退货待审核
                                </div>
                                <Linker
                                    to="/svcs/salemg/saleRefundAudit"
                                    class="value"
                                >
                                    {{ taskNum.saleRefundNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    销售退货待入库
                                </div>
                                <Linker
                                    to="/svwh/checkin/saleRefundCheckin"
                                    class="value"
                                >
                                    {{ taskNum.saleRefundCheckinNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    应付账款审核
                                </div>
                                <Linker
                                    to="/fn/payable/payableExamine"
                                    class="value"
                                >
                                    {{ taskNum.payableNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    实付款审核
                                </div>
                                <Linker
                                    to="/fn/payment/paymentExamine"
                                    class="value"
                                >
                                    {{ taskNum.paymentNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    应收账款审核
                                </div>
                                <Linker
                                    to="/fn/receivable/receivableExamine"
                                    class="value"
                                >
                                    {{ taskNum.receivableNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    实收款审核
                                </div>
                                <Linker
                                    to="/fn/receipt/receiptExamine"
                                    class="value"
                                >
                                    {{ taskNum.receiptNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
                header="预警提醒"
            >
                <el-col :span="12">
                    <div  class="pa-l pa-r">商品到期预警</div>
                    <el-form
                        ref="queryForm"
                        class="pa-l pa-r"
                        size="small"
                        :inline="true">
                        <el-tabs
                            class="classify-tabs"
                            v-model="expireDate"
                            type="card"
                            @tab-click="onQuery()"
                        >
                            <el-tab-pane
                                label="近一周"
                                name="10"
                            />
                            <el-tab-pane
                                label="近一个月"
                                name="20"
                            />
                        </el-tabs>
                    </el-form>
                    <div class='pa-a'>
                        <el-table
                            stripe
                            border
                            height="250"
                            size="small"
                            :data="goodsExpireDateList"
                        >
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="realstockQuantity"
                                label="数量"
                                width="80"
                            />
                            <el-table-column
                                prop="expireDate"
                                label="到期时间"
                                min-width="100"
                            />
                        </el-table>
                    </div>
                </el-col>
                <el-col :span="12">
                    <br>
                    <div  class="pa-l pa-r">商品库存预警</div>
                    <br>
                    <div class='pa-a'>
                        <el-table
                            stripe
                            border
                            height="250"
                            size="small"
                            :data="goodsStockQuantityList"
                        >
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="allRealstockQuantity"
                                label="库存数量"
                                min-width="80"
                            />
                            <el-table-column
                                prop="stockWarning"
                                label="预警值"
                                min-width="80"
                            />
                        </el-table>
                    </div>
                </el-col>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
                header="时间段内(开始时间——截止时间)"
            >
                <el-form
                    ref="queryForm1"
                    class="second-header npa-b"
                    size="small"
                    :inline="true">
                    <el-form-item
                        prop="amountDate"
                        label-width="5em">
                        <el-date-picker
                            :picker-options="$utils.CommonConfig.pickerOptions"
                            v-model="amountDate"
                            type="datetimerange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            @click="onQuery1()"
                        >
                            查询
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="24">
                        <div class="index-info-item">
                            <ECharts
                                class="echarts-demo"
                                :options="orderStatOptions"
                            />
                        </div>
                    </el-col>
                </el-row>
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="24">
                        <div class="index-info-item">
                            <ECharts
                                class="echarts-demo"
                                :options="orderStatOptions1"
                            />
                        </div>
                    </el-col>
                </el-row>
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="24">
                        <div class="index-info-item">
                            <ECharts
                                class="echarts-demo"
                                :options="orderStatOptions2"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
                header="财务数据"
            >
                <el-row>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">总应付款</div>
                                <div class='value'>{{ (amountStat.payableStat + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,') }}</div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">总应收款</div>
                                <div class='value'>{{ (amountStat.receivableStat + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,') }}</div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">总实付款</div>
                                <div class='value'>{{ (amountStat.paymentStat + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,') }}</div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">总实收款</div>
                                <div class='value'>{{ (amountStat.receiptStat + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,') }}</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="ma-t">
                    <el-col :span="6">
                        <div class="index-info-item">
                            <div class="body">
                                <div class="label">现金余额</div>
                                <div class='value'>{{ (amountStat.receiptStat - amountStat.paymentStat + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,') }}</div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="index-info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">总库存价值</div>
                                <div class='value'>{{ (amountStat.stockAllAmountStat + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,') }}</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'Index',
    mixins: [pagesMixin],
    data() {
        return {
            expireDate: '',
            amountDate: '',
            goodsExpireDateList: [],
            goodsStockQuantityList: [],
            timeMap: {},
            taskNum: {},
            amountStat: {},
            monthSupplierPurchaseStatList: [],
            monthCustomerSaleStatList: [],
            monthGoodsSaleStatList: [],
        };
    },
    methods: {
        getData() {
            return this.$api.Rs.Console.index().then(json => {
                const res = json.data.data;
                this.timeMap = res.timeMap.timeMap;
                this.taskNum = res.taskNum;
                this.goodsExpireDateList = res.goodsStockList;
                this.goodsStockQuantityList = res.goodsStockWarnList;
                this.amountStat = res.amountStatMap;
                this.monthSupplierPurchaseStatList = res.monthSupplierPurchaseStatList;
                this.monthCustomerSaleStatList = res.monthCustomerSaleStatList;
                this.monthGoodsSaleStatList = res.monthGoodsSaleStatList;
            });
        },
        onQuery() {
            return this.$api.Rs.Console.expireDate(this.expireDate).then(json => {
                const res = json.data.data;
                if (res.goodsStockMonthList != null) {
                    this.goodsExpireDateList = res.goodsStockMonthList;
                } else {
                    this.goodsExpireDateList = res.goodsStockList;
                }
            });
        },
        onQuery1() {
            return this.$api.Rs.Console.index(this.amountDate).then(json => {
                const res = json.data.data;
                this.monthSupplierPurchaseStatList = res.monthSupplierPurchaseStatList;
                this.monthCustomerSaleStatList = res.monthCustomerSaleStatList;
                this.monthGoodsSaleStatList = res.monthGoodsSaleStatList;
            });
        },
        getDateStr(date) {
            return (
                date.getFullYear() +
                '-' +
                (Array(2).join('0') + (date.getMonth() + 1)).slice(-2) +
                '-' +
                (Array(2).join('0') + date.getDate()).slice(-2)
            );
        },
    },
    computed: {
        orderStatOptions() {
            const supplierCostAmountData = [];
            const supplierData = [];
            this.monthSupplierPurchaseStatList.forEach(item => {
                if (item.supplierCostAmount) {
                    supplierCostAmountData.push(item.supplierCostAmount);
                    supplierData.push(item.supplierName);
                }
            });
            return {
                title: {
                    text: '供应商采购金额排行',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    name: '供应商',
                    type: 'category',
                    data: supplierData,
                },
                yAxis: {
                    name: '采购金额',
                    type: 'value',
                },
                series: [
                    {
                        data: supplierCostAmountData,
                        type: 'bar',
                        encode: { x: supplierData, y: supplierCostAmountData },
                    },
                ],
            };
        },
        orderStatOptions1() {
            const customerReceivableData = [];
            const customerData = [];
            this.monthCustomerSaleStatList.forEach(item => {
                if (item.customerReceivableAmount > 0) {
                    customerReceivableData.push(item.customerReceivableAmount);
                    customerData.push(item.customerName);
                }
            });
            return {
                title: {
                    text: '客户消费金额排行',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    name: '客户',
                    type: 'category',
                    data: customerData,
                },
                yAxis: {
                    name: '金额',
                    type: 'value',
                },
                series: [
                    {
                        data: customerReceivableData,
                        type: 'bar',
                        encode: { x: customerData, y: customerReceivableData },
                    },
                ],
            };
        },
        orderStatOptions2() {
            const saleAllAmountData = [];
            const goodsData = [];
            this.monthGoodsSaleStatList.forEach(item => {
                if (item.saleAllAmount > 0) {
                    saleAllAmountData.push(item.saleAllAmount);
                    goodsData.push(item.goodsName);
                }
            });
            return {
                title: {
                    text: '商品销售金额排行',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    name: '商品',
                    type: 'category',
                    data: goodsData,
                },
                yAxis: {
                    name: '金额',
                    type: 'value',
                },
                series: [
                    {
                        data: saleAllAmountData,
                        type: 'bar',
                        encode: { x: goodsData, y: saleAllAmountData },
                    },
                ],
            };
        },
    },
    created() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.amountDate = [this.getDateStr(start), this.getDateStr(end)];
        return this.getData();
    },
};
</script>

<style lang="scss" scoped>
.echarts-demo {
    width: 100%;
}
</style>
<style lang="scss">
.index-info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-small;

    > .icon {
        flex: 0 0 auto;
        font-size: 26px;
        width: 30px;
        text-align: center;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        padding: $padding-small;
        border: 1px solid $color-border;
        border-radius: 5px;

        > .label {
            order: 1;
        }

        > .value {
            order: 0;
            font-weight: bold;
            font-size: 180%;
            color: $color-primary;
        }
    }
}
</style>
