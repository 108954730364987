<template>
    <page back>
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="entryKey"
                        label="条目值"
                        label-width="7em"
                    >
                        <el-input
                            v-model="formModel.entryKey"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="entryName"
                        label="条目名称"
                        label-width="7em"
                    >
                        <el-input
                            v-model="formModel.entryName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="moduleId"
                        label="配置模块"
                        label-width="7em"
                    >
                        <RemoteSelect
                            filterable
                            clearable
                            remote="/rs/languageModule/data"
                            placeholder="请选择"
                            :props="{id:'id',name:'moduleName'}"
                            v-model="formModel.moduleId"
                        />
                    </el-form-item>
                    <el-form-item
                        v-for="item in formModel.entryTransList"
                        :key="item.language"
                        :label="item.languageName"
                        label-width="7em"
                    >
                        <el-input
                            v-model="item.entryTrans"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="entryDesc"
                        label="条目描述"
                        label-width="7em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.entryDesc"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label-width="7em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click.passive.stop="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'LanguageEntryEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                moduleId: '',
                entryKey: '',
                entryName: '',
                entryDesc: '',
                entryTransList: [],
            },
            // 表单校验规则
            formRules: {
                moduleId: {
                    required: true,
                    message: '请选择配置模块',
                },
                entryKey: {
                    required: true,
                    message: '请输入条目值',
                    trigger: 'blur',
                },
                entryName: {
                    required: true,
                    message: '请输入条目名称',
                    trigger: 'blur',
                },
            },
            // 条目译文列表
            entryTransList: [],
        };
    },
    computed: {
        changedModuleId() {
            return this.formModel.moduleId;
        },
    },
    watch: {
        changedModuleId(val) {
            if (val !== '' || val != null) {
                this.$api.Rs.LanguageModule.getAvailableLanguage({ id: this.formModel.moduleId }).then(json => {
                    const res = json.data;
                    let newEntryTransList = [];
                    if (res.data != null) {
                        res.data.forEach(item => {
                            let isFlag = false;
                            if (this.formModel.entryTransList != null) {
                                this.formModel.entryTransList.forEach(item1 => {
                                    if (item1.language === item.language) {
                                        isFlag = true;
                                        if (item1.languageName !== item.languageName) {
                                            let newItem = item1;
                                            newItem.languageName = item.languageName;
                                            newEntryTransList.push(newItem);
                                        } else {
                                            newEntryTransList.push(item1);
                                        }
                                    }
                                });
                            }
                            if (!isFlag) {
                                let isFlag1 = false;
                                if (this.entryTransList != null) {
                                    this.entryTransList.forEach(item1 => {
                                        if (item1.language === item.language) {
                                            isFlag1 = true;
                                            if (item1.languageName !== item.languageName) {
                                                let newItem = item1;
                                                newItem.languageName = item.languageName;
                                                newEntryTransList.push(newItem);
                                            } else {
                                                newEntryTransList.push(item1);
                                            }
                                        }
                                    });
                                }
                                if (!isFlag1) {
                                    newEntryTransList.push(item);
                                }
                            }
                        });
                    }
                    this.formModel.entryTransList = newEntryTransList;
                });
            }
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Rs.LanguageEntry.save({
                    ...this.formModel,
                    entryTransListJson: JSON.stringify(this.formModel.entryTransList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        /**
         * 初始化
         */
        init() {
            this.$api.Rs.LanguageEntry.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.entryTransList = res.entryTransList;
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
