/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Am } from './Am';

class ScaleFactorComment extends Am {
    constructor() {
        super();
        this.baseUrl += '/scaleFactorComment';
    }

    list({ scaleId } = {}) {
        return super.get('/list', {
            scaleId,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        scaleId,
        factorId,
        scoreExpression,
        conditionExpression,
        commentTitle,
        comment,
        advice,
        remarks,
        orderNum,
    } = {}) {
        return super.post('/save', {
            id,
            scaleId,
            factorId,
            scoreExpression,
            conditionExpression,
            commentTitle,
            comment,
            advice,
            remarks,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ScaleFactorComment();
export { ScaleFactorComment };
