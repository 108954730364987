/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class LanguageDataConf extends Rs {
    constructor() {
        super();
        this.baseUrl += '/languageDataConf';
    }

    data({ objectCode, currentPage, pageSize } = {}) {
        return super.get('/data', {
            objectCode,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, objectCode, objectConfig } = {}) {
        return super.post('/save', {
            id,
            objectCode,
            objectConfig,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new LanguageDataConf();
export { LanguageDataConf };
