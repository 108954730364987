<!-- Created by henian.xu on 2023/12/8. -->

<template>
    <div class='party-reward-statistics'>
        <div class='item'>
            <CircleProgress
                :value='partyDue.unpaidPercent'
                background-color='rgba(228,66,63,0.2)'
                foreground-color='#e4423f'></CircleProgress>
            <div class='content'>
                <div class='label'>现存未缴党费笔数</div>
                <ProgressBar
                    :value='partyDue.unpaidNum'
                    :max-value='partyDue.partyDueNum'></ProgressBar>
                <div class='sub-label'>
                    未缴金额: <span>{{ partyDue.unpaidDueAmount | moneyFormat }}元</span>
                </div>
            </div>
        </div>
        <div class='line'></div>
        <div class='item'>
            <CircleProgress
                :value='partyDue.currMonthPaidPercent'
                background-color='rgba(226,151,53,0.2)'
                foreground-color='#e29735'></CircleProgress>
            <div class='content'>
                <div class='label'>本月已缴党费人数</div>
                <ProgressBar
                    :value='partyDue.currMonthPaidNum'
                    :max-value='partyDue.currMonthPartyDueNum'
                    theme='secondary'></ProgressBar>
                <div class='sub-label'>
                    已缴金额: <span>{{ partyDue.currMonthPaidDueAmount | moneyFormat }}元</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from 'pages/largeScreen/_progressBar.vue';
import CircleProgress from 'pages/largeScreen/_circleProgress.vue';
import { rsLargeScreenMixin } from 'pages/largeScreen/store';

export default {
    name: 'PartyRewardStatistics',
    mixins: [rsLargeScreenMixin],
    components: {
        CircleProgress,
        ProgressBar,
    },
    data() {
        return {};
    },
};
</script>

<style lang='scss'>
.party-reward-statistics {
    > .line {
        height: 1px;
        background-image: linear-gradient(to right, transparent, #fff, transparent);
    }
    > .item {
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .circle-progress {
            margin-right: $margin;
        }

        > .content {
            flex: 1 1 1%;

            > .label {
                margin-bottom: $margin-small;
            }

            > .sub-label {
                margin-top: $margin-small;
                font-size: 14px;
            }
        }
    }
}
</style>
