import { HttpBase } from '@/api/base/HttpBase';

class Pe extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/pe';
    }
}
export default new Pe();
export { Pe };
