<template>
    <page back>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.signInStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="未签到"
                    name="10"
                />
                <el-tab-pane
                    label="无需签到"
                    name="99"
                />
                <el-tab-pane
                    label="已签到"
                    name="100"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="平台昵称"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入平台昵称"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="联系电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入联系电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="applyTime"
                    label="报名时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.applyTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item
                    prop="signInTime"
                    label="签到时间"
                    v-if="+queryFormModel.signInStatus === 0 || +queryFormModel.signInStatus === 100"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.signInTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <div
                class="ma-b"
            >
                <el-button-group class="ma-l">
                    <el-button
                        icon="el-icon-download"
                        type="primary"
                        size="small"
                        @click="onExportAll"
                    >
                        报名人员导出全部
                    </el-button>
                </el-button-group>
                <el-button-group class="ma-l">
                    <el-button
                        icon="el-icon-download"
                        type="primary"
                        size="small"
                        @click="onExport"
                        :disabled="!currentTableSelect.length"
                    >
                        报名人员导出
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="50"
                />
                <el-table-column
                    prop="activityName"
                    label="活动名称"
                    min-width="100"
                />
                <el-table-column
                    prop="fullName"
                    label="平台昵称"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="联系电话"
                    min-width="100"
                />
                <el-table-column
                    prop="applyTime"
                    label="报名时间"
                    width="140"
                >
                    <template #default="scope">
                        {{scope.row.applyTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="signInTime"
                    label="签到时间"
                    width="140"
                    v-if="+queryFormModel.signInStatus === 100"
                >
                    <template #default="scope">
                        {{scope.row.signInTime | moment('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="signInStatusName"
                    label="签到状态"
                    width="80"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';
export default {
    name: 'ActivityMember',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orgName: '',
                activityId: '',
                fullName: '',
                idCardNo: '',
                mobile: '',
                applyTime: '',
                signInTime: '',
                signInStatus: '0',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            orgApi: this.$api.Rs.Org,
            loading: '',
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Mb.ActivityMember.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onExportAll() {
            this.$confirm('是否确定要导出全部！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                const { applyTime, signInTime } = this.queryFormModel;
                let applyTimeBegin, applyTimeEnd;
                if (applyTime && applyTime.length === 2) {
                    [applyTimeBegin, applyTimeEnd] = applyTime;
                }

                let signInTimeBegin, signInTimeEnd;
                if (signInTime && signInTime.length === 2) {
                    [signInTimeBegin, signInTimeEnd] = signInTime;
                }
                this.$http.href('/mb/activityMember/exportActivityMember', {
                    ...this.queryFormModel,
                    applyTimeBegin,
                    applyTimeEnd,
                    signInTimeBegin,
                    signInTimeEnd,
                });
            });
        },
        onExport() {
            this.$confirm('是否确定要导出！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                const ids = this.currentTableSelect
                    .reduce((prev, next) => {
                        prev.push(next.id);
                        return prev;
                    }, [])
                    .join(',');
                const { applyTime, signInTime } = this.queryFormModel;
                let applyTimeBegin, applyTimeEnd;
                if (applyTime && applyTime.length === 2) {
                    [applyTimeBegin, applyTimeEnd] = applyTime;
                }

                let signInTimeBegin, signInTimeEnd;
                if (signInTime && signInTime.length === 2) {
                    [signInTimeBegin, signInTimeEnd] = signInTime;
                }
                this.$http.href('/mb/activityMember/exportActivityMember', {
                    ...this.queryFormModel,
                    applyTimeBegin,
                    applyTimeEnd,
                    signInTimeBegin,
                    signInTimeEnd,
                    ids: ids,
                });
            });
        },
    },
    created() {
        this.queryFormModel.activityId = this.$route.params.id;
    },
};
</script>

<style scoped lang="scss">
</style>
