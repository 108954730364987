import { Pe } from './Pe';

class Device extends Pe {
    constructor() {
        super();
        this.baseUrl += '/device';
    }

    data({ stationId, deviceType, deviceSn, deviceName, deviceStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            stationId,
            deviceType,
            deviceSn,
            deviceName,
            deviceStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }

    save({ id, stationId, deviceType, deviceSn, deviceName, deviceStatus, remarks } = {}) {
        return super.post('/save', {
            id,
            stationId,
            deviceType,
            deviceSn,
            deviceName,
            deviceStatus,
            remarks,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    checkDeviceSn({ id, deviceSn } = {}, config = {}) {
        return super.get(
            '/checkDeviceSn',
            {
                id,
                deviceSn,
            },
            {
                ...config,
            },
        );
    }
}
export default new Device();
export { Device };
