/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class ExchangeRate extends Rs {
    constructor() {
        super();
        this.baseUrl += '/exchangeRate';
    }

    data({ destCurrency, origCurrency, currentPage, pageSize } = {}) {
        return super.get('/data', {
            destCurrency,
            origCurrency,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, destCurrency, origCurrency, rate } = {}) {
        return super.post('/save', {
            id,
            destCurrency,
            origCurrency,
            rate,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    searchExchangeRate({ origCurrency, destCurrency }) {
        return super.get('/searchExchangeRate', { origCurrency, destCurrency });
    }
}

export default new ExchangeRate();
export { ExchangeRate };
