/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class LanguageDataTrans extends Rs {
    constructor() {
        super();
        this.baseUrl += '/languageDataTrans';
    }

    getLanguageData({ tableCode, dataId }) {
        return super.get('/getLanguageData', { tableCode, dataId });
    }

    saveJsonList({ tableCode, dataId, jsonList } = {}) {
        return super.post('/saveJsonList', {
            tableCode,
            dataId,
            jsonList,
        });
    }
}

export default new LanguageDataTrans();
export { LanguageDataTrans };
