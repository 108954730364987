<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="stationName"
                    label="站点名称"
                >
                    <el-input
                        v-model="queryFormModel.stationName"
                        placeholder="请输入站点名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="体检人姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入体检人姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="idCardNo"
                    label="体检人身份证号"
                >
                    <el-input
                        v-model="queryFormModel.idCardNo"
                        placeholder="请输入体检人身份证号"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="会员联系电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入会员联系电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="medicalReportDate"
                    label="体检日期">
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.medicalReportDate"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <!--                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="50"
                />-->
                <el-table-column
                    prop="stationName"
                    label="站点名称"
                    min-width="100"
                />
                <el-table-column
                    prop="fullName"
                    label="体检人姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="idCardNo"
                    label="体检人身份证号"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="会员联系电话"
                    width="100"
                />
                <el-table-column
                    prop="medicalReportDate"
                    label="体检日期"
                    width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.medicalReportDate | moment('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="viewReport(scope.row)"
                            v-if="scope.row.attachment"
                        >
                            查看报告
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Doctor',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                fullName: '',
                idCardNo: '',
                stationName: '',
                mobile: '',
                reservationType: '',
                medicalReportDate: [],
                reservationStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            loading: '',
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Pe.MedicalReport.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        viewReport(row) {
            row.attachmentList.forEach(item => {
                window.open(item);
            });
        },
    },
};
</script>

<style scoped lang="scss">
</style>
